import { ZoomInOutlined, ZoomOutOutlined } from "@ant-design/icons";
import { Button, InputNumber, Result } from "antd";
import { useEffect, useState } from "react";
import { Document, Page } from "react-pdf";
import { CenteredSpinner } from "../Spinner";

import { pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import styles from "./DocumentViewerContent.module.scss";

pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const MIN_ZOOM = 10;
const MAX_ZOOM = 300;

const SCROLL_PIXEL_THRESHOLD = 10;

export interface DocumentViewerProps {
  previewBlob?: Blob;
  previewError?: boolean;
  onScrolledToBottom?: () => void;
}

const DocumentViewerContent = ({
  previewBlob,
  previewError,
  onScrolledToBottom,
}: DocumentViewerProps): JSX.Element => {
  const [numPages, setNumPages] = useState(0);
  const [zoom, setZoom] = useState(100);
  const [passwordError, setPasswordError] = useState(false);

  useEffect(() => {
    if (numPages === 1 && onScrolledToBottom) {
      onScrolledToBottom();
    }
  }, [numPages, onScrolledToBottom]);

  const handlePassword = (
    callback: (password: string) => void,
    reason: string | number,
  ) => {
    const callbackProxy = (password: string | null) => {
      // Cancel button handler
      if (password === null) {
        setPasswordError(true);
      } else {
        callback(password);
      }
    };

    switch (reason) {
      // Need password
      case 1: {
        const password = prompt("Enter the password to open this PDF file.");
        callbackProxy(password);
        break;
      }
      // Wrong password
      case 2: {
        const password = prompt("Invalid password - please try again.");
        callbackProxy(password);
        break;
      }
      default:
    }
  };
  return (
    <>
      {previewError ? (
        <Result
          status="error"
          title="Unable to preview document."
          subTitle="Please download the file directly or refresh the page to try again."
        />
      ) : passwordError ? (
        <Result
          status="error"
          title="Unable to preview document."
          subTitle="This document requires a password to view."
        />
      ) : previewBlob === undefined ? (
        <CenteredSpinner />
      ) : (
        <>
          <div className={styles.PdfToolbar}>
            <Button
              className={styles.PdfToolbar_button}
              size="small"
              onClick={() => setZoom(Math.max(zoom - 10, MIN_ZOOM))}
            >
              <ZoomOutOutlined />
            </Button>
            <Button
              className={styles.PdfToolbar_button}
              size="small"
              onClick={() => setZoom(Math.min(zoom + 10, MAX_ZOOM))}
            >
              <ZoomInOutlined />
            </Button>
            <span className={styles.PdfToolbar_zoomLabel}>Zoom:&nbsp;</span>
            <InputNumber
              className={styles.PdfToolbar_zoomInput}
              size="small"
              min={MIN_ZOOM}
              max={MAX_ZOOM}
              step={10}
              addonAfter="%"
              value={zoom}
              onChange={(value) => setZoom(value ?? 100)}
            />
          </div>
          <Document
            onScroll={(e) => {
              if (
                e.target.scrollTop +
                  e.target.offsetHeight +
                  SCROLL_PIXEL_THRESHOLD >=
                e.target.scrollHeight
              ) {
                onScrolledToBottom?.();
              }
            }}
            className={styles.PdfDocument}
            file={previewBlob}
            loading={<CenteredSpinner />}
            onPassword={handlePassword}
            onLoadSuccess={({ numPages: pdfNumPages }) => {
              setNumPages(pdfNumPages);
            }}
          >
            {Array.from(new Array(numPages), (_, i) => (
              <div className={styles.PdfPage} key={i}>
                <Page
                  pageIndex={i}
                  loading={<CenteredSpinner />}
                  scale={zoom / 100}
                />
              </div>
            ))}
          </Document>
        </>
      )}
    </>
  );
};

export default DocumentViewerContent;
