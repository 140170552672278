import { Table } from "antd";
import { useGetReferralsQuery } from "../../../features/API/referral";
import { ISODateStr } from "../../../features/API/types";
import { readableDateTimeString } from "../../../utils/dates";

const ReferralTable = (): JSX.Element => {
  const { data: referrals, isLoading } = useGetReferralsQuery();

  const columns = [
    {
      title: "First Name",
      key: "firstName",
      dataIndex: "firstName",
    },
    {
      title: "Last Name",
      key: "lastName",
      dataIndex: "lastName",
    },
    {
      title: "Email",
      key: "email",
      dataIndex: "email",
    },
    {
      title: "Company Name",
      key: "companyName",
      dataIndex: "companyName",
    },
    {
      title: "Notes",
      key: "notes",
      dataIndex: "notes",
    },
    {
      title: "Created At",
      key: "createdAt",
      dataIndex: "createdAt",
      render: (createdAt: ISODateStr) => readableDateTimeString(createdAt),
    },
  ];

  const documentTableData =
    referrals?.data.map((referral) => ({
      key: referral.id,
      firstName: referral.attributes.firstName,
      lastName: referral.attributes.lastName,
      email: referral.attributes.email,
      companyName: referral.attributes.companyName,
      notes: referral.attributes.notes,
      createdAt: referral.attributes.createdAt,
    })) ?? [];

  return (
    <Table
      dataSource={documentTableData}
      columns={columns}
      pagination={false}
      loading={isLoading}
    />
  );
};

export default ReferralTable;
