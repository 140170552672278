import { skipToken } from "@reduxjs/toolkit/query";
import { Alert, Flex, Select, Space, message } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { RcFile } from "antd/lib/upload";
import { useGetQuestionnaireThreadQuery } from "../../../../src/features/API/Dashboard/dashboard";
import AppHeader from "../../../components/AppHeader";
import ConfirmationModal from "../../../components/ConfirmationModal";
import UploadDragger from "../../../components/UploadDragger";
import { useBulkUploadPendingQuestionsMutation } from "../../../features/API/pendingQuestions";
import { QuestionnaireTaskId } from "../../../features/MissionControl/types/tasks";
import { getErrorDetail } from "../../../utils/hooks";

import CompanySelector from "../../../components/CompanySelector";
import MultiProductSelector from "../../../components/MultiProductSelector";
import QuestionnaireTaskSelector from "../../../components/QuestionnaireTaskSelector";
import { CompanyId, ProductId } from "../../../features/API/types";
import styles from "./BulkUploadQuestions.module.scss";

const BulkUploadQuestions = () => {
  const navigate = useNavigate();
  const [activeCompany, setActiveCompany] = useState<CompanyId | undefined>(
    undefined,
  );
  const [pendingQuestionsCSV, setPendingQuestionsCSV] = useState<RcFile>();
  const [taskId, setTaskId] = useState<QuestionnaireTaskId>();
  const [productIds, setProductIds] = useState<ProductId[]>();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [bulkUpload] = useBulkUploadPendingQuestionsMutation();

  const { data: questionnaireTaskData } = useGetQuestionnaireThreadQuery(
    taskId ? taskId : skipToken,
  );

  const uploadPendingQuestions = async () => {
    if (!pendingQuestionsCSV) {
      message.error("No CSV selected.");
      return;
    }
    if (!activeCompany) {
      message.error("No company selected.");
      return;
    }
    if (!productIds) {
      message.error("No library selected.");
      return;
    }

    const formData = new FormData();
    formData.append("questionnaireTaskId", taskId as string);
    productIds.forEach((productId) => {
      formData.append("productIds", productId.toString());
    });
    formData.append("file", pendingQuestionsCSV);

    const response = await bulkUpload(formData);
    if ("error" in response) {
      const errorDetail = getErrorDetail(response.error);
      message.error(`Error uploading pending questions: ${errorDetail}`);
      return;
    }

    message.success("Upload complete!");
    setShowConfirmationModal(false);
    setTaskId(undefined);
    setPendingQuestionsCSV(undefined);
  };
  const confirmationValue =
    questionnaireTaskData?.data?.relationships.task.relationships.products
      .filter((product) => productIds?.includes(product.id))
      .map((product) => product.attributes.productName)
      .join(", ") || "";

  return (
    <>
      <Flex justify="space-between">
        <AppHeader
          title="Bulk Upload"
          onBack={() => {
            navigate("/pending-questions-internal");
          }}
        />

        <Flex gap={"small"}>
          <div>
            Company:{" "}
            <CompanySelector
              onChange={(id) => {
                setActiveCompany(id);
                setTaskId(undefined);
                setProductIds(undefined);
              }}
              defaultValue={activeCompany}
            />
          </div>
          <div>
            Questionnaire Task:{" "}
            <QuestionnaireTaskSelector
              companyId={activeCompany}
              statuses={[
                "undergoingCompletion",
                "qaCheck",
                "completedAwaitingReturn",
              ]}
              disabled={!activeCompany}
              onChange={(id) => {
                setTaskId(id);
                setProductIds(undefined);
              }}
              className={styles.SelectQuestionnaireTaskInput}
            />
          </div>
          <div>
            Library:{" "}
            <MultiProductSelector
              companyId={activeCompany}
              options={questionnaireTaskData?.data?.relationships.task.relationships.products.map(
                (p) => ({
                  value: p.id,
                  label: p.attributes.productName,
                }),
              )}
              onChange={(ids) => {
                setProductIds(ids);
              }}
              disabled={!taskId}
              value={productIds}
              placeholder="Select Libraries"
            />
          </div>
        </Flex>
      </Flex>
      <Flex vertical gap="middle">
        <Alert
          message="Instructions"
          type="info"
          showIcon
          description={
            <>
              <p>
                Select the library and questionnaire task you want to create
                pending questions for.
              </p>
              <p>
                By default you will only see the questionnaire tasks that are
                either <i>In Progress</i>, <i>Quality Check</i> or{" "}
                <i>Returned</i>.
              </p>
              <p>Finally, upload the CSV file.</p>
              <p>
                <b>Note:</b> A valid CSV file must have columns named
                'Question', 'Context' and 'Category'. Only these three columns
                will be imported.
              </p>
            </>
          }
        />

        <Space direction="vertical">
          <UploadDragger
            onSetFile={(file) => setPendingQuestionsCSV(file)}
            acceptedFiles=".csv"
            mainText="Click or drag file to this area to upload pending questions CSV."
            onButtonClick={() => {
              if (!productIds) {
                message.error("No product selected.");
                return;
              }
              setShowConfirmationModal(true);
            }}
            buttonDisabled={!pendingQuestionsCSV}
          />
        </Space>
      </Flex>
      {productIds && (
        <ConfirmationModal
          open={showConfirmationModal}
          title="Bulk Upload Pending Questions"
          onCancel={() => setShowConfirmationModal(false)}
          onConfirm={uploadPendingQuestions}
          confirmationValue={confirmationValue}
        >
          <p>
            Please type <code>{confirmationValue}</code> to confirm that you are
            uploading data for the correct product.
          </p>
        </ConfirmationModal>
      )}
    </>
  );
};

export default BulkUploadQuestions;
