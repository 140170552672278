import { IconDefinition, library } from "@fortawesome/fontawesome-svg-core";
import * as allIcons from "@fortawesome/free-solid-svg-icons";

const ICONS: IconDefinition[] = [];

// Use set to avoid duplicates
const iconNameSet = new Set();
Object.keys(allIcons).forEach((key) => {
  // biome-ignore lint/suspicious/noExplicitAny: Ignore
  const icon = (allIcons as any)[key];
  if (
    icon.prefix &&
    icon.iconName &&
    icon.icon &&
    !iconNameSet.has(icon.iconName)
  ) {
    ICONS.push(icon);
    iconNameSet.add(icon.iconName);
  }
});

library.add(...ICONS);

export default ICONS;
