import { Static, Union } from "runtypes";
import {
  APIRequestThreadRT,
  AirtableRequestThreadRT,
  CommentFileRT,
  InAppRequestThreadRT,
  NonStaffAPIRequestThreadRT,
  NonStaffAirtableRequestThreadRT,
  NonStaffInAppRequestThreadRT,
} from "./base_threads";
import {
  GmailAttachmentMetadataRT,
  GmailDraftAttachmentRT,
  GmailRequestThreadRT,
  NonStaffGmailRequestThreadRT,
} from "./gmail";
import { QuestionnaireTaskFileRT } from "./tasks";

const MissionControlFileRT = Union(
  QuestionnaireTaskFileRT,
  GmailDraftAttachmentRT,
  GmailAttachmentMetadataRT,
  CommentFileRT,
);
export type MissionControlFile = Static<typeof MissionControlFileRT>;
export const RequestThreadRT = Union(
  AirtableRequestThreadRT,
  GmailRequestThreadRT,
  APIRequestThreadRT,
  InAppRequestThreadRT,
);
export type RequestThread = Static<typeof RequestThreadRT>;

export const NonStaffRequestThreadRT = Union(
  NonStaffGmailRequestThreadRT,
  NonStaffAPIRequestThreadRT,
  NonStaffInAppRequestThreadRT,
  NonStaffAirtableRequestThreadRT,
);
export type NonStaffRequestThread = Static<typeof NonStaffRequestThreadRT>;
