import { PSClickWrap } from "@pactsafe/pactsafe-react-sdk";
import { captureException } from "@sentry/react";
import { Result } from "antd";
import { useEffect, useState } from "react";
import { CenteredSpinner } from "../Spinner";
import { IroncladNda } from "../types/assuranceProfile";

const CLICKWRAP_LOAD_TIMEOUT = 10000;

interface IroncladNdaProps {
  nda: IroncladNda;
  templateRenderData: TemplateRenderData;
  onAllChecked: (checked: boolean) => void;
}
const IroncladInlineNda = ({
  nda,
  templateRenderData,
  onAllChecked,
}: IroncladNdaProps): JSX.Element => {
  const [isLoadingClickwrap, setIsLoadingClickwrap] = useState(true);
  const [showLoadingError, setShowLoadingError] = useState(false);

  // Ironclad doesn't provide any error callback functions, so if user provides invalid clickwrap group data, we need a way to show that
  // fetching the clickwrap failed.
  useEffect(() => {
    if (!isLoadingClickwrap) {
      return;
    }
    const timeout = setTimeout(() => {
      if (isLoadingClickwrap) {
        setShowLoadingError(true);
      }
    }, CLICKWRAP_LOAD_TIMEOUT);
    return () => clearTimeout(timeout);
  }, [isLoadingClickwrap]);

  return (
    <>
      {showLoadingError ? (
        <Result status={404} title="NDA not found" />
      ) : (
        isLoadingClickwrap && <CenteredSpinner />
      )}
      <Clickwrap
        nda={nda}
        setAllChecked={onAllChecked}
        templateRenderData={templateRenderData}
        onRendered={() => setIsLoadingClickwrap(false)}
      />
    </>
  );
};

export interface TemplateRenderData {
  firstName: string;
  lastName: string;
  email: string;
  companyName: string;
}

interface ClickwrapProps {
  nda: IroncladNda;
  setAllChecked: (checked: boolean) => void;
  templateRenderData: TemplateRenderData;
  onRendered: () => void;
}
export const Clickwrap = ({
  nda,
  setAllChecked,
  templateRenderData,
  onRendered,
}: ClickwrapProps) => {
  const isProduction = window.ENV_VARIABLE_SENTRY_ENVIRONMENT === "prod";
  // This component is not-reactive. Passing different renderData will not trigger a different template :-/
  return (
    <PSClickWrap
      accessId={nda.attributes.siteAccessId}
      groupKey={nda.attributes.groupKey}
      dynamic
      clickWrapStyle="scroll"
      renderData={templateRenderData}
      signerId={templateRenderData.email}
      disableSending
      snapshotLocation={nda.attributes.snapshotLocationKey}
      allowDisagreed
      onRendered={onRendered}
      psScriptUrl={
        isProduction ? undefined : "//vault.demo.pactsafe.io/ps.min.js"
      }
      onDisplayed={(el: HTMLElement) => {
        const versionId = el.getAttribute("data-vid");
        const contractIdRaw = el.getAttribute("data-cid");
        const contractId = contractIdRaw ? parseInt(contractIdRaw) : null;

        if (!versionId || !contractId) {
          captureException(new Error("versionId or contractId not found"));
          return;
        }
        if (!window._ps) {
          captureException(new Error("window._ps not found"));
          return;
        }
        window._ps(nda.attributes.groupKey + ":send", "displayed", {
          //temporarily allow sending events
          disable_sending: false,
          contracts: [contractId],
          versions: [versionId],
        });
      }}
      onValid={() => {
        setAllChecked(true);
      }}
      onInvalid={() => {
        setAllChecked(false);
      }}
    />
  );
};

export default IroncladInlineNda;
