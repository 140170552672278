import {
  combineReducers,
  configureStore,
  isAsyncThunkAction,
  isPlain,
} from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import * as Sentry from "@sentry/react";
import dayjs from "dayjs";
import {
  magicLinkMiddleware,
  magicLinkReducer,
  magicLinkReducerPath,
  middleware as privateApiMiddleware,
  reducer as privateApiReducer,
  reducerPath as privateApiReducerPath,
} from "../features/API";
import authApi from "../features/API/auth";
import askToAnswerReducer, { ASK_TO_ANSWER_KEY } from "../features/AskToAnswer";
import preAuthReducer, { PREAUTH_KEY } from "../features/Authentication";
import layoutReducer, { LAYOUT_KEY } from "../features/Layout";
import missionControlReducer, {
  MISSION_CONTROL_KEY,
} from "../features/MissionControl";
import registerReducer, { REGISTRATION_KEY } from "../features/Registration";
import userAuthReducer, { USER_AUTH_KEY } from "../features/UserInfo";
import { loadReduxState } from "../utils/localStorage";
import TokenHandler from "../utils/token";
import { clearLoggedInState } from "./clearLoggedInState";

export type RootState = ReturnType<typeof combinedReducer>;

const combinedReducer = combineReducers({
  [ASK_TO_ANSWER_KEY]: askToAnswerReducer,
  [LAYOUT_KEY]: layoutReducer,
  [privateApiReducerPath]: privateApiReducer,
  [magicLinkReducerPath]: magicLinkReducer,
  [REGISTRATION_KEY]: registerReducer,
  [USER_AUTH_KEY]: userAuthReducer,
  [MISSION_CONTROL_KEY]: missionControlReducer,
  [PREAUTH_KEY]: preAuthReducer,
});

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  actionTransformer: (action) => {
    if (isAsyncThunkAction(authApi)) {
      return null;
    }

    return action;
  },
  stateTransformer: (state: RootState) => {
    const transformedState = {
      ...state,
      userAuth: null,
      privateApi: null,
    };
    return transformedState;
  },
});

const store = configureStore({
  reducer: (state, action) => {
    // tests fail without this
    if (!state) {
      return combinedReducer(state, action);
    }
    if (
      clearLoggedInState.match(action) ||
      authApi.endpoints.logout.matchPending(action) ||
      authApi.endpoints.logout.matchFulfilled(action) ||
      authApi.endpoints.logout.matchRejected(action) // logout locally even if endpoint failed somehow
    ) {
      // Only keep dark mode setting
      const { [LAYOUT_KEY]: layout } = state;
      state = { [LAYOUT_KEY]: { ...layout, activeProduct: null } };

      // Clear TokenHandler
      TokenHandler.clearTokens();
    }

    if (
      authApi.endpoints.impersonateUser.matchFulfilled(action) ||
      authApi.endpoints.clearImpersonatedUser.matchFulfilled(action)
    ) {
      //wipe redux state except for auth data
      state = {
        [USER_AUTH_KEY]: {
          ...state.userAuth,
        },
      };
      // to trigger rtk query state refresh. Tag invalidation was leading to race conditions.
      window.location.reload();
    }

    return combinedReducer(state, action);
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      // Date objects are not serializable by default. We need to add a custom serializer for them. More info here: https://redux-toolkit.js.org/api/serializabilityMiddleware
      serializableCheck: {
        isSerializable: (value: unknown) => {
          return isPlain(value) || dayjs.isDayjs(value);
        },
        getEntries: (value: unknown) => {
          if (dayjs.isDayjs(value) && value.isValid()) {
            return Object.entries(value.toISOString());
          }
          return Object.entries(value as Record<string, unknown>);
        },
      },
    }).concat(privateApiMiddleware, magicLinkMiddleware);
  },
  preloadedState: loadReduxState(),
  enhancers: (getDefaultEnhancers) =>
    getDefaultEnhancers().concat(sentryReduxEnhancer),
});

export type AppDispatch = typeof store.dispatch;

setupListeners(store.dispatch);

export default store;
