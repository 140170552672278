import { Undefined } from "runtypes";
import { privateApi } from "..";
import { APIEndpoints } from "../../../utils/constants";
import {
  Comment,
  CommentId,
  CommentRT,
} from "../../MissionControl/types/base_threads";
import { mutationEndpointBuilder } from "../utils";

const missionControlCommentsApi = privateApi.injectEndpoints({
  endpoints: (builder) => ({
    createComment: mutationEndpointBuilder<void, Comment, FormData>({
      builder,
      metaRuntype: Undefined,
      dataRuntype: CommentRT,
      url: () => APIEndpoints.missionControl.CREATE_COMMENT,
      body: (params) => params,
    }),
    deleteComment: mutationEndpointBuilder<void, void, CommentId>({
      builder,
      metaRuntype: Undefined,
      dataRuntype: Undefined,
      url: () => APIEndpoints.missionControl.DELETE_COMMENT,
      body: (commentId) => ({ commentId }),
    }),
    editComment: mutationEndpointBuilder<void, Comment, FormData>({
      builder,
      metaRuntype: Undefined,
      dataRuntype: CommentRT,
      url: () => APIEndpoints.missionControl.EDIT_COMMENT,
      body: (params) => params,
    }),
  }),
  overrideExisting: false,
});

export const {
  useCreateCommentMutation,
  useDeleteCommentMutation,
  useEditCommentMutation,
} = missionControlCommentsApi;

export default missionControlCommentsApi;
