import { Form, FormItemProps } from "antd";
import {
  createValidatorRule,
  validateAgainstEmptyResults,
} from "../../utils/helper";

interface RequiredFormItemProps extends FormItemProps {
  validatorText?: string;
}

const RequiredFormItem = ({
  validatorText = "This field must not be empty.",
  ...props
}: RequiredFormItemProps): JSX.Element => {
  const { rules, ...rest } = props;
  rest.required = true;
  return (
    <Form.Item
      rules={[
        createValidatorRule(validateAgainstEmptyResults, validatorText),
        ...(rules ?? []),
      ]}
      {...rest}
    />
  );
};

export default RequiredFormItem;
