import { skipToken } from "@reduxjs/toolkit/query";
import { Button, Divider, Form, Select } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useEffect, useState } from "react";
import { SingleCategorySelect } from "../../../components/CategorySelect";
import CompanySelector from "../../../components/CompanySelector";
import MultiProductSelector from "../../../components/MultiProductSelector";
import QuestionnaireTaskSelector from "../../../components/QuestionnaireTaskSelector";
import RequiredFormItem from "../../../components/RequiredFormItem";
import PaginatedUserSelector from "../../../components/UserSelector/PaginatedUserSelector";
import { useGetQuestionnaireThreadQuery } from "../../../features/API/Dashboard/dashboard";
import { useGetTagsQuery } from "../../../features/API/KnowledgeLibrary/knowledgeLibrary";
import {
  useEditPendingQuestionMutation,
  useUploadSinglePendingQuestionMutation,
} from "../../../features/API/pendingQuestions";
import type { CompanyId, ProductId } from "../../../features/API/types";
import { KnowledgeLibraryTag } from "../../../features/KnowledgeLibrary/types";
import { QuestionnaireTaskId } from "../../../features/MissionControl/types/tasks";
import {
  PendingQuestionResponse,
  ProductResponse,
} from "../../../features/pendingQuestions/types";
import { useMessageForMutationResultHook } from "../../../utils/hooks";
import TagSelect from "../../KnowledgeLibraryContainer/components/TagSelect";
import PendingQuestionFormField from "../../VendorAssessContainer/components/VendorAssessmentDetailFormField";
import styles from "./SingleQuestionForm.module.scss";

interface SingleQuestionFormProps {
  onCancel: () => void;
  onOk?: () => void;
  pendingQuestion?: PendingQuestionResponse;
}

const SingleQuestionForm = ({
  onCancel,
  onOk,
  pendingQuestion,
}: SingleQuestionFormProps) => {
  const isEditForm = !!pendingQuestion;
  const [companyId, setCompanyId] = useState<CompanyId | undefined>(
    isEditForm && pendingQuestion.relationships.companyId !== null
      ? (pendingQuestion.relationships.companyId as CompanyId)
      : undefined,
  );
  const [productIds, setProductIds] = useState<ProductId[] | undefined>(
    isEditForm && pendingQuestion.relationships.products !== null
      ? (pendingQuestion.relationships.products as ProductResponse[]).map(
          (product) => product.id as ProductId,
        )
      : undefined,
  );
  const [tags, setTags] = useState<KnowledgeLibraryTag[]>([]);
  const { data: tagsData } = useGetTagsQuery(
    productIds && productIds.length > 0 ? productIds : skipToken,
  );
  useEffect(() => {
    if (!productIds || productIds.length === 0) {
      setTags([]);
    } else {
      setTags(tagsData?.data || []);
    }
  }, [tagsData, productIds]);

  const [form] = Form.useForm();

  const taskId = Form.useWatch("questionnaireTaskId", form);

  const { data: questionnaireTaskData } = useGetQuestionnaireThreadQuery(
    isEditForm
      ? pendingQuestion.relationships.questionnaireTaskId
      : taskId
        ? taskId
        : skipToken,
  );

  useEffect(() => {
    if (!pendingQuestion) return;
    form.setFieldsValue({
      customer: pendingQuestion.relationships.companyId,
      questionnaireTaskId: pendingQuestion.relationships.questionnaireTaskId,
      question: pendingQuestion.attributes.question,
      context: pendingQuestion.attributes.context ?? undefined,
      category: pendingQuestion.attributes.category ?? undefined,
      notes: pendingQuestion.attributes.notes ?? undefined,
      assignee: pendingQuestion.relationships.assignedUser?.id ?? undefined,
      tags: pendingQuestion.relationships.tags?.map((t) => t.id) ?? [],
      productIds:
        pendingQuestion.relationships.products?.map((p) => p.id) ?? [],
    });
  }, [form, pendingQuestion]);

  const [
    uploadQuestion,
    { isLoading: isUploading, status: uploadStatus, error: uploadError },
  ] = useUploadSinglePendingQuestionMutation();
  const [
    editQuestion,
    { isLoading: isSavingEdit, status: saveEditStatus, error: saveEditError },
  ] = useEditPendingQuestionMutation();

  useMessageForMutationResultHook(
    uploadStatus,
    uploadError,
    "Question uploaded successfully",
    "Error uploading question",
  );

  useMessageForMutationResultHook(
    saveEditStatus,
    saveEditError,
    "Question edited successfully",
    "Error editing question",
  );

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();

      if (isEditForm) {
        await editQuestion({
          pendingQuestionId: pendingQuestion.id,
          question: values.question,
          questionnaireTaskId:
            pendingQuestion.relationships.questionnaireTaskId,
          context: values.context,
          category: values.category,
          notes: values.notes,
          tagIds: values.tags,
          assigneeId: values.assignee,
          productIds: values.productIds,
        }).unwrap();
      } else {
        await uploadQuestion({
          question: values.question,
          questionnaireTaskId: values.questionnaireTaskId,
          context: values.context,
          category: values.category,
          notes: values.notes,
          tagIds: values.tags,
          assigneeId: values.assignee,
          productIds: values.productIds,
        }).unwrap();
      }
      form.resetFields();
      setCompanyId(undefined);
      setProductIds(undefined);
      setTags([]);
      onOk?.();
    } catch (_error) {
      // do nothing, error should be handled by useMessageForMutationResultHook
    }
  };
  return (
    <Form layout="horizontal" form={form}>
      <div className={styles.FieldsContainer}>
        <PendingQuestionFormField
          label="Customer"
          description="Select the name of customer"
          required
        >
          <RequiredFormItem name="customer">
            <CompanySelector
              onChange={(id) => {
                setCompanyId(id);
                setProductIds(undefined);
                form.setFieldValue("questionnaireTaskId", undefined);
                form.setFieldValue("productIds", []);
                form.setFieldValue("tags", undefined);
                form.setFieldValue("assignee", undefined);
              }}
              defaultValue={companyId}
              disabled={isEditForm}
            />
          </RequiredFormItem>
        </PendingQuestionFormField>

        <PendingQuestionFormField
          label="Questionnaire"
          description="Select the questionnaire this answer is needed for"
          required
        >
          <RequiredFormItem name="questionnaireTaskId">
            <QuestionnaireTaskSelector
              companyId={companyId}
              statuses={[
                "undergoingCompletion",
                "qaCheck",
                "completedAwaitingReturn",
              ]}
              disabled={isEditForm || !companyId}
              placeholder={
                companyId
                  ? "Please select Questionnaire"
                  : "Please select Customer first"
              }
              defaultValue={
                isEditForm
                  ? (pendingQuestion?.relationships
                      .questionnaireTaskId as QuestionnaireTaskId)
                  : undefined
              }
              onChange={() => {
                setProductIds([]);
                form.setFieldValue("productIds", []);
                form.setFieldValue("tags", undefined);
              }}
            />
          </RequiredFormItem>
        </PendingQuestionFormField>
        <PendingQuestionFormField
          label="Libraries"
          description="Select the libraries this answer is needed for"
          required
        >
          <RequiredFormItem name="productIds">
            <MultiProductSelector
              companyId={companyId}
              options={questionnaireTaskData?.data.relationships.task.relationships.products.map(
                (p) => ({
                  value: p.id,
                  label: p.attributes.productName,
                }),
              )}
              defaultValue={productIds}
              onChange={(ids) => {
                setProductIds(ids);
                form.setFieldValue("tags", undefined);
              }}
              disabled={!taskId}
              placeholder={
                taskId
                  ? "Select Libraries"
                  : "Please select Questionnaire first"
              }
            />
          </RequiredFormItem>
        </PendingQuestionFormField>
        <PendingQuestionFormField
          label="Question"
          description="Add the Pending Question"
          required
        >
          <RequiredFormItem name="question">
            <TextArea />
          </RequiredFormItem>
        </PendingQuestionFormField>
        <PendingQuestionFormField
          label="Context"
          description="Paste the link of Questionnaire and any additional information that gives context to the question"
          required
        >
          <RequiredFormItem name="context">
            <TextArea />
          </RequiredFormItem>
        </PendingQuestionFormField>

        <PendingQuestionFormField
          label="Category"
          description="Select the relevant category for the question"
          required
        >
          <RequiredFormItem name="category">
            <SingleCategorySelect />
          </RequiredFormItem>
        </PendingQuestionFormField>
        <PendingQuestionFormField
          label="Tags"
          description="Select the relevant tags for the question"
        >
          <Form.Item name="tags">
            <TagSelect
              tags={tags}
              disabled={productIds ? false : true}
              placeholder={
                productIds
                  ? "Please select Tags"
                  : "Please select Library first"
              }
            />
          </Form.Item>
        </PendingQuestionFormField>
        <PendingQuestionFormField
          label="Notes"
          description="Add any additional notes that would be helpful to answer the question"
        >
          <Form.Item name="notes">
            <TextArea />
          </Form.Item>
        </PendingQuestionFormField>
        <PendingQuestionFormField
          label="Assignee"
          description="Choose the user you want to assign the question to"
        >
          <Form.Item name="assignee">
            {companyId ? (
              <PaginatedUserSelector filterParams={{ companyId: companyId }} />
            ) : (
              <Select placeholder="Please select Company first" disabled />
            )}
          </Form.Item>
        </PendingQuestionFormField>
      </div>
      <Divider />
      <div className={styles.ActionButtons}>
        <Button onClick={onCancel}>Cancel</Button>
        <Button
          type="primary"
          onClick={handleSubmit}
          loading={isUploading || isSavingEdit}
        >
          {isEditForm ? "Save" : "Upload"}
        </Button>
      </div>
    </Form>
  );
};
export default SingleQuestionForm;
