export const noUserAvatar = (
  <svg
    width="33"
    height="33"
    viewBox="0 0 33 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.344727"
      y="0.640381"
      width="32"
      height="32"
      rx="16"
      fill="black"
      fillOpacity="0.1"
    />
    <path
      d="M26.1485 22.9582L17.664 16.4026C19.2196 15.8515 20.344 14.3848 20.344 12.6426C20.344 10.4337 18.5529 8.64258 16.344 8.64258C14.2462 8.64258 12.5484 10.2604 12.3796 12.3093L7.76621 8.74925C7.54843 8.58036 7.23288 8.61591 7.06399 8.83813L6.45065 9.6248C6.28176 9.84258 6.31732 10.1581 6.53954 10.3226L24.9263 24.5315C25.144 24.7004 25.4596 24.6648 25.6285 24.4426L26.2418 23.6515C26.4107 23.4426 26.3663 23.1271 26.1485 22.9582ZM9.34399 21.8426V23.1404C9.34399 23.9671 10.0151 24.6382 10.8418 24.6382H21.784L12.824 17.7137C10.8507 18.0604 9.34399 19.7671 9.34399 21.8426Z"
      fill="#8C8C8C"
    />
  </svg>
);
