import { Input, Modal, ModalProps, message } from "antd";
import { useState } from "react";

type ConfirmationModalProps = Omit<ModalProps, "onOk" | "confirmLoading"> & {
  onConfirm: () => Promise<void>;
  children: React.ReactNode;
  confirmationValue: string;
};

const ConfirmationModal = ({
  onConfirm,
  children,
  confirmationValue,
  ...props
}: ConfirmationModalProps): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const [enteredValue, setEnteredValue] = useState("");

  return (
    <Modal
      confirmLoading={loading}
      onOk={async () => {
        if (enteredValue.toLowerCase() !== confirmationValue.toLowerCase()) {
          message.error("Incorrect confirmation value.");
          return;
        }
        setLoading(true);
        try {
          await onConfirm();
        } finally {
          setLoading(false);
        }
      }}
      {...props}
    >
      {children}
      <Input onChange={(e) => setEnteredValue(e.target.value)}></Input>
    </Modal>
  );
};

export default ConfirmationModal;
