import dayjs from "dayjs";
import { ProductId, UserId } from "../API/types";
import { QuestionnaireId } from "../Questionnaires/types";
import {
  AnswerEntryFilterMethod,
  AnswerEntrySearchFilters,
  AnswerEntrySearchOrdering,
  DocumentSearchFilters,
  KnowledgeLibraryTagId,
} from "./types";

export const MULTIPLE_LIBRARY_IDS_QUERY_KEY = "libraryIds";
export const searchParamsToQASearchFilter = (
  searchParams: URLSearchParams,
): AnswerEntrySearchFilters => {
  const productIds = getProductParams(searchParams) ?? [];

  const [beforeDate, afterDate, beforeNextReviewDate, afterNextReviewDate] = [
    "beforeDate",
    "afterDate",
    "beforeNextReviewDate",
    "afterNextReviewDate",
  ]
    .map((item) => searchParams.get(item))
    .map((item) => (item ? dayjs(item) : undefined));

  return {
    productIds,
    query: searchParams.get("query") ?? "",
    sourceQuestionnaireIds: searchParams.getAll(
      "sourceQuestionnaireIds",
    ) as QuestionnaireId[],
    ordering:
      (searchParams.get("ordering") as AnswerEntrySearchOrdering) ??
      AnswerEntrySearchOrdering.relevance,
    onlyShowReviewed: searchParams.get("onlyShowReviewed") === "true",
    returnOutdatedReviewDate:
      searchParams.get("returnOutdatedReviewDate") === "true",
    tagsOperator: searchParams.get("tagsOperator") as AnswerEntryFilterMethod,
    tags: searchParams.getAll("tags") as KnowledgeLibraryTagId[],
    ownerUserIds: searchParams
      .getAll("ownerUserIds")
      .map((id) => parseInt(id) as UserId),
    categories: searchParams.getAll("categories"),
    beforeDate,
    afterDate,
    beforeNextReviewDate,
    afterNextReviewDate,
    noOwner: searchParams.get("noOwner") === "true",
  };
};

export const QASearchFilterToSearchParams = (
  searchFilter: AnswerEntrySearchFilters,
): URLSearchParams => {
  const searchParams = new URLSearchParams();
  setProductParams(searchFilter, searchParams);
  searchParams.set("query", searchFilter.query);
  searchFilter.sourceQuestionnaireIds?.forEach((id) =>
    searchParams.append("sourceQuestionnaireIds", id.toString()),
  );
  searchParams.set("ordering", searchFilter.ordering);
  searchParams.set(
    "onlyShowReviewed",
    searchFilter.onlyShowReviewed.toString(),
  );
  searchParams.set(
    "returnOutdatedReviewDate",
    searchFilter.returnOutdatedReviewDate.toString(),
  );
  searchFilter.categories?.forEach((category) =>
    searchParams.append("categories", category),
  );
  searchParams.append("tagsOperator", searchFilter.tagsOperator);
  searchFilter.tags?.forEach((tag) => searchParams.append("tags", tag));
  searchFilter.ownerUserIds?.forEach((userId) =>
    searchParams.append("ownerUserIds", userId.toString()),
  );
  searchFilter.beforeDate &&
    searchParams.set("beforeDate", searchFilter.beforeDate.toISOString());
  searchFilter.afterDate &&
    searchParams.set("afterDate", searchFilter.afterDate.toISOString());
  searchFilter.beforeNextReviewDate &&
    searchParams.set(
      "beforeNextReviewDate",
      searchFilter.beforeNextReviewDate.toISOString(),
    );
  searchFilter.afterNextReviewDate &&
    searchParams.set(
      "afterNextReviewDate",
      searchFilter.afterNextReviewDate.toISOString(),
    );
  searchParams.set("page", "1");
  searchFilter.noOwner &&
    searchParams.set("noOwner", searchFilter.noOwner.toString());
  return searchParams;
};

export const searchParamsToDocSearchFilter = (
  searchParams: URLSearchParams,
): DocumentSearchFilters => {
  const productIds = getProductParams(searchParams) ?? [];

  const [beforeNextReviewDate, afterNextReviewDate] = [
    "beforeNextReviewDate",
    "afterNextReviewDate",
  ]
    .map((item) => searchParams.get(item))
    .map((item) => (item ? dayjs(item) : undefined));

  return {
    productIds,
    query: searchParams.get("query") ?? "",
    onlyShowReviewed: searchParams.get("onlyShowReviewed") === "true",
    returnOutdatedReviewDate:
      searchParams.get("returnOutdatedReviewDate") === "true",
    tagsOperator: searchParams.get("tagsOperator") as AnswerEntryFilterMethod,
    tags: searchParams.getAll("tags") as KnowledgeLibraryTagId[],
    ownerUserIds: searchParams
      .getAll("ownerUserIds")
      .map((id) => parseInt(id) as UserId),
    beforeNextReviewDate,
    afterNextReviewDate,
  };
};
export const docFilterToSearchParams = (
  searchFilter: DocumentSearchFilters,
): URLSearchParams => {
  const searchParams = new URLSearchParams();
  setProductParams(searchFilter, searchParams);
  searchParams.set("query", searchFilter.query);
  searchParams.append("tagsOperator", searchFilter.tagsOperator);
  searchFilter.tags?.forEach((tag) => searchParams.append("tags", tag));
  searchFilter.ownerUserIds?.forEach((userId) =>
    searchParams.append("ownerUserIds", userId.toString()),
  );
  searchParams.set(
    "onlyShowReviewed",
    searchFilter.onlyShowReviewed.toString(),
  );
  searchParams.set(
    "returnOutdatedReviewDate",
    searchFilter.returnOutdatedReviewDate.toString(),
  );
  if (searchFilter.beforeNextReviewDate) {
    searchParams.set(
      "beforeNextReviewDate",
      searchFilter.beforeNextReviewDate.toISOString(),
    );
  }
  if (searchFilter.afterNextReviewDate) {
    searchParams.set(
      "afterNextReviewDate",
      searchFilter.afterNextReviewDate.toISOString(),
    );
  }
  return searchParams;
};

export const getProductParams = (searchParams: URLSearchParams) => {
  const productIdsString = searchParams.get(MULTIPLE_LIBRARY_IDS_QUERY_KEY);
  const productIds = productIdsString
    ? (productIdsString.split(",").map((v) => parseInt(v)) as ProductId[])
    : undefined;

  return productIds;
};

const setProductParams = (
  searchFilters: DocumentSearchFilters | AnswerEntrySearchFilters,
  searchParams: URLSearchParams,
) => {
  searchParams.set(
    MULTIPLE_LIBRARY_IDS_QUERY_KEY,
    searchFilters.productIds.map((id) => id.toString()).join(","),
  );
};
