import {
  Literal,
  Number,
  Optional,
  Record,
  Static,
  String,
  Union,
} from "runtypes";

const SSOCheckAuthenticationTypeResultRT = Record({
  authProvider: Literal("sso"),
  url: String,
});

const NonSSOAuthProvider = Union(
  Literal("email"),
  Literal("google"),
  Literal("microsoft"),
);

export const AuthErrorRT = Record({
  data: Record({
    email: Optional(String),
    detail: Optional(String),
  }),
  status: Number,
});

const NonSSOCheckAuthenticationTypeResultRT = Record({
  authProvider: NonSSOAuthProvider,
});

export const CheckAuthenticationResultRT = Union(
  SSOCheckAuthenticationTypeResultRT,
  NonSSOCheckAuthenticationTypeResultRT,
);
export type CheckAuthenticationResult = Static<
  typeof CheckAuthenticationResultRT
>;
