import { Product } from "../API/types";

export const ACTION_TYPE_LAYOUT = {
  CHANGE_CLIENT: "CHANGE_CLIENT",
  FETCH_CLIENTS: "FETCH_CLIENTS",
  FETCH_CLIENTS_SUCCESS: "FETCH_CLIENTS_SUCCESS",
  FETCH_CLIENTS_ERROR: "FETCH_CLIENTS_ERROR",

  RESET_MESSAGE: "RESET_MESSAGE",
  TOGGLE_DARK_MODE: "TOGGLE_DARK_MODE",
};

export const sortProductsByName = (a: Product, b: Product): -1 | 0 | 1 => {
  const aFilename = a.attributes.productName.toLowerCase();
  const bFilename = b.attributes.productName.toLowerCase();
  if (aFilename < bFilename) {
    return -1;
  }
  if (bFilename < aFilename) {
    return 1;
  }
  return 0;
};
