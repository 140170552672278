import { Button, Divider, Select } from "antd";
import { SelectProps } from "antd/lib";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { CompanyId, ProductId } from "../../features/API/types";
import { useGetCompanyProducts } from "../../utils/hooks";
import styles from "./MultiProductSelector.module.css";

interface ProductOption {
  value: ProductId;
  label: string;
}

type MultiProductSelectorProps = SelectProps & {
  options?: ProductOption[];
  companyId?: CompanyId;
};

const MultiProductSelector = ({
  options,
  companyId,
  ...rest
}: MultiProductSelectorProps): JSX.Element => {
  const defaultOptions = useGetCompanyProducts(companyId).map((p) => ({
    label: p.attributes.productName,
    value: p.id,
  }));
  const [currentValue, setCurrentValue] = useState<ProductId[]>(
    rest.defaultValue ?? rest.value ?? [],
  );

  useEffect(() => {
    setCurrentValue(rest.value ?? []);
  }, [rest.value]);

  const selectOptions = options || defaultOptions;

  const selectOnChange = (
    value: ProductId[],
    option: ProductOption | ProductOption[],
  ) => {
    setCurrentValue(value);
    rest.onChange?.(value, option);
  };
  const showSelectAllButton =
    currentValue.length !== selectOptions.length && selectOptions.length > 1;
  const showDeselectAllButton =
    currentValue.length === selectOptions.length && selectOptions.length > 1;

  return (
    <Select<ProductId[], ProductOption>
      {...rest}
      value={currentValue}
      onChange={selectOnChange}
      showSearch
      filterOption={(inputVal, option) =>
        option?.label.toLowerCase().includes(inputVal.toLowerCase()) ?? false
      }
      className={clsx(styles.Selector, rest.className)}
      mode="multiple"
      aria-label="Library selector"
      options={selectOptions}
      dropdownRender={(menu) => (
        <>
          {(showSelectAllButton || showDeselectAllButton) && (
            <Button
              className={styles.SelectAllButton}
              type="text"
              onClick={() => {
                if (showSelectAllButton) {
                  selectOnChange(
                    selectOptions.map((o) => o.value),
                    selectOptions,
                  );
                  return;
                }
                selectOnChange([], []);
              }}
            >
              <strong>
                {showSelectAllButton ? "Select all" : "Deselect all"}
              </strong>
            </Button>
          )}
          <Divider className={styles.SelectAllDivider} />
          {menu}
        </>
      )}
    />
  );
};

export default MultiProductSelector;
