import { FormItemProps } from "antd";

import RequiredFormItem from "../RequiredFormItem";

interface RequiredEmailFormItemProps extends FormItemProps {
  emailValidatorText?: string;
  requiredValidatorText?: string;
}

const RequiredEmailFormItem = ({
  emailValidatorText = "Please enter a valid email address.",
  requiredValidatorText,
  ...props
}: RequiredEmailFormItemProps): JSX.Element => {
  const { rules, ...rest } = props;
  return (
    <RequiredFormItem
      validatorText={requiredValidatorText}
      rules={[{ type: "email", message: emailValidatorText }, ...(rules ?? [])]}
      validateTrigger="onBlur"
      {...rest}
    />
  );
};

export default RequiredEmailFormItem;
