import {
  Draft,
  PayloadAction,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { RootState } from "../../store";
import missionControlCommentsApi from "../API/MissionControl/comments";
import missionControlDraftsApi from "../API/MissionControl/drafts";
import missionControlLabelsApi from "../API/MissionControl/labels";
import missionControlThreadsApi from "../API/MissionControl/threads";
import { RequestThreadId } from "./types/base";
import { RequestThreadType } from "./types/base_threads";
import { GmailDraftMessage, GmailRequestThread } from "./types/gmail";
import { Label, LabelId } from "./types/labels";
import { MailSocketMessage } from "./types/socket";
import { RequestThread } from "./types/unions";

export const MISSION_CONTROL_KEY = "missionControl";

export interface MissionControlState {
  threadsById: Record<RequestThreadId, RequestThread>;
}
const initialState: MissionControlState = { threadsById: {} };

const upsertMatchFulfilled = (
  state: Draft<MissionControlState>,
  upsertedDraft: GmailDraftMessage,
) => {
  const gmailMessageId = upsertedDraft.attributes.gmailMessageId;

  const threads = Object.values(state.threadsById).filter(
    (t) => t.type === RequestThreadType.GMAIL,
  ) as GmailRequestThread[];

  for (let i = 0; i < threads.length; i++) {
    const messageIndex = threads[i].relationships.messages.findIndex(
      (message) => message.id === gmailMessageId,
    );

    if (messageIndex > -1) {
      const messageToUpdate = threads[i].relationships.messages[messageIndex];
      const draftIndex = messageToUpdate.relationships.drafts.findIndex(
        (draft) => draft.id === upsertedDraft.id,
      );

      if (draftIndex > -1) {
        messageToUpdate.relationships.drafts[draftIndex] = upsertedDraft;
      } else {
        messageToUpdate.relationships.drafts.push(upsertedDraft);
      }

      break;
    }
  }
};

const missionControlSlice = createSlice({
  name: MISSION_CONTROL_KEY,
  initialState,
  reducers: {
    removeThread(state, action: PayloadAction<RequestThreadId>) {
      delete state.threadsById[action.payload];
    },
    handleSocketMessage(state, action: PayloadAction<MailSocketMessage>) {
      const socketMessage = action.payload;
      const threads = Object.values(state.threadsById);
      switch (socketMessage.eventType) {
        case "deleteLabels":
          threads.forEach((t) => {
            t.relationships.labels = t.relationships.labels.filter(
              (l) => !socketMessage.objectIds.includes(l.id),
            );
          });
          break;
        case "deleteThreads":
          socketMessage.objectIds.forEach((id) => delete state.threadsById[id]);
          break;
        case "updateLabels": {
          const updatedIds: Record<LabelId, Label> = {};
          socketMessage.updatedObjects.forEach((l) => (updatedIds[l.id] = l));
          threads.forEach((t) => {
            t.relationships.labels = t.relationships.labels.map(
              (l) => updatedIds[l.id] ?? l,
            );
          });
          break;
        }
        case "updateThreads": {
          const newThreads = socketMessage.updatedObjects;
          newThreads.forEach((t) => (state.threadsById[t.id] = t));
          break;
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      missionControlThreadsApi.endpoints.getThreads.matchFulfilled,
      (state, action) => {
        action.payload.data.forEach((t) => (state.threadsById[t.id] = t));
      },
    );
    builder.addMatcher(
      missionControlThreadsApi.endpoints.getThread.matchFulfilled,
      (state, action) => {
        const thread = action.payload.data;
        state.threadsById[thread.id] = thread;
      },
    );
    [
      missionControlDraftsApi.endpoints.createGmailDraft,
      missionControlDraftsApi.endpoints.updateGmailDraft,
      missionControlDraftsApi.endpoints.takeOverDraft,
      missionControlDraftsApi.endpoints.undoSendDraft,
    ].forEach((endpoint) => {
      builder.addMatcher(endpoint.matchFulfilled, (state, action) => {
        upsertMatchFulfilled(state, action.payload.data);
      });
    });
    builder.addMatcher(
      missionControlDraftsApi.endpoints.scheduleSendDraft.matchFulfilled,
      (state, action) => {
        upsertMatchFulfilled(state, action.payload.data.draft);
      },
    );

    builder.addMatcher(
      missionControlThreadsApi.endpoints.threadSetCompany.matchFulfilled,
      (state, action) => {
        state.threadsById[action.payload.data.id] = action.payload.data;
      },
    );
    builder.addMatcher(
      missionControlThreadsApi.endpoints.assignUser.matchFulfilled,
      (state, action) => {
        state.threadsById[action.payload.data.id] = action.payload.data;
      },
    );
    builder.addMatcher(
      missionControlThreadsApi.endpoints.updateSeen.matchFulfilled,
      (state, action) => {
        const { seen, threadIds } = action.meta.arg.originalArgs;
        threadIds.forEach((id) => {
          state.threadsById[id].attributes.seen = seen;
        });
      },
    );
    builder.addMatcher(
      missionControlThreadsApi.endpoints.updateBookmarked.matchFulfilled,
      (state, action) => {
        const { bookmarked, threadIds } = action.meta.arg.originalArgs;
        threadIds.forEach((id) => {
          state.threadsById[id].attributes.bookmarked = bookmarked;
        });
      },
    );
    builder.addMatcher(
      missionControlThreadsApi.endpoints.setInbox.matchFulfilled,
      (state, action) => {
        const { inbox, threadIds } = action.meta.arg.originalArgs;
        threadIds.forEach((id) => {
          state.threadsById[id].attributes.inbox = inbox;
        });
      },
    );
    builder.addMatcher(
      missionControlThreadsApi.endpoints.archive.matchFulfilled,
      (state, action) => {
        const { archive, threadIds } = action.meta.arg.originalArgs;
        threadIds.forEach((id) => {
          state.threadsById[id].attributes.isArchived = archive;
        });
      },
    );
    builder.addMatcher(
      missionControlLabelsApi.endpoints.deleteLabel.matchFulfilled,
      (state, action) => {
        const labelIdToDelete = action.meta.arg.originalArgs.id;
        const threads = Object.values(state.threadsById);
        threads.forEach((t) => {
          t.relationships.labels = t.relationships.labels.filter(
            (l) => l.id !== labelIdToDelete,
          );
        });
      },
    );
    builder.addMatcher(
      missionControlLabelsApi.endpoints.editLabel.matchFulfilled,
      (state, action) => {
        const editedLabel = action.payload.data;
        const threads = Object.values(state.threadsById);
        threads.forEach((t) => {
          const labelIndex = t.relationships.labels.findIndex(
            (l) => l.id === editedLabel.id,
          );
          if (labelIndex > -1) {
            t.relationships.labels[labelIndex] = editedLabel;
          }
        });
      },
    );
    builder.addMatcher(
      missionControlCommentsApi.endpoints.createComment.matchFulfilled,
      (state, action) => {
        const threadId = action.meta.arg.originalArgs.get(
          "threadId",
        ) as RequestThreadId;
        const thread = state.threadsById[threadId];
        thread?.relationships.comments.push(action.payload.data);
      },
    );
    builder.addMatcher(
      missionControlCommentsApi.endpoints.deleteComment.matchFulfilled,
      (state, action) => {
        const commentIdToDelete = action.meta.arg.originalArgs;
        const threads = Object.values(state.threadsById);
        for (let i = 0; i < threads.length; i++) {
          if (
            threads[i].relationships.comments.some(
              (c) => c.id === commentIdToDelete,
            )
          ) {
            threads[i].relationships.comments = threads[
              i
            ].relationships.comments.filter((c) => c.id !== commentIdToDelete);
            break;
          }
        }
      },
    );
    builder.addMatcher(
      missionControlCommentsApi.endpoints.editComment.matchFulfilled,
      (state, action) => {
        const editedComment = action.payload.data;
        const threads = Object.values(state.threadsById);
        for (let i = 0; i < threads.length; i++) {
          const commentIndex = threads[i].relationships.comments.findIndex(
            (c) => c.id === editedComment.id,
          );
          if (commentIndex > -1) {
            threads[i].relationships.comments[commentIndex] = editedComment;
            break;
          }
        }
      },
    );
  },
});

export const { handleSocketMessage, removeThread } =
  missionControlSlice.actions;

export const selectCachedThreadsForIds = createSelector(
  [
    (state: RootState) => state.missionControl.threadsById,
    (_: RootState, threadIds: RequestThreadId[] | undefined) => threadIds,
  ],
  (threadsById, threadIds) =>
    threadIds ? threadIds.map((id) => threadsById[id]) : undefined,
);

export const selectCachedThreadForId = createSelector(
  [
    (state: RootState) => state.missionControl.threadsById,
    (_: RootState, threadId: RequestThreadId) => threadId,
  ],
  (threadsById, threadId) => threadsById[threadId],
);

export default missionControlSlice.reducer;
