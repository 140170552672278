import { Runtype, Unknown } from "runtypes";

// Adapted from https://github.com/pelotom/runtypes/issues/66#issuecomment-788129292
export const runtypeFromEnum = <EnumType>(
  theEnum: Record<string, EnumType>,
): Runtype<EnumType> => {
  const values = Object.values<unknown>(theEnum);
  const isEnumValue = (input: unknown): input is EnumType =>
    values.includes(input);
  const errorMessage = (input: unknown): string =>
    `Failed constraint check. Received ${JSON.stringify(
      input,
    )}, but expected one of ${JSON.stringify(values)}`;
  return Unknown.withConstraint<EnumType>(
    (object: unknown) => isEnumValue(object) || errorMessage(object),
  );
};
