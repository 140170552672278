import { Array, Number, Optional, Record, Static, String } from "runtypes";
import { CompanyIdRT, RBACRoleRT } from "../API/types";

export type SSOAdminPortalLinkType = "sso" | "dsync";

export const GetDsyncGroupsMetaRT = Record({
  totalCount: Number,
});

export type GetDsyncGroupsMeta = Static<typeof GetDsyncGroupsMetaRT>;

export const DsyncGroupIdRT = String.withBrand("dsyncGroup");
export type DsyncGroupId = Static<typeof DsyncGroupIdRT>;

export const DsyncGroupRT = Record({
  id: DsyncGroupIdRT,
  name: String,
});
export type DsyncGroup = Static<typeof DsyncGroupRT>;

export const AssociateGroupParamsRT = Record({
  dsyncId: Optional(String),
  accessGroupId: Optional(String),
  groupName: Optional(String),
  productIds: Array(Number),
  role: RBACRoleRT,
  companyId: CompanyIdRT,
});
export type AssociateGroupParams = Static<typeof AssociateGroupParamsRT>;
