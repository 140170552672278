import { Static, String } from "runtypes";
export const SearchQueryIdRT = String.withBrand("searchQuery");
export type SearchQueryId = Static<typeof SearchQueryIdRT>;
export enum SearchMetrics {
  THUMBS_UP = "thumbs_up",
  THUMBS_DOWN = "thumbs_down",
  NO_INTERACTION = "no_interaction",
  SIMILARITY_CHECK = "similarity_check",
  ANSWER_COPIED = "answer_copied",
  LINK_COPIED = "link_copied",
  INTERACTED_WITH_SOURCES = "interacted_with_sources",
  DETAILS_PRESSED = "details_section_clicked",
  PAGE_CHANGED = "page_changed",
  KL = "kl",
  COPILOT = "copilot",
}
