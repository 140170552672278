import { Modal } from "antd";
import { PendingQuestionResponse } from "../../../features/pendingQuestions/types";
import styles from "./EditQuestionModal.module.scss";
import SingleQuestionForm from "./SingleQuestionForm";

interface EditQuestionModalProps {
  onCancel: () => void;
  onOk: () => void;
  pendingQuestion: PendingQuestionResponse;
}

const EditQuestionModal = ({
  onCancel,
  onOk,
  pendingQuestion,
}: EditQuestionModalProps): JSX.Element => {
  return (
    <Modal
      open
      title="Edit Pending Question"
      footer={null}
      onCancel={onCancel}
      className={styles.EditModal}
      width={"75%"}
    >
      <SingleQuestionForm
        pendingQuestion={pendingQuestion}
        onCancel={onCancel}
        onOk={onOk}
      />
    </Modal>
  );
};

export default EditQuestionModal;
