import { Checkbox, CheckboxProps, Typography } from "antd";
import { useContext } from "react";
import { ProfileVersionContext } from "../AssuranceProfileWrapper";
import styles from "./RequestInfoForm.module.scss";

interface Agreement {
  name: string;
  url?: string;
}

interface CustomAgreementCheckboxProps extends CheckboxProps {
  accentColor?: string;
}

const CustomAgreementCheckbox = ({
  ...props
}: CustomAgreementCheckboxProps): JSX.Element => {
  const profileVersion = useContext(ProfileVersionContext);
  const requestInfoSettings =
    profileVersion?.relationships.requestInformationSettings.attributes;
  const agreements = {
    privacyPolicy: requestInfoSettings?.privacyPolicyUrl,
    termsOfService: requestInfoSettings?.termsOfServiceUrl,
  };

  const currAgreements: Agreement[] = [];
  if (agreements.privacyPolicy) {
    currAgreements.push({
      name: "Privacy Policy",
      url: agreements.privacyPolicy,
    });
  }

  if (agreements.termsOfService) {
    currAgreements.push({
      name: "Terms of Service",
      url: agreements.termsOfService,
    });
  }

  return (
    <Checkbox {...props} data-testid="custom-agreement">
      I agree to {profileVersion?.attributes.companyName}&apos;s{" "}
      {currAgreements.map((agreement, index) => (
        <span key={agreement.name}>
          {index > 0 && (index === currAgreements.length - 1 ? " and " : ", ")}
          {agreement.url ? (
            <Typography.Link
              className={styles.AgreementLink}
              href={agreement.url}
              target="_blank"
              rel="noreferrer"
              style={{
                borderBottomColor: props.accentColor,
                color: props.accentColor,
              }}
            >
              {agreement.name}
            </Typography.Link>
          ) : (
            agreement.name
          )}
        </span>
      ))}
      .
    </Checkbox>
  );
};

export default CustomAgreementCheckbox;
