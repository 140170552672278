import { PageHeader, PageHeaderProps } from "@ant-design/pro-layout";
import clsx from "clsx";
import styles from "./AppHeader.module.css";

interface AppHeaderProps extends PageHeaderProps {
  truncate?: boolean;
}

const AppHeader: React.FC<AppHeaderProps> = ({
  children,
  truncate = true,
  ...props
}): JSX.Element => {
  const { className, ...rest } = props;

  return (
    <PageHeader
      className={clsx(styles.AppHeader, className, {
        [styles.NoTruncate]: !truncate,
      })}
      {...rest}
    >
      {children}
    </PageHeader>
  );
};

export default AppHeader;
