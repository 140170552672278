import { Static, String, Union } from "runtypes";

const HEX3_COLOR_REGEX = /^#[a-fA-F0-9]{3}$/;
const isHex3ColorFromString = (colorString: string): boolean =>
  HEX3_COLOR_REGEX.test(colorString);

const HEX6_COLOR_REGEX = /^#[a-fA-F0-9]{6}$/;
const isHex6ColorFromString = (colorString: string): boolean =>
  HEX6_COLOR_REGEX.test(colorString);

const Hex3ColorRT = String.withBrand("Hex3Color").withConstraint(
  (color) => isHex3ColorFromString(color) || "Invalid color",
);
export const Hex6ColorRT = String.withBrand("Hex6Color").withConstraint(
  (color) => isHex6ColorFromString(color) || "Invalid color",
);
export const HexColorRT = Union(Hex3ColorRT, Hex6ColorRT);

export type Hex3Color = Static<typeof Hex3ColorRT>;
export type Hex6Color = Static<typeof Hex6ColorRT>;
export type HexColor = Static<typeof HexColorRT>;

export const isHex6Color = (color: HexColor): color is Hex6Color => {
  return isHex6ColorFromString(color);
};
