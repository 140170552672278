import { Array, Literal, Number, Record, Static, String } from "runtypes";
import { CompanyRT, ISODateStrRT, PartialUserRT } from "../../API/types";
import { FileBaseRT, FileType } from "./base";
import {
  CommentRT,
  EmailParticipantRT,
  NonStaffBaseRequestThreadRT,
  RequestThreadBaseAttributesRT,
  RequestThreadBaseRT,
  RequestThreadBaseRelationshipsRT,
  RequestThreadType,
} from "./base_threads";
import { RequestThreadEventRT } from "./events";
import { QuestionnaireTaskRT } from "./tasks";

const GmailDraftMessageIdRT = String.withBrand("GmailDraftMessageId");
export type GmailDraftMessageId = Static<typeof GmailDraftMessageIdRT>;
const GlobalMessageIdRT = String.withBrand("GlobalMessageId");
export type GlobalMessageId = Static<typeof GlobalMessageIdRT>;

//Gmail's message id
const GmailExternalMessageIdRT = String.withBrand("GmailExternalMessageId");
export type GmailExternalMessageId = Static<typeof GmailExternalMessageIdRT>;

//Our id for the GmailMessage
const GmailMessageIdRT = Number.withBrand("GmailMessageId");
export type GmailMessageId = Static<typeof GmailMessageIdRT>;
const GmailThreadIdRT = String.withBrand("GmailThreadId");
export type GmailThreadId = Static<typeof GmailThreadIdRT>;

export const GmailDraftAttachmentRT = FileBaseRT.extend({
  type: Literal(FileType.gmailDraftAttachment),
});

export type GmailDraftAttachment = Static<typeof GmailDraftAttachmentRT>;

const GmailDraftMessageAttributesRT = Record({
  gmailMessageId: GmailMessageIdRT.nullable(),
  subject: String.nullable(),
  to: Array(EmailParticipantRT),
  cc: Array(EmailParticipantRT),
  bcc: Array(EmailParticipantRT),
  replyTo: Array(EmailParticipantRT),
  body: String.nullable(),
  createdAt: ISODateStrRT,
  updatedAt: ISODateStrRT,
  scheduledSendAt: ISODateStrRT.nullable(),
});

const GmailDraftMessageRelationshipsRT = Record({
  attachments: Array(GmailDraftAttachmentRT),
  scheduledSendBy: PartialUserRT.nullable(),
  ownedBy: PartialUserRT,
});

export const GmailDraftMessageRT = Record({
  type: Literal("draftGmailMessage"),
  id: GmailDraftMessageIdRT,
  attributes: GmailDraftMessageAttributesRT,
  relationships: GmailDraftMessageRelationshipsRT,
});

export const Base64DataRT = String;
export type Base64Data = Static<typeof Base64DataRT>;

export const ScheduledGmailDraftMessageRT = GmailDraftMessageRT.extend({
  attributes: GmailDraftMessageAttributesRT.extend({
    scheduledSendAt: ISODateStrRT,
  }),
  relationships: GmailDraftMessageRelationshipsRT.extend({
    scheduledSendBy: PartialUserRT,
  }),
});

export type ScheduledGmailDraftMessage = Static<
  typeof ScheduledGmailDraftMessageRT
>;

export const GmailAttachmentMetadataRT = FileBaseRT.extend({
  type: Literal(FileType.gmailAttachment),
});

export type GmailAttachmentMetadata = Static<typeof GmailAttachmentMetadataRT>;

export type GmailDraftMessage = Static<typeof GmailDraftMessageRT>;

const NonStaffGmailMessageAttributesRT = Record({
  subject: String.nullable(),
  body: String.nullable(),
  snippet: String.nullable(),
  sender: EmailParticipantRT,
  to: Array(EmailParticipantRT),
  cc: Array(EmailParticipantRT),
  bcc: Array(EmailParticipantRT),
  replyTo: Array(EmailParticipantRT),
  deliveredAt: ISODateStrRT.optional(),
});

const NonStaffGmailMessageRT = Record({
  type: Literal("gmailMessage"),
  id: GmailMessageIdRT,
  attributes: NonStaffGmailMessageAttributesRT,
  relationships: Record({
    attachments: Array(GmailAttachmentMetadataRT),
  }),
});

export type NonStaffGmailMessage = Static<typeof NonStaffGmailMessageRT>;

export const GmailMessageRT = Record({
  type: Literal("gmailMessage"),
  id: GmailMessageIdRT,
  attributes: NonStaffGmailMessageAttributesRT.extend({
    globalMessageId: GlobalMessageIdRT,
    gmailThreadId: GmailThreadIdRT,
    gmailMessageId: GmailExternalMessageIdRT,
    internalSender: PartialUserRT.nullable(),
    createdAt: ISODateStrRT,
    updatedAt: ISODateStrRT,
  }),
  relationships: Record({
    attachments: Array(GmailAttachmentMetadataRT),
    drafts: Array(GmailDraftMessageRT),
  }),
});
export type GmailMessage = Static<typeof GmailMessageRT>;

export const GmailRequestThreadRT = RequestThreadBaseRT.extend({
  type: Literal(RequestThreadType.GMAIL),
  attributes: RequestThreadBaseAttributesRT.extend({
    externalId: GmailThreadIdRT,
    snippet: String.nullable(),
  }),
  relationships: RequestThreadBaseRelationshipsRT.extend({
    messages: Array(GmailMessageRT),
  }),
});
export type GmailRequestThread = Static<typeof GmailRequestThreadRT>;

export const NonStaffGmailRequestThreadRT = NonStaffBaseRequestThreadRT.extend({
  type: Literal(RequestThreadType.GMAIL),
  relationships: Record({
    task: QuestionnaireTaskRT,
    comments: Array(CommentRT),
    company: CompanyRT,
    messages: Array(NonStaffGmailMessageRT),
    events: Array(RequestThreadEventRT),
  }),
});
