import { privateApi } from ".";
import { APIEndpoints } from "../../utils/constants";

import { Undefined } from "runtypes";
import { ReferralResponse } from "../Referral/types";
import { mutationEndpointBuilder, queryEndpointBuilder } from "./utils";

type submitReferralRequestType = {
  firstName: string;
  lastName: string;
  email: string;
  notes: string | null;
  companyName: string;
};

const extendedApi = privateApi.injectEndpoints({
  endpoints: (builder) => ({
    getReferrals: queryEndpointBuilder<void, ReferralResponse[], void>({
      builder,
      metaRuntype: Undefined,
      dataRuntype: undefined,
      url: () => {
        return APIEndpoints.referrals.GET_REFERRALS;
      },
      extraOptions: {
        checkRunTypes: false,
      },
    }),
    submitReferral: mutationEndpointBuilder<
      void,
      void,
      submitReferralRequestType
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: Undefined,
      url: () => APIEndpoints.referrals.SUBMIT_REFERRAL,
      body: (params) => params,
      extraOptions: {
        checkRunTypes: false,
      },
    }),
  }),
});

export const { useGetReferralsQuery, useSubmitReferralMutation } = extendedApi;
