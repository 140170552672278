import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Divider } from "antd";

import { useNavigate } from "react-router-dom";
import AppHeader from "../../../components/AppHeader";
import SingleQuestionForm from "./SingleQuestionForm";
import styles from "./UploadSingleQuestion.module.scss";
const UploadSingleQuestion = (): JSX.Element => {
  const navigate = useNavigate();
  const goToPendingQuestionsInternal = () => {
    navigate("/pending-questions-internal/");
  };
  return (
    <>
      <div className={styles.HeaderWithBackButton}>
        <Button
          className={styles.BackButton}
          onClick={goToPendingQuestionsInternal}
          icon={<ArrowLeftOutlined />}
        />
        <AppHeader title="Upload Question" className="Title" />
      </div>

      <Divider className={styles.Divider} />
      <SingleQuestionForm onCancel={goToPendingQuestionsInternal} />
    </>
  );
};

export default UploadSingleQuestion;
