import { AppProps, Card } from "antd";
import clsx from "clsx";
import styles from "./AssuranceProfileCard.module.css";

interface AssuranceProfileCardProps extends AppProps {}

//wrapper to include custom styles
const AssuranceProfileCard = ({
  ...rest
}: AssuranceProfileCardProps): JSX.Element => (
  <Card {...rest} className={clsx(styles.Card, rest.className, "Tile")} />
);

export default AssuranceProfileCard;
