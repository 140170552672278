import {
  Array,
  Boolean,
  Literal,
  Number,
  Record,
  Static,
  String,
  Union,
} from "runtypes";
import { QuestionnaireTypeOptionRT } from "../../API/MissionControl/types";
import {
  ISODateStrRT,
  PartialUserRT,
  ProductRT,
  QuestionnaireTaskStatusRT,
  SimpleUserRT,
} from "../../API/types";
import { KnowledgeLibraryTagRT } from "../../KnowledgeLibrary/types";
import { PRISMEntryRT } from "../../Questionnaires/types";
import {
  ExpediteRequestStatusRT,
  FileAttributesRT,
  FileBaseRT,
  FileType,
} from "./base";

export const QuestionnaireTaskIdRT = String.withBrand("QuestionnaireTaskId");
export type QuestionnaireTaskId = Static<typeof QuestionnaireTaskIdRT>;

export const QuestionnaireTaskFileRT = FileBaseRT.extend({
  type: Literal(FileType.questionnaireTaskFile),
  attributes: FileAttributesRT.extend({
    questionnaireTaskId: QuestionnaireTaskIdRT,
    status: Union(Literal("original"), Literal("final"), Literal("completed")),
  }),
});

export type QuestionnaireTaskFile = Static<typeof QuestionnaireTaskFileRT>;

export enum QuestionnaireTaskPriority {
  expedited = 1,
  high = 2,
  medium = 3,
  low = 4,
}
const QuestionnaireTaskPriorityRT = Union(
  Literal(QuestionnaireTaskPriority.expedited),
  Literal(QuestionnaireTaskPriority.high),
  Literal(QuestionnaireTaskPriority.medium),
  Literal(QuestionnaireTaskPriority.low),
);

export const QuestionnaireTaskPriorityMap = {
  [QuestionnaireTaskPriority.expedited]: "Expedited",
  [QuestionnaireTaskPriority.high]: "High",
  [QuestionnaireTaskPriority.medium]: "Medium",
  [QuestionnaireTaskPriority.low]: "Low",
};

export type QuestionnaireTaskPriorityType = Static<
  typeof QuestionnaireTaskPriorityRT
>;

const AICompletionRequestIdRT = String.withBrand("AICompletionRequestId");
export type AICompletionRequestId = Static<typeof AICompletionRequestIdRT>;

const AICompletionRequestRT = Record({
  id: AICompletionRequestIdRT,
  requester: SimpleUserRT.optional(),
  createdAt: ISODateStrRT,
});

export const QuestionnaireTaskRT = Record({
  type: Literal("questionnaireTask"),
  id: QuestionnaireTaskIdRT,
  attributes: Record({
    requestedPriority: QuestionnaireTaskPriorityRT.nullable(),
    expediteRequestGranted: ExpediteRequestStatusRT,
    completionPercentage: Number.nullable().optional(),
    totalQuestionsCount: Number.nullable().optional(),
    unansweredQuestionsCount: Number.nullable().optional(),
    portalInfo: Record({
      link: String.nullable(),
      credentials: String.nullable(),
    }),
    status: QuestionnaireTaskStatusRT,
    sentAt: ISODateStrRT,
    acceptedAt: ISODateStrRT.nullable().optional(),
    canceledAt: ISODateStrRT.nullable().optional(),
    completedAt: ISODateStrRT.nullable().optional(),
    returnedAt: ISODateStrRT.nullable().optional(),
    dueDate: ISODateStrRT.nullable().optional(),
    prospectDueDate: ISODateStrRT.nullable().optional(),
    canceledReason: String.nullable().optional(),
    prospect: String,
    senderEmail: String,
    senderName: String,
    initialNotesRaw: String.nullable(),
    externalAcceptanceNotesRaw: String.nullable().optional(),
    completionNotesRaw: String.nullable().optional(),
    finalNotesRaw: String.nullable().optional(),
    airtableLink: String.nullable().optional(),
    gdriveLink: String.nullable().optional(),
    questionnaireType: Array(QuestionnaireTypeOptionRT).nullable(),
    isArchived: Boolean,
    annualRecurringRevenue: Number.nullable().optional(),
    prismCompletedAt: ISODateStrRT.nullable().optional(),
  }),
  relationships: Record({
    files: Array(QuestionnaireTaskFileRT).optional(),
    completedBy: PartialUserRT.nullable().optional(),
    canceledBy: PartialUserRT.nullable().optional(),
    acceptedBy: PartialUserRT.nullable().optional(),
    returnedBy: PartialUserRT.nullable().optional(),
    products: Array(ProductRT),
    tags: Array(KnowledgeLibraryTagRT),
    assignedCustomerUser: PartialUserRT.nullable().optional(),
    prismEntries: Array(PRISMEntryRT).optional(),
    aiCompletionRequest: AICompletionRequestRT.nullable().optional(),
  }),
});

export type QuestionnaireTask = Static<typeof QuestionnaireTaskRT>;

const QuestionnaireCollaboratorEmailIdRT = String.withBrand(
  "QuestionnaireCollaboratorEmailId",
);

export const QuestionnaireCollaboratorEmailRT = Record({
  id: QuestionnaireCollaboratorEmailIdRT,
  type: Literal("questionnaireCollaboratorEmail"),
  attributes: Record({
    email: String,
  }),
});

export type QuestionnaireCollaboratorEmail = Static<
  typeof QuestionnaireCollaboratorEmailRT
>;

export const QuestionnaireTaskMetaParamsRT = Record({
  questionnaireTaskId: QuestionnaireTaskIdRT,
  isArchived: Boolean,
});

export type QuestionnaireTaskMetaParams = Static<
  typeof QuestionnaireTaskMetaParamsRT
>;
