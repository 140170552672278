import { InfoCircleOutlined } from "@ant-design/icons";
import { Cascader, CascaderProps, Tooltip } from "antd";
import clsx from "clsx";
import { uniqBy } from "lodash";
import { categories } from "../../utils/constants";
import styles from "./CategorySelect.module.css";

interface CategoryOption {
  value: string;
  label: string;
  children?: CategoryOption[];
}

type SingleCategorySelectProps = Omit<
  CascaderProps<CategoryOption, "value", false>,
  "value" | "onChange"
> & {
  value?: string;
  onChange?: (value: string | undefined) => void;
};

type MultipleCategorySelectProps = Omit<
  CascaderProps<CategoryOption, "value", true>,
  "value" | "onChange"
> & {
  extraCategories?: string[];
  value?: string[];
  onChange?: (value: string[] | undefined) => void;
};

const allCategoryOptions = (extraCategories: string[]) => {
  const baseOptions: CategoryOption[] = Object.keys(categories).map(
    (parentCategory) => {
      const subValues = Object.keys(categories[parentCategory]).map(
        (category) => ({
          value: category,
          label: category,
        }),
      );
      return {
        value: parentCategory,
        label: parentCategory,
        children: subValues,
      };
    },
  );

  const flatCategories: CategoryOption[] =
    extraCategories
      .filter((c) => c.trim())
      .map((category) => {
        //sanitizing to resolve https://github.com/SecurityPal/security-pal/issues/3434
        const sanitizedCategory = category.trim().replaceAll("\n", ": ");
        return {
          value: sanitizedCategory,
          label: sanitizedCategory,
        };
      }) ?? [];

  return uniqBy([...baseOptions, ...flatCategories], "value");
};

const displayRender = (labels: string[]) => (
  <div className={styles.CategorySelect_option}>
    <div>{labels.join(" - ")}</div>
    {labels.length === 2 &&
      categories[labels[0]]?.[labels[1]] !== undefined && (
        <Tooltip title={categories[labels[0]][labels[1]]}>
          <InfoCircleOutlined />
        </Tooltip>
      )}
  </div>
);

const showSearch = {
  filter: (inputValue: string, path: CategoryOption[]) => {
    // Handle nested categories
    if (path.length === 2) {
      const parentCategory = path[0].value?.toString() ?? "";
      const category = path[1].value?.toString() ?? "";
      const combinedPath = `${parentCategory} - ${category}`;

      return (
        combinedPath.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0 ||
        categories[parentCategory][category]
          .toLowerCase()
          .indexOf(inputValue.toLowerCase()) >= 0
      );
    }

    // Handle flat categories
    if (path.length === 1 && path[0].value !== "") {
      const option = path[0]?.value?.toString().toLowerCase() ?? "";
      return option.indexOf(inputValue.toLowerCase()) >= 0;
    }
    return false;
  },
  render: (_: string, path: CategoryOption[]) => {
    return path.map((v) => v.label).join(" - ");
  },
};

const SingleCategorySelect = ({
  value,
  onChange,
  ...rest
}: SingleCategorySelectProps): JSX.Element => (
  <Cascader
    {...rest}
    className={clsx(rest.className, styles.CategorySelect)}
    options={allCategoryOptions([])}
    displayRender={displayRender}
    value={value?.split(" - ")}
    onChange={(v) => onChange?.(v.join(" - "))}
    allowClear={false}
    showSearch={showSearch}
  />
);

const MultipleCategorySelect = ({
  extraCategories,
  onChange,
  value,
  ...rest
}: MultipleCategorySelectProps) => (
  <Cascader
    {...rest}
    className={clsx(rest.className, styles.CategorySelect)}
    options={allCategoryOptions(extraCategories ?? [])}
    displayRender={displayRender}
    allowClear={true}
    showSearch={showSearch}
    multiple
    onChange={(v) => onChange?.(v.map((v) => v.join(" - ")))}
    value={value?.map((v) => v.split(" - "))}
  />
);

export { MultipleCategorySelect, SingleCategorySelect };
