import { SerializedError } from "@reduxjs/toolkit";
import {
  BaseQueryFn,
  EndpointBuilder,
  FetchArgs,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query";
import {
  Array,
  Boolean,
  Literal,
  Null,
  Number,
  Optional,
  Record as RTRecord,
  Static,
  String,
  Undefined,
  Union,
} from "runtypes";
import { runtypeFromEnum } from "../../utils/runtypes";
import { RtkQueryTag } from "../../utils/webSockets/generatedTypes";
import { DashboardQuestionnaireResult } from "../Concierge/autoGeneratedTypes";
import { Permission, PermissionRT } from "./permissions";

// Roles

export const CompanyIdRT = String.withBrand("CompanyId");
export type CompanyId = Static<typeof CompanyIdRT>;
export const ProductIdRT = Number.withBrand("ProductId");
export type ProductId = Static<typeof ProductIdRT>;
export const UserIdRT = Number.withBrand("UserId");
export type UserId = Static<typeof UserIdRT>;
export const ISODateStrRT = String.withBrand("ISODateStr");
export type ISODateStr = Static<typeof ISODateStrRT>;

export enum CustomerRBACRole {
  APP_ADMIN = "app-admin",
  APP_EDITOR = "app-editor",
  APP_VIEWER = "app-viewer",
  CONCIERGE_DASHBOARD_USER = "concierge-dashboard-user",
}
export const CustomerRBACRoleRT = runtypeFromEnum(CustomerRBACRole);

export enum InternalRole {
  SP_EMPLOYEE = "sp-employee",
  SP_LIBRARY_MAINTAINER = "sp-library-maintainer",
  SP_ONBOARDER = "sp-onboarder",
  SP_DELIVERY_POD_LEADER = "sp-delivery-pod-leader",
  SP_MISSION_CONTROL_SENDER = "sp-mission-control-sender",
  SP_CUSTOMER_SUCCESS = "sp-customer-success",
  SP_ENG = "sp-eng",
  SP_CUSTOMER_ASSURANCE_PUBLISHER = "sp-customer-assurance-publisher",
  SP_CUSTOMER_ASSURANCE_EDITOR = "sp-customer-assurance-editor",
  SP_FINAL_REVIEWERS = "sp-final-reviewers",
  SP_INITIAL_REVIEWERS = "sp-initial-reveiwers",
  SP_CIRRT = "sp-cirrt",
  SP_LEADS_AND_MANAGERS = "sp-leads-and-managers",
  SP_KL_FINAL_REVIEWERS = "sp-kl-final-reviewers",
  SP_KL_INITIAL_REVIEWERS = "sp-kl-initial-reviewers",
  SP_KL_LEAD = "sp-kl-lead",
  SP_DATA_TEAM = "sp-data-team",
  SP_VENDOR_ASSESS_VIEWER = "sp-vendor-assess-viewer",
  SP_VENDOR_ASSESS_EDITOR = "sp-vendor-assess-editor",
}
export const InternalRoleRT = runtypeFromEnum(InternalRole);

export const RBACRoleRT = Union(InternalRoleRT, CustomerRBACRoleRT);
export type RBACRole = Static<typeof RBACRoleRT>;

export const ALL_RBAC_ROLES = { ...CustomerRBACRole, ...InternalRole };

// Company and Product

export enum CopilotProvider {
  OPENAI = "openai",
  GEMINI = "gemini",
}
export const CopilotProviderRT = runtypeFromEnum(CopilotProvider);

export const SimpleCompanyRT = RTRecord({
  id: CompanyIdRT,
  type: Literal("simpleCompany"),
  attributes: RTRecord({
    displayName: String,
    spCustomerId: String,
  }),
});

export const CompanyRT = RTRecord({
  type: Literal("company"),
  id: CompanyIdRT,
  attributes: RTRecord({
    displayName: String,
    slug: String,
    spCustomerId: String.Or(Null),
    autoActivateNewUsers: Boolean,
    isMfaEnforced: Boolean,
    isSsoEnabled: Boolean,
    isDsyncEnabled: Boolean,
    isActive: Boolean,
    isCopilotEnabled: Boolean,
    copilotProvider: Optional(CopilotProviderRT),
    slaHours: Number.Or(Null),
    expeditedSlaHours: Number.Or(Null),
    weekendSupport: Boolean,
    domains: Array(String),
  }),
});
export type Company = Static<typeof CompanyRT>;

export const FullCompanyRT = RTRecord({
  type: Literal("fullCompany"),
  id: CompanyIdRT,
  attributes: RTRecord({
    displayName: String,
    slug: String,
    isMfaEnforced: Boolean,
    isSsoEnabled: Boolean,
    isDsyncEnabled: Boolean,
    isActive: Boolean,
    serviceEndDateInclusive: ISODateStrRT.Or(Null),
    ssoOrgId: String.Or(Null),
    aliases: Array(String).Or(Null),
  }),
});
export type FullCompany = Static<typeof FullCompanyRT>;

export const ProductRT = RTRecord({
  type: Literal("product"),
  id: ProductIdRT,
  attributes: RTRecord({
    productName: String,
    reviewCycle: Number,
  }),
  relationships: RTRecord({
    company: RTRecord({
      data: CompanyRT,
    }),
  }),
});
export type Product = Static<typeof ProductRT>;

export const LibraryRolesRT = RTRecord({
  product: ProductRT,
  role: RBACRoleRT,
});
export type LibraryRoles = Static<typeof LibraryRolesRT>;

// Access Groups

export const GetAccessGroupsMetaRT = RTRecord({
  totalCount: Number,
});
export type GetAccessGroupsMeta = Static<typeof GetAccessGroupsMetaRT>;

export const AccessGroupAttributesRT = RTRecord({
  groupName: String,
  role: RBACRoleRT,
  isDefaultAccessGroup: Boolean,
  dsyncId: Optional(String),
});
export type AccessGroupAttributes = Static<typeof AccessGroupAttributesRT>;

export const AccessGroupRelationshipsRT = RTRecord({
  company: CompanyRT,
  products: Optional(Array(ProductRT)),
});
export type AccessGroupRelationships = Static<
  typeof AccessGroupRelationshipsRT
>;

export const AccessGroupIdRT = String.withBrand("accessGroupId");
export type AccessGroupId = Static<typeof AccessGroupIdRT>;

export const AccessGroupRT = RTRecord({
  id: AccessGroupIdRT,
  type: Literal("accessGroup"),
  attributes: AccessGroupAttributesRT,
  relationships: AccessGroupRelationshipsRT,
});
export type AccessGroup = Static<typeof AccessGroupRT>;

export const GetAccessGroupUsersMetaRT = RTRecord({
  totalCount: Number,
  offset: Number,
  limit: Number,
});
export type GetAccessGroupUsersMeta = Static<typeof GetAccessGroupUsersMetaRT>;

// User

export const SimpleUserRT = RTRecord({
  id: UserIdRT,
  type: Literal("simpleUser"),
  attributes: RTRecord({
    name: String,
    email: String,
  }),
});

export type SimpleUser = Static<typeof SimpleUserRT>;

export const PartialUserRT = RTRecord({
  type: Literal("user"),
  id: UserIdRT,
  attributes: RTRecord({
    email: String,
    name: String,
    roles: Array(RBACRoleRT),
    permissions: Array(PermissionRT).nullable(),
    isStaff: Boolean,
    isActive: Boolean,
    isPending: Boolean,
    isVerified: Boolean,
  }),
  relationships: Optional(Undefined),
});
export type PartialUser = Static<typeof PartialUserRT>;

export const UserRT = PartialUserRT.omit("relationships").extend({
  relationships: Optional(
    RTRecord({
      company: CompanyRT,
      productRoles: Optional(Array(LibraryRolesRT)),
      accessGroups: Optional(Array(AccessGroupRT)),
    }),
  ),
});

export const DomainCaptureRT = RTRecord({
  type: Literal("domainCapture"),
  attributes: RTRecord({
    domain: String,
  }),
});

export type User = Static<typeof UserRT>;

export type SPQueryExtraOptions = {
  unauthed?: boolean;
  checkRunTypes?: boolean;
};

export type SPQueryError = FetchBaseQueryError | SerializedError | undefined;

export type SPBaseQueryFn = BaseQueryFn<
  string | FetchArgs,
  unknown,
  SPQueryError,
  SPQueryExtraOptions,
  Record<string, unknown>
>;
export type SPEndpointBuilder = EndpointBuilder<SPBaseQueryFn, string, string>;
export const hasPermission = (u: User | undefined, p: Permission): boolean =>
  !!u && !!u.attributes.permissions?.includes(p);

export type DashboardQuestionnaireStatus =
  DashboardQuestionnaireResult["status"];

// duplicated runtype since we don't use generated types everywhere
export const QuestionnaireTaskStatusRT = Union(
  Literal("submitted"),
  Literal("undergoingCompletion"),
  Literal("qaCheck"),
  Literal("completedAwaitingReturn"),
  Literal("revisedVersionReturned"),
  Literal("completedWithFeedback"),
  Literal("canceled"),
  Literal("unknown"),
);

// NOTE: The order matters. This is the order we use to display in the status legend.
// use a record as typescript hack to get exhaustive list of dashboardQuestionnaireStatus
export const ORDERED_DASHBOARD_QUESTIONNAIRE_STATUSES: Record<
  DashboardQuestionnaireStatus,
  number
> = {
  aiSubmitted: 1,
  aiProcessing: 2,
  aiCompleted: 3,
  submitted: 4,
  undergoingCompletion: 5,
  qaCheck: 6,
  completedAwaitingReturn: 7,
  revisedVersionReturned: 8,
  completedWithFeedback: 9,
  canceled: 10,
  aiFailed: 11,
  unknown: 12,
};

export const DASHBOARD_QUESTIONNAIRE_STATUSES: DashboardQuestionnaireStatus[] =
  Object.keys(ORDERED_DASHBOARD_QUESTIONNAIRE_STATUSES).sort(
    (a, b) =>
      ORDERED_DASHBOARD_QUESTIONNAIRE_STATUSES[
        a as DashboardQuestionnaireStatus
      ] -
      ORDERED_DASHBOARD_QUESTIONNAIRE_STATUSES[
        b as DashboardQuestionnaireStatus
      ],
  ) as DashboardQuestionnaireStatus[];

export const TotalCountMetaRT = RTRecord({ totalCount: Number });
export type TotalCountMeta = Static<typeof TotalCountMetaRT>;

// hack to get an exhaustive list of RtkQueryTags
const RtkQueryTagMap: Record<RtkQueryTag, 0> = {
  AccessGroup: 0,
  AccessGroupUser: 0,
  AgreementTemplate: 0,
  AnswerRequest: 0,
  APIToken: 0,
  BulkAnswer: 0,
  Categories: 0,
  CanonicalQuestion: 0,
  Certification: 0,
  CopilotQuery: 0,
  CopilotSlackSettings: 0,
  CustomerAssuranceDocument: 0,
  Comments: 0,
  CompanySettings: 0,
  DashboardQuestionnaire: 0,
  Document: 0,
  EmailTemplate: 0,
  FullCompany: 0,
  InboxMetadata: 0,
  InformationRequest: 0,
  KnowledgeLibraryTag: 0,
  Label: 0,
  MeData: 0,
  Impersonating: 0,
  Notification: 0,
  NotificationCount: 0,
  Product: 0,
  ProfileVersion: 0,
  Prospect: 0,
  Questionnaire: 0,
  QuestionnaireResponses: 0,
  RequestThread: 0,
  Response: 0,
  SuggestedEdit: 0,
  TeamMember: 0,
  Threads: 0,
  NdaSigned: 0,
  Assessments: 0,
  AltitudeReports: 0,
  PendingQuestions: 0,
  DocusignEnvelope: 0,
  VendorAssessment: 0,
  DataRoomLinks: 0,
  Profile: 0,
  CustomerAssuranceDocumentGroups: 0,
};

export interface SignedURLResponse {
  uploadUrl: string;
  gcsFileName: string;
  fileName: string;
}

export interface SignedUploadsParams {
  fileNames: string[];
  bucketType?: string;
  companyId: string;
}

export interface SignedUploadParams {
  bucketType?: string;
  companyId: string;
  fileName: string;
}

export interface FileUploadConfig {
  fileName: string;
  gcsFileName: string;
  contentType?: string;
}

export const AllRtkQueryTags = Object.keys(RtkQueryTagMap) as RtkQueryTag[];
