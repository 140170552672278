import { captureException } from "@sentry/react";
import { Button, Form, Input, message } from "antd";
import RequiredEmailFormItem from "../../../components/RequiredEmailFormItem";
import RequiredFormItem from "../../../components/RequiredFormItem";
import { useSubmitReferralMutation } from "../../../features/API/referral";

interface ReferralForm {
  firstName: string;
  lastName: string;
  emailId: string;
  companyName: string;
  notes: string;
}

const ReferralForm = (): JSX.Element => {
  const [form] = Form.useForm<ReferralForm>();
  const { TextArea } = Input;
  const [submitReferral, { isLoading: isLoadingReferral }] =
    useSubmitReferralMutation();

  const formItemLayout = { labelCol: { span: 4 }, wrapperCol: { span: 14 } };

  const buttonItemLayout = { wrapperCol: { offset: 15 } };
  const handleSubmit = async (values: ReferralForm) => {
    try {
      await submitReferral({
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.emailId,
        companyName: values.companyName,
        notes: values.notes,
      });
      message.success("Referral submitted successfully");
      form.resetFields();
    } catch (e) {
      captureException(e);
      message.error("Error submitting referral");
    }
  };

  return (
    <Form
      {...formItemLayout}
      layout="horizontal"
      form={form}
      onFinish={handleSubmit}
    >
      <RequiredFormItem label="First Name" name="firstName">
        <Input type="text" />
      </RequiredFormItem>
      <RequiredFormItem label="Last Name" name="lastName">
        <Input type="text" />
      </RequiredFormItem>
      <RequiredEmailFormItem label="Email" name="emailId">
        <Input type="text" />
      </RequiredEmailFormItem>
      <RequiredFormItem label="Company Name" name="companyName">
        <Input type="text" />
      </RequiredFormItem>
      <Form.Item label="Notes" name="notes">
        <TextArea rows={4} />
      </Form.Item>
      <RequiredFormItem {...buttonItemLayout}>
        <Button type="primary" htmlType="submit" loading={isLoadingReferral}>
          Refer SecurityPal
        </Button>
      </RequiredFormItem>
    </Form>
  );
};

export default ReferralForm;
