import { createSlice } from "@reduxjs/toolkit";
import authApi from "../API/auth";
import socialAuthApi from "../API/socialAuth";
import { UserAuthData } from "../UserInfo/types";

const initialState = {
  registrationData: undefined,
} as {
  registrationData: UserAuthData | undefined;
};

export const REGISTRATION_KEY = "registration";

const registrationSlice = createSlice({
  name: REGISTRATION_KEY,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    const registrationEndpoints = [
      authApi.endpoints.register,
      authApi.endpoints.ssoCallback,
      socialAuthApi.endpoints.loginWithGoogle,
      socialAuthApi.endpoints.loginWithMicrosoft,
    ];
    for (const endpoint of registrationEndpoints) {
      builder.addMatcher(endpoint.matchFulfilled, (state, action) => {
        if (action.payload.data.authResultType === "registration") {
          state.registrationData = action.payload.data;
        }
      });
    }
  },
});

export default registrationSlice.reducer;
