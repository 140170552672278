import { createSelector, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import TokenHandler from "../../utils/token";
import authApi from "../API/auth";
import socialAuthApi from "../API/socialAuth";
import { UserAuthData } from "./types";

interface UserAuthState {
  userAuthData: UserAuthData | undefined;
}

const initialState: UserAuthState = {
  userAuthData: undefined,
};

export const USER_AUTH_KEY = "userAuth";

const userAuthSlice = createSlice({
  name: USER_AUTH_KEY,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    const registrationEndpoints = [
      authApi.endpoints.login,
      authApi.endpoints.register,
      authApi.endpoints.validateMFA,
      authApi.endpoints.ssoCallback,
      socialAuthApi.endpoints.loginWithGoogle,
      socialAuthApi.endpoints.loginWithMicrosoft,
    ];
    for (const endpoint of registrationEndpoints) {
      builder.addMatcher(endpoint.matchFulfilled, (state, action) => {
        if (action.payload.data.authResultType !== "registration") {
          state.userAuthData = action.payload.data;
          if (
            state.userAuthData &&
            state.userAuthData.authResultType === "full"
          ) {
            TokenHandler.updateTokens(state.userAuthData.tokens);
          }
        }
      });
    }
  },
});

const selectUserAuthData = (state: RootState) => state.userAuth.userAuthData;

export const selectFullUserAuthData = createSelector(
  selectUserAuthData,
  (userAuthData) => {
    if (
      userAuthData !== undefined &&
      userAuthData.authResultType !== "needs_mfa" &&
      userAuthData.authResultType !== "registration"
    ) {
      return userAuthData;
    }
    return undefined;
  },
);

export const selectPartialUserAuthData = createSelector(
  selectUserAuthData,
  (userData) => {
    if (userData?.authResultType === "needs_mfa") {
      return userData;
    }
    return undefined;
  },
);

export default userAuthSlice.reducer;
