import {
  DownOutlined,
  EditOutlined,
  PlusOutlined,
  UpOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  ConfigProvider,
  Grid,
  ModalProps,
  Popover,
  Row,
  Typography,
} from "antd";
import Color from "color";
import { useEffect, useState } from "react";
import AssuranceProfileCard from "../AssuranceProfileCard";
import {
  DeleteSubprocessorPopconfirmProps,
  EditSubprocessorModalProps,
} from "../AssuranceProfileWrapper";
import CardHeader from "../CardHeader";
import MoveRankButtons from "../MoveRankButtons/MoveRankButtons";
import {
  ProfileVersion,
  Subprocessor,
  SubprocessorId,
} from "../types/assuranceProfile";
import styles from "./Subprocessors.module.scss";
interface EditorComponents {
  EditModal: React.FC<EditSubprocessorModalProps>;
  CreateModal: React.FC<ModalProps>;
  DeletePopconfirm: React.FC<DeleteSubprocessorPopconfirmProps>;
}
interface SubprocessorsProps {
  profileVersion: ProfileVersion;
  editorComponents?: EditorComponents;
  onMoveSubprocessor?: (
    subprocessorId: SubprocessorId,
    direction: string,
  ) => void;
}

const BREAKPOINTS = {
  LARGE: "lg",
  MEDIUM: "md",
  SMALL: "sm",
};

const VISIBLE_SUBPROCESSORS = {
  [BREAKPOINTS.LARGE]: 8,
  [BREAKPOINTS.MEDIUM]: 4,
  [BREAKPOINTS.SMALL]: 2,
};

const Subprocessors = ({
  profileVersion,
  editorComponents,
  onMoveSubprocessor,
}: SubprocessorsProps): JSX.Element => {
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const CreateModal = editorComponents?.CreateModal;
  const subprocessors = profileVersion.relationships.subprocessors;
  const firstSubprocessor = subprocessors[0];
  const lastSubprocessor = subprocessors.slice(-1)[0];
  const [showAll, setShowAll] = useState(false);
  const [visibleItems, setVisibleItems] = useState(
    VISIBLE_SUBPROCESSORS[BREAKPOINTS.LARGE],
  );
  const accentColor =
    profileVersion.relationships.styleSettings.attributes.primaryColor;
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  useEffect(() => {
    if (screens.lg) {
      setVisibleItems(VISIBLE_SUBPROCESSORS[BREAKPOINTS.LARGE]);
    } else if (screens.md) {
      setVisibleItems(VISIBLE_SUBPROCESSORS[BREAKPOINTS.MEDIUM]);
    } else {
      setVisibleItems(VISIBLE_SUBPROCESSORS[BREAKPOINTS.SMALL]);
    }
  }, [screens]);

  return (
    <>
      <ConfigProvider
        theme={{
          components: {
            Button: {
              colorPrimary: accentColor,
              colorPrimaryHover: Color(accentColor).alpha(0.75).string(),
            },
          },
        }}
      >
        <AssuranceProfileCard>
          <CardHeader
            titleLevel={2}
            title="Subprocessors"
            editButton={
              editorComponents?.CreateModal && (
                <Button
                  onClick={() => setCreateModalOpen(true)}
                  icon={<PlusOutlined />}
                />
              )
            }
          />
          <Row gutter={[16, 16]}>
            {subprocessors.map((subprocessor, index) => {
              const isVisible = showAll || index < visibleItems;
              return isVisible ? (
                <Col
                  className={styles.TileColumn}
                  xs={24}
                  sm={12}
                  lg={6}
                  key={subprocessor.id}
                >
                  <SubprocessorTile
                    key={subprocessor.id}
                    subprocessor={subprocessor}
                    EditModal={editorComponents?.EditModal}
                    DeletePopconfirm={editorComponents?.DeletePopconfirm}
                    onMoveSubprocessorDown={
                      onMoveSubprocessor &&
                      (() => onMoveSubprocessor(subprocessor.id, "down"))
                    }
                    onMoveSubprocessorUp={
                      onMoveSubprocessor &&
                      (() => onMoveSubprocessor(subprocessor.id, "up"))
                    }
                    disableUp={subprocessor.id === firstSubprocessor?.id}
                    disableDown={subprocessor.id === lastSubprocessor?.id}
                  />
                </Col>
              ) : null;
            })}
          </Row>
          {subprocessors.length > visibleItems && (
            <Button
              type="primary"
              className={styles.ShowAllButton}
              onClick={() => setShowAll(!showAll)}
            >
              {showAll ? (
                <UpOutlined className={styles.ShowAllButtonsArrow} />
              ) : (
                <DownOutlined className={styles.ShowAllButtonsArrow} />
              )}
            </Button>
          )}
        </AssuranceProfileCard>
      </ConfigProvider>
      {CreateModal && (
        <CreateModal
          onCancel={() => setCreateModalOpen(false)}
          onOk={() => setCreateModalOpen(false)}
          open={createModalOpen}
        />
      )}
    </>
  );
};

interface SubprocessorTileProps {
  subprocessor: Subprocessor;
  DeletePopconfirm?: React.FC<DeleteSubprocessorPopconfirmProps>;
  EditModal?: React.FC<EditSubprocessorModalProps>;
  onMoveSubprocessorUp?: () => void;
  onMoveSubprocessorDown?: () => void;
  disableUp?: boolean;
  disableDown?: boolean;
}
const SubprocessorTile = ({
  subprocessor,
  DeletePopconfirm,
  EditModal,
  onMoveSubprocessorDown,
  onMoveSubprocessorUp,
  disableUp,
  disableDown,
}: SubprocessorTileProps): JSX.Element => {
  const [editModalOpen, setEditModalOpen] = useState(false);

  return (
    <>
      <Popover
        content={
          DeletePopconfirm &&
          EditModal && (
            <div className={styles.TileActionButtons}>
              <Button
                onClick={() => setEditModalOpen(true)}
                type="text"
                icon={<EditOutlined />}
              />
              <DeletePopconfirm subprocessorId={subprocessor.id} />
            </div>
          )
        }
      >
        <div className={styles.SubprocessorTile}>
          {subprocessor.attributes.header ? (
            <Typography.Title level={3} className="TileText">
              {subprocessor.attributes.header}
            </Typography.Title>
          ) : (
            <div className={styles.HeaderPlaceholder} />
          )}
          {subprocessor.relationships.file && (
            //TODO (#6445): handle publicUrls locally
            <div className={styles.ImageWrapper}>
              <img
                alt="subprocessor"
                src={subprocessor.relationships.file.attributes.publicUrl}
              />
            </div>
          )}
          {subprocessor.attributes.description && (
            <Typography.Paragraph className="TileText">
              {subprocessor.attributes.description}
            </Typography.Paragraph>
          )}
          {onMoveSubprocessorDown && onMoveSubprocessorUp && (
            <div className={styles.MoveRankButtonsWrapper}>
              <MoveRankButtons
                onMoveUp={() => onMoveSubprocessorUp?.()}
                onMoveDown={() => onMoveSubprocessorDown?.()}
                disableUp={disableUp}
                disableDown={disableDown}
                vertical={false}
              />
            </div>
          )}
        </div>
      </Popover>

      {EditModal && (
        <EditModal
          subprocessor={subprocessor}
          open={editModalOpen}
          onCancel={() => setEditModalOpen(false)}
          onOk={() => setEditModalOpen(false)}
        />
      )}
    </>
  );
};

export default Subprocessors;
