import dayjs from "dayjs";
import { ensureExhaustive } from "shared_frontend/src/utils";
import {
  DashboardQuestionnaireStatus,
  ISODateStr,
} from "../../features/API/types";

export const renderStatus = (status: DashboardQuestionnaireStatus): string => {
  switch (status) {
    case "aiSubmitted":
      return "Awaiting Copilot completion";
    case "aiProcessing":
      return "Copilot processing";
    case "aiCompleted":
      return "Copilot completed";
    case "submitted":
      return "Request Received";
    case "undergoingCompletion":
      return "In Progress";
    case "qaCheck":
      return "Quality Check";
    case "completedAwaitingReturn":
      return "Returned";
    case "revisedVersionReturned":
      return "Processing Feedback";
    case "completedWithFeedback":
      return "Completed";
    case "canceled":
      return "Canceled";
    case "unknown":
      return "Unknown";
    case "aiFailed":
      return "Copilot completion failed";
    default:
      return ensureExhaustive(status);
  }
};

export const formatCreateTaskErrorMessage = (errorDetail: string) =>
  `Failed to create a new questionnaire request: ${errorDetail}. Please correct or reach out to <a href='mailto:concierge@securitypalhq.com'>concierge@securitypalhq.com</a> and submit the details there.`;

export const getStatusDescription = (
  status: DashboardQuestionnaireStatus,
): string => {
  switch (status) {
    case "aiSubmitted":
      return "The questionnaire is enqueued for SecurityPal Copilot to process.";
    case "aiProcessing":
      return "SecurityPal Copilot is currently processing the questionnaire.";
    case "aiCompleted":
      return "SecurityPal Copilot has completed the questionnaire.";
    case "aiFailed":
      return "SecurityPal Copilot failed to process the questionnaire.";
    case "submitted":
      return "SecurityPal has received the questionnaire.";
    case "undergoingCompletion":
      return "SecurityPal is filling out the questionnaire.";
    case "qaCheck":
      return "A SecurityPal Analyst is checking the questionnaire for quality.";
    case "completedAwaitingReturn":
      return "SecurityPal has worked on your request and returned the questionnaire. We are waiting to receive the fully completed version.";
    case "revisedVersionReturned":
      return "The questionnaire submitter has returned the final version of the questionnaire to SecurityPal. SecurityPal is processing the final changes.";
    case "completedWithFeedback":
      return "SecurityPal has fulfilled the questionnaire request end-to-end.";
    case "canceled":
      return "The questionnaire request was canceled.";
    case "unknown":
      return "The status requires special human review and should be updated shortly.";
    default:
      return ensureExhaustive(status);
  }
};

export const completionFilterIsNotDefault = (
  minPercentage?: number | null,
  maxPercentage?: number | null,
) =>
  (minPercentage || maxPercentage) &&
  (minPercentage !== 0 || maxPercentage !== 100);

export const NA_STATUSES: DashboardQuestionnaireStatus[] = [
  "canceled",
  "unknown",
  "aiSubmitted",
  "aiProcessing",
  "aiCompleted",
  "aiFailed",
];

export const formatDate = (
  date: ISODateStr,
  status: DashboardQuestionnaireStatus,
): string => {
  switch (status) {
    case "submitted":
    case "undergoingCompletion":
    case "qaCheck":
      return dayjs(date).format("MM/DD LT");
    case "completedAwaitingReturn":
    case "revisedVersionReturned":
    case "completedWithFeedback":
    case "canceled":
    case "unknown":
    case "aiCompleted":
    case "aiFailed":
    case "aiProcessing":
    case "aiSubmitted":
      return dayjs(date).format("MM/DD/YYYY LT");
    default:
      return ensureExhaustive(status);
  }
};

export const isAiStatus = (status: DashboardQuestionnaireStatus): boolean => {
  switch (status) {
    case "aiSubmitted":
    case "aiProcessing":
    case "aiCompleted":
    case "aiFailed":
      return true;
    case "submitted":
    case "undergoingCompletion":
    case "qaCheck":
    case "completedAwaitingReturn":
    case "revisedVersionReturned":
    case "completedWithFeedback":
    case "canceled":
    case "unknown":
      return false;
    default:
      return ensureExhaustive(status);
  }
};
