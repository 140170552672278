import { Typography } from "antd";
import styles from "./Field.module.scss";

interface VendorAssessmentDetailFormFieldProps {
  label: string;
  description: string;
  children: React.ReactNode;
  required?: boolean;
}

const VendorAssessmentDetailFormField = ({
  label,
  description,
  children,
  required,
}: VendorAssessmentDetailFormFieldProps): JSX.Element => {
  return (
    <div className={styles.Container}>
      <div className={styles.LabelAndDescriptionContainer}>
        <span
          className={`${styles.RequiredMark} ${!required ? styles.Hidden : ""}`}
        >
          *
        </span>
        <div>
          <Typography.Title level={3} className={styles.Label}>
            {label}
          </Typography.Title>
          <span className={styles.Description}>{description}</span>
        </div>
      </div>
      <div className={styles.ChildrenContainer}>{children}</div>
    </div>
  );
};

export default VendorAssessmentDetailFormField;
