import { Checkbox, Form, Typography } from "antd";
import { useEffect, useRef, useState } from "react";
import LinkButton from "../LinkButton";
import NdaDocumentViewer from "../NdaDocumentModal";
import { FormField } from "../RequestInfo/Form";
import { FileBasedNda } from "../types/assuranceProfile";
import { downloadFile } from "../utils/helper";
interface NdaCheckboxProps {
  nda: FileBasedNda;
  companyName: string;
  disabled: boolean;
  onNdaAccepted: () => void;
}

const NdaCheckbox = ({
  nda,
  companyName,
  disabled,
  onNdaAccepted,
}: NdaCheckboxProps): JSX.Element => {
  const [showPreview, setShowPreview] = useState(false);
  const [previewError, setPreviewError] = useState(false);
  const [ndaBlob, setNdaBlob] = useState<Blob>();
  const [ndaAccepted, setNdaAccepted] = useState(false);
  const isFetchingNdaRef = useRef(false);

  useEffect(() => {
    const fetchNdaBlob = async () => {
      isFetchingNdaRef.current = true;
      try {
        const blob = await downloadFile(
          `/api/download-nda-document?profile_version_id=${nda.attributes.profileVersionId}`,
        );
        setNdaBlob(blob);
      } finally {
        isFetchingNdaRef.current = false;
      }
    };

    // only fetch once
    if (isFetchingNdaRef.current || ndaBlob) return;

    // called when viewing the profile in published mode. skipped when in editor/preview mode.
    if (nda && !disabled) {
      fetchNdaBlob();
    }
  }, [nda, disabled, ndaBlob]);

  return (
    <>
      <Form.Item
        name={FormField.agreesToNDA}
        valuePropName="checked"
        extra={
          nda.attributes.ndaDisclaimer && (
            <Typography.Text>{nda.attributes.ndaDisclaimer}</Typography.Text>
          )
        }
      >
        <Checkbox
          data-testid="nda-agreement"
          disabled={disabled || !ndaAccepted}
        >
          I agree to {companyName}&apos;s{" "}
          <LinkButton
            onClick={async () => {
              setShowPreview(true);
              setPreviewError(false);
            }}
          >
            Non-Disclosure Agreement
          </LinkButton>
        </Checkbox>
      </Form.Item>
      {showPreview && ndaBlob && (
        <NdaDocumentViewer
          ndaBlob={ndaBlob}
          title={nda.relationships.file.attributes.fileName}
          onCancel={() => {
            setShowPreview(false);
            setPreviewError(false);
          }}
          previewError={previewError}
          onNdaSigned={() => {
            setShowPreview(false);
            setNdaAccepted(true);
            onNdaAccepted();
          }}
          isDownloadNDAEnabled={nda.attributes.isDownloadable}
        />
      )}
    </>
  );
};

export default NdaCheckbox;
