import { EditOutlined } from "@ant-design/icons";
import { Button, Divider, Select } from "antd";
import { SelectProps } from "antd/lib";
import { isEqual } from "lodash";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  KnowledgeLibraryTag,
  KnowledgeLibraryTagId,
} from "../../../features/KnowledgeLibrary/types";
import { usePrevious } from "../../../utils/hooks";
import styles from "./TagSelect.module.scss";
interface TagSelectProps extends SelectProps<KnowledgeLibraryTagId[]> {
  tags: KnowledgeLibraryTag[];
  defaultOpen?: boolean;
}

const TagSelect = ({
  tags,
  defaultOpen = false,
  ...rest
}: TagSelectProps): JSX.Element => {
  // we store internal state so we can remove any tags that are no longer in provided options
  const [selectedValue, setSelectedValue] = useState<KnowledgeLibraryTagId[]>(
    rest.value ?? rest.defaultValue ?? [],
  );
  const previousTags = usePrevious(tags);

  useEffect(() => {
    if (rest.value) {
      setSelectedValue(rest.value);
    }
  }, [rest.value]);

  useEffect(() => {
    if (!previousTags || isEqual(previousTags, tags)) return;
    // Update value to remove any values that are no longer in the tags
    setSelectedValue((prevValue) =>
      prevValue.filter((val) => tags.some((tag) => tag.id === val)),
    );
  }, [tags, previousTags]);

  return (
    <Select<KnowledgeLibraryTagId[]>
      className={styles.TagSelect}
      mode="multiple"
      defaultOpen={defaultOpen}
      placeholder={rest.placeholder ?? "Select tags"}
      optionFilterProp="label"
      options={tags.map((t) => ({
        label: t.attributes.name,
        value: t.id,
      }))}
      dropdownRender={(menu) => {
        return (
          <div>
            {menu}
            <Divider className={styles.Divider} />
            <Link target="_blank" to="/knowledge-library/settings">
              <Button
                className={styles.EditTagsButton}
                type="text"
                icon={<EditOutlined />}
              >
                Edit tags
              </Button>
            </Link>
          </div>
        );
      }}
      {...rest}
      value={selectedValue}
      onChange={(newValue, option) => {
        setSelectedValue(newValue);
        rest.onChange?.(newValue, option);
      }}
    />
  );
};

export default TagSelect;
