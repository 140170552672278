class BroadcastChannelManager {
  private static instance: BroadcastChannelManager | null = null;
  private channel: BroadcastChannel | null = null;

  static getInstance(): BroadcastChannelManager {
    if (!BroadcastChannelManager.instance) {
      BroadcastChannelManager.instance = new BroadcastChannelManager();
    }
    return BroadcastChannelManager.instance;
  }

  static getChannel(channelName: string): BroadcastChannel {
    const instance = BroadcastChannelManager.getInstance();
    if (!instance.channel) {
      instance.channel = new BroadcastChannel(channelName);
    }
    return instance.channel;
  }

  static closeChannel(): void {
    const instance = BroadcastChannelManager.getInstance();
    if (instance.channel) {
      instance.channel.close();
      instance.channel = null;
    }
  }
}

export default BroadcastChannelManager;
