import { Select } from "antd";
import { QuestionStatusValues } from "../../features/pendingQuestions/constants";
import style from "./QuestionStatusSelector.module.scss";
interface QuestionStatusSelectorProps {
  onChange: (value: QuestionStatusValues) => void;
  disabled?: boolean;
  value?: QuestionStatusValues;
}

const QuestionStatusSelector = ({
  onChange,
  value,
  disabled = false,
}: QuestionStatusSelectorProps): JSX.Element => {
  const questionStatusOptions = [
    {
      value: QuestionStatusValues.unanswered,
      label: "Unanswered",
    },
    {
      value: QuestionStatusValues.answered,
      label: "Answered",
    },
    { value: QuestionStatusValues.all, label: "Any Status" },
  ];
  return (
    <Select
      className={style.SelectQuestionStatus}
      placeholder="Question Status"
      optionFilterProp="label"
      options={questionStatusOptions}
      onChange={onChange}
      disabled={disabled}
      value={value}
    />
  );
};

export default QuestionStatusSelector;
