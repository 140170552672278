import { privateApi } from ".";
import { APIEndpoints } from "../../utils/constants";

import { Undefined } from "runtypes";
import {
  AssignUserParams,
  EditQuestionParams,
  ListFilterParams,
  PendingQuestionResponse,
  PendingResponseMeta,
  SaveAnswerParams,
  UploadSingleQuestionParams,
} from "../pendingQuestions/types";
import { mutationEndpointBuilder, queryEndpointBuilder } from "./utils";

const extendedApi = privateApi.injectEndpoints({
  endpoints: (builder) => ({
    getPendingQuestions: queryEndpointBuilder<
      PendingResponseMeta,
      PendingQuestionResponse[],
      ListFilterParams
    >({
      builder,
      metaRuntype: undefined,
      dataRuntype: undefined,
      url: (filters) => {
        if (filters) {
          const params = new URLSearchParams();
          Object.entries(filters).forEach(([key, value]) => {
            if (Array.isArray(value)) {
              value.forEach((v) => params.append(key, v.toString()));
            } else if (value !== undefined) {
              params.append(key, value.toString());
            }
          });
          return `${
            APIEndpoints.pendingQuestions.GET_PENDING_QUESTIONS
          }?${params.toString()}`;
        }
        return `${APIEndpoints.pendingQuestions.GET_PENDING_QUESTIONS}`;
      },
      extraOptions: {
        checkRunTypes: false,
      },
      providesTags: ["PendingQuestions"],
    }),
    saveAnswerToPendingQuestion: mutationEndpointBuilder<
      void,
      void,
      SaveAnswerParams
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: Undefined,
      url: () => APIEndpoints.pendingQuestions.SAVE_ANSWER_TO_PENDING_QUESTION,
      body: (params) => ({ ...params }),
      invalidatesTags: ["PendingQuestions"],
    }),
    uploadSinglePendingQuestion: mutationEndpointBuilder<
      void,
      void,
      UploadSingleQuestionParams
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: Undefined,
      url: () => APIEndpoints.pendingQuestions.UPLOAD_SINGLE_PENDING_QUESTION,
      body: (params) => ({ ...params }),
      invalidatesTags: ["PendingQuestions"],
    }),

    bulkUploadPendingQuestions: builder.mutation<void, FormData>({
      query: (params) => ({
        url: APIEndpoints.pendingQuestions.BULK_UPLOAD,
        method: "POST",
        body: params,
      }),
      invalidatesTags: ["PendingQuestions"],
    }),
    assignUserPendingQuestion: mutationEndpointBuilder<
      void,
      void,
      AssignUserParams
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: Undefined,
      url: () => APIEndpoints.pendingQuestions.ASSIGN_USER,
      body: (params) => ({ ...params }),
      invalidatesTags: ["PendingQuestions"],
    }),
    editPendingQuestion: mutationEndpointBuilder<
      void,
      void,
      EditQuestionParams
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: Undefined,
      url: () => APIEndpoints.pendingQuestions.EDIT_QUESTION,
      body: (params) => ({ ...params }),
      invalidatesTags: ["PendingQuestions"],
    }),
  }),
});

export const {
  useGetPendingQuestionsQuery,
  useSaveAnswerToPendingQuestionMutation,
  useUploadSinglePendingQuestionMutation,
  useBulkUploadPendingQuestionsMutation,
  useAssignUserPendingQuestionMutation,
  useEditPendingQuestionMutation,
} = extendedApi;
