import { Literal, Record, Static, String, Union } from "runtypes";
import { runtypeFromEnum } from "../../../utils/runtypes";
import { CompanyIdRT, PartialUserRT } from "../../API/types";

export const RequestThreadIdRT = String.withBrand("RequestThreadId");
export type RequestThreadId = Static<typeof RequestThreadIdRT>;
const FileIdRT = String.withBrand("FileId");
export type FileId = Static<typeof FileIdRT>;

export enum FileType {
  gmailDraftAttachment = "gmailDraftAttachment",
  gmailAttachment = "gmailAttachmentMetadata",
  questionnaireTaskFile = "questionnaireTaskFile",
  commentFile = "commentFile",
}
const FileTypeRT = runtypeFromEnum(FileType);

export const FileAttributesRT = Record({
  filename: String,
  contentType: String,
  storagePath: String.optional(),
});

export const FileBaseRT = Record({
  type: FileTypeRT,
  id: FileIdRT,
  attributes: FileAttributesRT,
});

export const ExpediteRequestStatusRT = Union(
  Literal("waitingForResponse"),
  Literal("accepted"),
  Literal("rejected"),
  Literal("notRequested"),
);

export type ExpediteRequestStatus = Static<typeof ExpediteRequestStatusRT>;

export const DefaultRecipientRT = Record({
  type: Literal("defaultMissionControlRecipient"),
  id: String,
  attributes: Record({
    email: String,
    companyId: CompanyIdRT,
  }),
  relationships: Record({
    user: PartialUserRT.nullable(),
  }),
});

export type DefaultRecipient = Static<typeof DefaultRecipientRT>;
