import { Checkbox, Select, SelectProps, Tag } from "antd";
import clsx from "clsx";
import { debounce, uniqBy } from "lodash";
import { useState } from "react";
import { CenteredSpinner } from "shared_frontend/src/Spinner";
import { LabelSelectionStatus } from "../../containers/MissionControlContainer/components/Labels/TreeSelect/utils";
import { SimpleUser, UserId } from "../../features/API/types";
import UserAvatar from "../UserAvatar";
import styles from "./MultiUserSelector.module.css";
interface UserOption {
  label: React.ReactNode;
  tag: React.ReactNode;
  value: UserId;
  selectionStatus: LabelSelectionStatus;
}

export interface MultiUserSelectorProps {
  users: (Omit<SimpleUser, "type"> & { disabled?: boolean })[];
  isFetchingUsers?: boolean;
  onSearch: (query: string) => void;
  initialUsers?: Omit<SimpleUser, "type">[];
  partiallySelected?: Omit<SimpleUser, "type">[];
  value?: UserId[];
  onChange?: (value: UserId[]) => void;
  dropdownRender?: SelectProps["dropdownRender"];
  disabled?: boolean;
  className?: string;
  autoClearSearchValue?: boolean;
  placeholder?: string;
}

const MultiUserSelector = ({
  users,
  isFetchingUsers,
  onSearch,
  initialUsers,
  partiallySelected,
  value,
  onChange,
  dropdownRender,
  disabled,
  autoClearSearchValue,
  placeholder,
  className,
}: MultiUserSelectorProps) => {
  const [selectedUsers, setSelectedUsers] = useState(initialUsers ?? []);
  const combinedUsers = uniqBy(
    users.concat(selectedUsers).concat(partiallySelected ?? []),
    (u) => u.id,
  );
  const userOptions = combinedUsers.map((u) => {
    const selectionStatus: LabelSelectionStatus = value?.includes(u.id)
      ? "selected"
      : partiallySelected?.find((partial) => partial.id === u.id)
        ? "partial"
        : "unselected";

    const SelectorLabel = (
      <span className={styles.SelectorLabel} key={u.id}>
        <UserAvatar name={u.attributes.name} email={u.attributes.email} />
        {u.attributes.name}
      </span>
    );

    return {
      tag: SelectorLabel,
      label: (
        <div className={styles.SelectedOption}>
          <Checkbox
            className={styles.Checkbox_option}
            indeterminate={selectionStatus === "partial"}
            checked={selectionStatus === "selected"}
          />
          {SelectorLabel}
        </div>
      ),
      value: u.id,
      selectionStatus,
      disabled: u.disabled,
    };
  });

  return (
    <Select<UserId[], UserOption>
      onChange={(val) => {
        setSelectedUsers(
          val.map((v) => combinedUsers.find((u) => u.id === v)!),
        );
        onChange?.(val);
      }}
      onSearch={debounce((value) => {
        onSearch(value);
      }, 500)}
      onBlur={() => onSearch("")}
      className={clsx(styles.SelectInput, className)}
      mode="multiple"
      options={userOptions}
      showSearch
      allowClear
      disabled={disabled}
      autoClearSearchValue={autoClearSearchValue ?? false}
      filterOption={false}
      notFoundContent={
        isFetchingUsers ? <CenteredSpinner /> : "No results found"
      }
      tagRender={({ value: tagValue }) => {
        const option = userOptions.find((opt) => opt.value === tagValue);
        if (option === undefined) {
          return <></>;
        }

        return (
          <Tag
            className={clsx(
              styles.SelectedTag,
              disabled && styles.DisabledSelectedTag,
            )}
            closeIcon={!disabled}
            onClose={() => {
              if (!onChange || !value) return;
              onChange(value.filter((v) => v !== tagValue));
              setSelectedUsers(selectedUsers.filter((u) => u.id !== tagValue));
            }}
          >
            {option.tag}
          </Tag>
        );
      }}
      value={value}
      dropdownRender={dropdownRender}
      placeholder={placeholder}
    />
  );
};

export default MultiUserSelector;
