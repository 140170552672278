import { Avatar, Badge, Tooltip } from "antd";
import { TooltipPlacement } from "antd/lib/tooltip";
import { BsBuildingExclamation } from "react-icons/bs";
import { useGetMeQuery } from "../../features/API/auth";
import { Company } from "../../features/API/types";
import { hashColor } from "../../utils/colors/utils";
import styles from "./UserAvatar.module.scss";
import { convertToInitial } from "./utils";

export interface UserAvatarProps {
  company?: Company;
  email: string;
  name?: string;
  size?: number | "large" | "small" | "default";
  tooltipPlacement?: TooltipPlacement;
  hideTooltip?: boolean;
}

const UserAvatar = ({
  company,
  name,
  email,
  size,
  tooltipPlacement,
  hideTooltip,
}: UserAvatarProps): JSX.Element => {
  const { data: meData } = useGetMeQuery();

  const isExternalCompany =
    company && meData?.user.relationships?.company.id !== company.id;

  const isStaff = meData?.user.attributes.isStaff;

  const avatar = (
    <Badge
      className={styles.AvatarBadge}
      count={
        isStaff && isExternalCompany ? (
          <Tooltip title="External user">
            <BsBuildingExclamation className={styles.ExternalUserIcon} />
          </Tooltip>
        ) : undefined
      }
    >
      <Avatar
        className={styles.Avatar_icon}
        style={{
          backgroundColor: hashColor(email),
        }}
        size={size}
      >
        {convertToInitial(name || email)}
      </Avatar>
    </Badge>
  );
  if (hideTooltip) {
    return avatar;
  }

  const tooltipText = (
    <div className={styles.TooltipTextContainer}>
      {isExternalCompany && (
        <div className={styles.CompanyHeader}>
          <strong>{company.attributes.displayName}</strong>
          <BsBuildingExclamation />
        </div>
      )}
      <div className={styles.TooltipName}>
        {name && <strong>{name}</strong>}
        {email}
      </div>
    </div>
  );

  return (
    <Tooltip
      overlayClassName={styles.Tooltip}
      title={tooltipText}
      placement={tooltipPlacement ?? "bottom"}
    >
      {avatar}
    </Tooltip>
  );
};

export default UserAvatar;
