import { QuestionnaireResponsesId } from "../Questionnaires/types";
import { PresenceEntityId } from "./types";

// https://werxltd.com/wp/2010/05/13/javascript-implementation-of-javas-string-hashcode-method/
const getHashCode = (str: string): number => {
  let hash = 0,
    i,
    chr;
  if (str.length === 0) return hash;
  for (i = 0; i < str.length; i++) {
    chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};

const getResponseQuestionEntityId = (
  questionnaireResponseId: QuestionnaireResponsesId,
  questionNumber: number,
): PresenceEntityId => `${questionnaireResponseId}-${questionNumber}`;

export { getHashCode, getResponseQuestionEntityId };
