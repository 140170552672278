import {
  AnswerEntrySearchFilters,
  DocumentSearchFilters,
} from "../../KnowledgeLibrary/types";

export const constructEntriesQueryParams = (
  searchSettings: AnswerEntrySearchFilters,
  limit?: number,
  offset?: number,
  hybridOffset?: number,
): string => {
  const params = new URLSearchParams({
    query: searchSettings.query,
    ordering: searchSettings.ordering,
    onlyShowReviewed: searchSettings.onlyShowReviewed.toString(),
    tagsOperator: searchSettings.tagsOperator,
  });
  searchSettings.productIds.forEach((id) => {
    params.append("productIds", id.toString());
  });

  if (limit) {
    params.append("limit", limit.toString());
  }
  if (offset) {
    params.append("offset", offset.toString());
  }
  if (hybridOffset !== undefined) {
    params.append("hybridOffset", hybridOffset.toString());
  }
  if (searchSettings.beforeDate) {
    params.append("beforeDate", searchSettings.beforeDate.toISOString());
  }
  if (searchSettings.afterDate) {
    params.append("afterDate", searchSettings.afterDate.toISOString());
  }
  if (searchSettings.beforeNextReviewDate) {
    params.append(
      "beforeNextReviewDate",
      searchSettings.beforeNextReviewDate.toISOString(),
    );
  }
  if (searchSettings.afterNextReviewDate) {
    params.append(
      "afterNextReviewDate",
      searchSettings.afterNextReviewDate.toISOString(),
    );
  }
  searchSettings.categories?.forEach((category) => {
    params.append("categories", category);
  });
  searchSettings.sourceQuestionnaireIds?.forEach((sqId) => {
    params.append("sourceQuestionnaireIds", sqId);
  });
  searchSettings.tags?.forEach((tId) => {
    params.append("tagIds", tId);
  });
  searchSettings.ownerUserIds?.forEach((userId) => {
    params.append("ownerUserIds", userId.toString());
  });
  if (searchSettings.returnOutdatedReviewDate) {
    params.append(
      "returnOutdatedReviewDate",
      searchSettings.returnOutdatedReviewDate.toString(),
    );
  }
  if (searchSettings.noOwner) {
    params.append("noOwner", searchSettings.noOwner.toString());
  }
  return params.toString();
};

export const constructDocumentsQueryParams = (
  query: string,
  filters: DocumentSearchFilters,
  limit?: number,
  offset?: number,
): string => {
  const params = new URLSearchParams({
    query,
    onlyShowReviewed: filters.onlyShowReviewed.toString(),
    tagsOperator: filters.tagsOperator,
  });
  filters.productIds.forEach((id) => {
    params.append("productIds", id.toString());
  });
  if (limit) {
    params.append("limit", limit.toString());
  }
  if (offset) {
    params.append("offset", offset.toString());
  }
  if (filters.beforeNextReviewDate) {
    params.append(
      "beforeNextReviewDate",
      filters.beforeNextReviewDate.toISOString(),
    );
  }
  if (filters.afterNextReviewDate) {
    params.append(
      "afterNextReviewDate",
      filters.afterNextReviewDate.toISOString(),
    );
  }
  filters.tags?.forEach((tId) => {
    params.append("tagIds", tId);
  });
  filters.ownerUserIds?.forEach((userId) => {
    params.append("ownerUserIds", userId.toString());
  });
  if (filters.returnOutdatedReviewDate) {
    params.append(
      "returnOutdatedReviewDate",
      filters.returnOutdatedReviewDate.toString(),
    );
  }
  return params.toString();
};
