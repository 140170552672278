import { Tabs, Typography } from "antd";
import { useEffect } from "react";
import { HeaderInfoPopover } from "shared_frontend/src/InfoPopover/index";
import { trackViewReferPage } from "../../analytics";
import AppHeader from "../../components/AppHeader";
import { useGetMeQuery } from "../../features/API/auth";
import styles from "./ReferralContainer.module.scss";
import ReferralForm from "./components/ReferralForm";
import ReferralTable from "./components/ReferralTable";

const ReferralContainer = (): JSX.Element => {
  const { data: meData } = useGetMeQuery();
  const tabItems = [
    {
      label: "New Referral",
      key: "New Referral",
      children: <ReferralForm />,
    },
    {
      label: "Referral History",
      key: "Referral History",
      children: <ReferralTable />,
    },
  ];

  const companyId = meData?.user.relationships?.company.id;
  useEffect(() => {
    if (!companyId) {
      return;
    }
    trackViewReferPage(companyId);
  }, [companyId]);

  return (
    <>
      <AppHeader
        title={
          <HeaderInfoPopover
            title="Refer SecurityPal"
            content={
              <Typography.Paragraph>
                Provide a referral to SecurityPal and get a chance to win a $500
                AMEX or VISA Gift Card.
              </Typography.Paragraph>
            }
          />
        }
      />
      <Typography.Title level={4}>
        Hi there, it's the friendly SecurityPal Yeti! 👋🏽
      </Typography.Title>
      <Typography.Paragraph>
        <Typography.Text className={styles.LargeText}>
          Thank you for considering a referral to SecurityPal. Please provide
          the details of the person you would like to refer, along with any
          notes that might help us ensure a positive and valuable interaction.
        </Typography.Text>
        <br />
        <Typography.Text className={styles.LargeText}>
          If the referred person and their organization enter the procurement
          process with SecurityPal, you'll receive a $500 AMEX or VISA Gift Card
          as a token of our appreciation, regardless of whether they make a
          purchase.
        </Typography.Text>
      </Typography.Paragraph>
      <div className={styles.TabContainer}>
        <Tabs
          defaultActiveKey="New Referral"
          size="small"
          tabBarGutter={16}
          items={tabItems}
        />
      </div>
    </>
  );
};

export default ReferralContainer;
