import { ErrorEvent, EventHint } from "@sentry/react";
const eventLimiter: Record<string, boolean> = {};

export const beforeSentrySend = (
  event: ErrorEvent,
  hint: EventHint,
): ErrorEvent | PromiseLike<ErrorEvent | null> | null => {
  const msg = hint?.originalException?.toString();
  //Unclear from sentry docs when hint or original exception are null so just passing those through.
  if (!msg) return event;
  // do not send event if already sent in last 30 seconds
  if (msg && msg in eventLimiter) {
    return null;
  }
  eventLimiter[msg] = true;

  setTimeout(() => {
    delete eventLimiter[msg];
  }, 30 * 1000);

  return event;
};
