import { ModalProps, Typography } from "antd";
import MoveRankButtons from "../MoveRankButtons/MoveRankButtons";
import {
  CertificationSelection,
  CertificationSelectionId,
} from "../types/assuranceProfile";
import { statusIcon } from "../utils";
import styles from "./CertificationSelectionsList.module.scss";

interface CertificationSelectionsListProps {
  certificationSelections: CertificationSelection[];
  CreateModal?: React.FC<ModalProps>;
  onMoveCertificationSelection?: (
    certificationSelectionId: CertificationSelectionId,
    direction: string,
  ) => void;
  strippedCertifications?: boolean;
}

const CertificationSelectionsList = ({
  certificationSelections,
  CreateModal,
  onMoveCertificationSelection,
  strippedCertifications,
}: CertificationSelectionsListProps) => {
  const firstCertificatioSelection = certificationSelections[0];
  const lastCertificationSelection = certificationSelections.slice(-1)[0];

  return (
    <div className={styles.CertificationWrapper}>
      {certificationSelections.map((selection) => (
        <div className={styles.Tile} key={selection.id}>
          <div className={styles.ImageWrapper}>
            <img
              alt=""
              src={selection.relationships.certification.attributes.logoUrl}
            />
          </div>
          <div className={styles.CertificationCaption}>
            <div className={styles.StatusIconAndTextWrapper}>
              <span className={styles.StatusIconWrapper}>
                {statusIcon(selection.attributes.status)}
              </span>

              <Typography.Text className="TileText">
                {selection.relationships.certification.attributes.name}
              </Typography.Text>
            </div>
            {CreateModal && (
              <MoveRankButtons
                onMoveUp={() =>
                  onMoveCertificationSelection?.(selection.id, "up")
                }
                onMoveDown={() =>
                  onMoveCertificationSelection?.(selection.id, "down")
                }
                disableUp={selection.id === firstCertificatioSelection?.id}
                disableDown={
                  !strippedCertifications &&
                  selection.id === lastCertificationSelection?.id
                }
                vertical={false}
              />
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default CertificationSelectionsList;
