import { InfoCircleOutlined } from "@ant-design/icons";
import { Popover, Tag, Typography } from "antd";
import clsx from "clsx";
import styles from "./InfoPopover.module.scss";

interface InfoPopoverProper {
  title: string;
  content: React.ReactNode;
  showBetaTag?: boolean;
  className?: string;
}

export const HeaderInfoPopover = ({
  title,
  content,
  showBetaTag = false,
  className,
}: InfoPopoverProper): JSX.Element => {
  return (
    <div className={clsx(styles.Wrapper, className)}>
      <Typography.Text className={styles.Title}>{title}</Typography.Text>

      <Popover
        trigger="click"
        placement="bottomLeft"
        content={content}
        overlayClassName={styles.Popover}
      >
        <InfoCircleOutlined className={styles.InfoIcon} />
      </Popover>

      {showBetaTag && (
        <sup className={styles.BetaTag}>
          <Tag color="blue">beta</Tag>
        </sup>
      )}
    </div>
  );
};

export const InfoPopover = ({
  title,
  content,
  showBetaTag = false,
}: InfoPopoverProper): JSX.Element => {
  return (
    <div className={styles.Wrapper}>
      {title}

      <Popover
        trigger="click"
        placement="bottomLeft"
        content={content}
        overlayClassName={styles.Popover}
      >
        <InfoCircleOutlined className={styles.InfoIcon} />
      </Popover>

      {showBetaTag && (
        <sup className={styles.BetaTag}>
          <Tag color="blue">beta</Tag>
        </sup>
      )}
    </div>
  );
};
