import { Select, SelectProps } from "antd";
import { uniqBy } from "lodash";
import { useEffect, useState } from "react";
import { SimpleUser, UserId } from "../../features/API/types";
import UserAvatar from "../UserAvatar";
import styles from "./UserSelector.module.css";

export type UserSelectorProps = Omit<
  SelectProps,
  "options" | "filterOptions" | "showSearch"
> & {
  users: (Omit<SimpleUser, "type"> & { disabled?: boolean })[];
  initiallySelected?: Omit<SimpleUser, "type">;
};

interface UserOption {
  label: React.ReactNode;
  value: UserId;
}
const BaseUserSelector: (props: UserSelectorProps) => JSX.Element = (props) => {
  const [currentlySelected, setCurrentlySelected] = useState(
    props.initiallySelected,
  );

  useEffect(() => {
    if (!props.value) {
      setCurrentlySelected(undefined);
    } else if (props.users.find((u) => u.id === props.value)) {
      setCurrentlySelected(props.users.find((u) => u.id === props.value));
    }
  }, [props.value, props.users]);

  let users = props.users;
  if (currentlySelected) {
    users = [currentlySelected, ...users];
  }

  const userOptions = uniqBy(users, (u) => u.id).map((u) => ({
    label: (
      <span className={styles.UserSelectorLabel}>
        <UserAvatar name={u.attributes.name} email={u.attributes.email} />
        {u.attributes.name}
      </span>
    ),
    value: u.id,
    disabled: u.disabled,
  }));

  return (
    <Select<UserId, UserOption>
      className={styles.UserSelector}
      {...props}
      options={userOptions}
      showSearch
      onChange={(value, option) => {
        setCurrentlySelected(users.find((u) => u.id === value));
        props.onChange?.(value, option);
      }}
      onClear={() => {
        setCurrentlySelected(undefined);
        props.onClear?.();
      }}
      value={currentlySelected?.id ?? props.value}
      filterOption={false}
    />
  );
};

export default BaseUserSelector;
