import {
  Hex6Color,
  Hex6ColorRT,
  HexColor,
  isHex6Color,
} from "shared_frontend/src/types/colors";
import { getHashCode } from "../../features/Presence/utils";
import { darkAccentColors } from "../constants";

export const hashColor = (string: string): string => {
  const accentColorsArray = Object.values(darkAccentColors);
  const hashCode = Math.abs(getHashCode(string));
  return accentColorsArray[hashCode % accentColorsArray.length];
};

export const getTextColorFromBackgroundColor = (
  backgroundColor: HexColor,
): "black" | "white" => {
  const hex6 = convertToHex6Color(backgroundColor);
  return _getTextColorFromBackgroundHex6Color(hex6);
};

// Taken from https://24ways.org/2010/calculating-color-contrast
const _getTextColorFromBackgroundHex6Color = (hexColor: Hex6Color) => {
  const r = parseInt(hexColor.substring(1, 3), 16);
  const g = parseInt(hexColor.substring(3, 5), 16);
  const b = parseInt(hexColor.substring(5, 7), 16);
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? "black" : "white";
};

const convertToHex6Color = (color: HexColor): Hex6Color => {
  if (isHex6Color(color)) {
    return color;
  }
  const [__hash, r, g, b] = color.split("");
  const hex6 = `#${r}${r}${g}${g}${b}${b}`;
  return Hex6ColorRT.check(hex6);
};
