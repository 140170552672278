import { QueryStatus } from "@reduxjs/toolkit/query";
import * as Sentry from "@sentry/react";
import { message } from "antd";
import isEqual from "lodash/isEqual";
import { useEffect, useRef, useState } from "react";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { identify } from "../analytics";
import { useGetProductsQuery } from "../features/API";
import { useGetMeQuery } from "../features/API/auth";
import { Permission } from "../features/API/permissions";
import {
  CompanyId,
  Product,
  ProductId,
  SPQueryError,
  hasPermission,
} from "../features/API/types";
import { LAYOUT_KEY } from "../features/Layout";
import { sortProductsByName } from "../features/Layout/types";
import { UserData } from "../features/UserInfo/types";
import type { AppDispatch, RootState } from "../store";

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
//use this instead of useDispatch for correct typing when dispatching thunks
export const useAppDispatch: () => AppDispatch = useDispatch;

export const getErrorDetail = (error: SPQueryError): string => {
  if (error && "data" in error) {
    const errorData = error.data as Record<string, unknown>;
    if (typeof errorData === "object" && "detail" in errorData) {
      if (typeof errorData.detail === "string") {
        return errorData.detail;
      }
      return JSON.stringify(errorData.detail);
    } else {
      return JSON.stringify(error.data);
    }
  } else {
    return JSON.stringify(error);
  }
};

const isRedirect = (error: { status: number }) => {
  return error.status === 302;
};

export const useMessageForMutationResultHook = (
  status: QueryStatus,
  error: SPQueryError,
  successMessage: string,
  errorMessage: string,
  duration?: number,
): void => {
  const durationRef = useRef(duration);
  useEffect(() => {
    if (status === QueryStatus.fulfilled) {
      message.success(successMessage, durationRef.current);
    } else if (status === QueryStatus.rejected) {
      message.error(
        `${errorMessage}: ${getErrorDetail(error)}`,
        durationRef.current,
      );
    }
  }, [status, error, successMessage, errorMessage]);
};

export const useMessageForOptimisticMutationResultHook = (
  status: QueryStatus,
  successMessage: string,
): void => {
  useEffect(() => {
    if (status === QueryStatus.pending) {
      message.success(successMessage);
    }
  }, [status, successMessage]);
};

export const useMessageForMutationErrorHook = (
  status: QueryStatus,
  error: SPQueryError,
  errorMessage: string,
): void => {
  useEffect(() => {
    if (status === QueryStatus.rejected) {
      message.error(`${errorMessage}: ${getErrorDetail(error)}`);
    }
  }, [status, error, errorMessage]);
};

export const useMessageForRediractableMutationResultHook = (
  status: QueryStatus,
  error: SPQueryError,
  successMessage: string,
  errorMessage: string,
): void => {
  useEffect(() => {
    if (status === QueryStatus.fulfilled) {
      message.success(successMessage);
    } else if (
      status === QueryStatus.rejected &&
      !isRedirect(error as { status: number })
    ) {
      message.error(`${errorMessage}: ${getErrorDetail(error)}`);
    }
  }, [status, error, successMessage, errorMessage]);
};

export const useInterval = (callback: () => void, interval: number): void => {
  useEffect(() => {
    const id = setInterval(callback, interval);
    return () => clearInterval(id);
  }, [callback, interval]);
};

export const useActiveProduct = (): Product | null =>
  useAppSelector((state) => state[LAYOUT_KEY].activeProduct);

// Hook returns a bool indicating whether the query is fetching and the query params have changed,
// not when the query is fetching because of tag invalidation
export const useIsFetchingBasedOnQueryChange = <T>(
  isFetching: boolean,
  queryParams: T,
): boolean => {
  const [cachedQueryParams, setCachedQueryParams] = useState<T>();
  useEffect(() => {
    if (!isFetching) {
      setCachedQueryParams(queryParams);
    }
  }, [isFetching, queryParams]);

  return isFetching && !isEqual(queryParams, cachedQueryParams);
};

export const usePrevious = <T>(
  value: T | undefined | null,
): T | undefined | null => {
  const ref = useRef<T | null | undefined>();
  useEffect(() => {
    ref.current = value; //assign the value of ref to the argument
  }, [value]); //this code will run when the value of 'value' changes
  return ref.current; //in the end, return the current ref value.
};

export const useSetupTracking = (userData?: UserData): void => {
  useEffect(() => {
    if (userData !== undefined) {
      identify(userData);
    }
  }, [userData]);

  // tag the session with company name because this is the only way we can filter by company in the UI
  const currentUserCompanyName =
    userData?.user.relationships?.company.attributes.displayName;

  useEffect(() => {
    if (currentUserCompanyName) {
      Sentry.getCurrentScope().setTag("company", currentUserCompanyName);
    }
  }, [currentUserCompanyName]);
};

export const useHasPermission = (
  p: Permission,
  productId?: ProductId,
): boolean => {
  const { data: userData } = useGetMeQuery();
  const productPermissions = useAppSelector(
    (state) => state[LAYOUT_KEY].productPermissions,
  );
  if (!userData) return false;
  if (userData.user.attributes.isStaff) {
    return hasPermission(userData.user, p);
  }
  if (!productPermissions) return false;
  if (productId) {
    return !!productPermissions[productId]?.includes(p);
  }

  return !!userData.user.attributes.permissions?.includes(p);
};

export const useProductsWithPermission = (
  p: Permission,
  products: Product[],
): Product[] => {
  const { data: userData } = useGetMeQuery();

  const productPermissions = useAppSelector(
    (state) => state[LAYOUT_KEY].productPermissions,
  );
  if (!userData) return [];
  if (userData.user.attributes.isStaff) {
    if (!hasPermission(userData.user, p)) {
      return [];
    }
    return products;
  }
  if (!productPermissions) return [];
  return products.filter((product) =>
    productPermissions[product.id]?.includes(p),
  );
};

export const useHasPermissionOnAnyProduct = (
  p: Permission,
  productIds?: ProductId[],
): boolean => {
  const { data: userData } = useGetMeQuery();
  const productPermissions = useAppSelector(
    (state) => state[LAYOUT_KEY].productPermissions,
  );
  if (!userData) return false;
  if (userData.user.attributes.isStaff) {
    return hasPermission(userData.user, p);
  }
  if (!productPermissions) return false;
  for (const productId of productIds || []) {
    if (productPermissions[productId]?.includes(p)) {
      return true;
    }
  }
  return false;
};

export const useHasPermissionOnAllProducts = (
  p: Permission,
  productIds?: ProductId[],
): boolean => {
  const { data: userData } = useGetMeQuery();
  const productPermissions = useAppSelector(
    (state) => state[LAYOUT_KEY].productPermissions,
  );
  if (!userData) return false;
  if (userData.user.attributes.isStaff) {
    return hasPermission(userData.user, p);
  }
  if (!productPermissions) return false;
  for (const productId of productIds || []) {
    if (!productPermissions[productId]?.includes(p)) {
      return false;
    }
  }
  return true;
};

export const useScrollToAnchor = (): void => {
  const location = useLocation();
  const lastHash = useRef("");

  // listen to location change using useEffect with location as dependency
  // https://jasonwatmore.com/react-router-v6-listen-to-location-route-change-without-history-listen
  useEffect(() => {
    if (location.hash) {
      lastHash.current = location.hash.slice(1); // safe hash for further use after navigation
    }

    if (lastHash.current && document.getElementById(lastHash.current)) {
      const timer = setTimeout(() => {
        document
          .getElementById(lastHash.current)
          ?.scrollIntoView({ behavior: "smooth", block: "start" });
        lastHash.current = "";
      }, 100);

      return () => clearTimeout(timer);
    }
  }, [location]);
};

/**
 * Custom hook to fetch products for a specific company.
 *
 * For non-staff users: Returns products of their own company.
 * For staff users: Returns products of the own company if no company is specified. Else, return products for specified company.
 *
 * @param targetCompanyId Optional company ID to filter products
 * @returns Filtered array of products Sorted by Name
 */
export const useGetCompanyProducts = (companyId?: CompanyId): Product[] => {
  const { data: productsData } = useGetProductsQuery();
  const { data: meData } = useGetMeQuery();

  const products = productsData?.data;
  const targetCompanyId = companyId || meData?.user.relationships?.company.id;

  if (!products || !targetCompanyId) {
    return [];
  }

  return products
    .filter(
      (product) => product.relationships.company.data.id === targetCompanyId,
    )
    .sort(sortProductsByName);
};

export const useGetCompanyFromProductId = (
  productId?: ProductId,
): CompanyId | undefined => {
  const { data: productsData } = useGetProductsQuery(undefined, {
    skip: !productId,
  });
  const products = productsData?.data;
  if (!products) {
    return undefined;
  }
  return (
    products.find((product) => product.id === productId)?.relationships.company
      .data.id || undefined
  );
};

const useGetProductMap = (): Record<ProductId, Product> => {
  const { data: productsData } = useGetProductsQuery();
  const products = productsData?.data;
  const productMap: Record<ProductId, Product> = {};
  products?.reduce((acc, next) => {
    acc[next.id] = next;
    return acc;
  }, productMap);
  return productMap;
};

export const useGetProductsFromIds = (ids: ProductId[]): Product[] => {
  const productMap = useGetProductMap();
  const values = ids.map((id) => productMap[id]).filter((_) => _);
  if (values.length !== ids.length) {
    Sentry.captureException(new Error("Couldn't find product"), {
      extra: { ids, values },
    });
  }
  return values;
};
