import { Button, Modal, Result, Typography } from "antd";
interface DownloadCompleteModalProps {
  onClose: () => void;
  downloadedCount: number;
  onError?: boolean;
}

const DownloadCompleteModal: React.FC<DownloadCompleteModalProps> = ({
  onClose,
  downloadedCount,
  onError,
}) => {
  return (
    <>
      {!onError && (
        <Modal open={true} onCancel={onClose} centered footer={null}>
          <Result
            status="success"
            title="Download complete"
            subTitle={`You successfully downloaded ${downloadedCount}
                ${downloadedCount > 1 ? "documents" : "document"}.`}
            extra={[
              <Button key="back" onClick={onClose}>
                Back to Assurance Profile
              </Button>,
            ]}
          />
        </Modal>
      )}

      {onError && (
        <Modal open={true} onCancel={onClose} centered onOk={onClose}>
          <Result
            status="error"
            title={
              <Typography.Title level={4}>Error downloading</Typography.Title>
            }
            subTitle={`Oops, looks like we encountered an error during this download.
                Try again.`}
          />
        </Modal>
      )}
    </>
  );
};

export default DownloadCompleteModal;
