import { InboxOutlined } from "@ant-design/icons";
import { Button, Upload } from "antd";
import { RcFile } from "antd/lib/upload";
import styles from "./UploadDragger.module.css";

interface UploadDraggerProps {
  file?: RcFile;
  onSetFile?: (file: RcFile | undefined) => void;
  acceptedFiles: string;

  mainText: React.ReactNode;
  subtitleText?: React.ReactNode;

  hideButton?: boolean;
  onButtonClick?: () => void;
  buttonDisabled?: boolean;
  buttonLoading?: boolean;
  buttonText?: string;
}

const UploadDragger = ({
  file,
  onSetFile,
  acceptedFiles,
  mainText,
  subtitleText,
  hideButton,
  onButtonClick,
  buttonDisabled,
  buttonLoading,
  buttonText,
}: UploadDraggerProps): JSX.Element => {
  return (
    <div className={styles.UploadDragger}>
      <Upload.Dragger
        accept={acceptedFiles}
        beforeUpload={(file) => {
          if (onSetFile) {
            onSetFile(file);
          }
          return false;
        }}
        maxCount={1}
        onRemove={() => {
          if (onSetFile) {
            onSetFile(undefined);
          }
        }}
        fileList={file ? [file] : undefined}
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">{mainText}</p>
        {subtitleText && <p className="ant-upload-hint">{subtitleText}</p>}
      </Upload.Dragger>
      {!hideButton && (
        <Button
          type="primary"
          disabled={buttonDisabled}
          loading={buttonLoading}
          onClick={onButtonClick}
          className={styles.UploadDragger_button}
        >
          {buttonText || "Upload"}
        </Button>
      )}
    </div>
  );
};

export default UploadDragger;
