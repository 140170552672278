import {
  DownOutlined,
  LeftOutlined,
  RightOutlined,
  UpOutlined,
} from "@ant-design/icons";
import { Button } from "antd";
import styles from "./MoveRankButtons.module.scss";

interface MoveRankButtonsProps {
  onMoveUp: () => void;
  onMoveDown: () => void;
  disableUp?: boolean;
  disableDown?: boolean;
  vertical: boolean;
}

const MoveRankButtons = ({
  onMoveDown,
  onMoveUp,
  disableUp,
  disableDown,
  vertical,
}: MoveRankButtonsProps): JSX.Element => (
  <div className={styles.MoveRankButtons}>
    <Button
      className={styles.MoveButton}
      type="text"
      onClick={onMoveUp}
      disabled={disableUp}
    >
      {vertical ? (
        <UpOutlined className="TileText" />
      ) : (
        <LeftOutlined className="TileText" />
      )}
    </Button>
    <Button
      className={styles.MoveButton}
      type="text"
      onClick={onMoveDown}
      disabled={disableDown}
    >
      {vertical ? (
        <DownOutlined className="TileText" />
      ) : (
        <RightOutlined className="TileText" />
      )}
    </Button>
  </div>
);

export default MoveRankButtons;
