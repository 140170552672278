import { SelectProps } from "antd";
import { debounce } from "lodash";
import { useMemo, useState } from "react";
import BaseUserSelector from ".";
import { SimpleUser } from "../../features/API/types";
import {
  GetUsersParams,
  useGetUsersQuery,
} from "../../features/API/userManagement";

const DEBOUNCE_TIME_MILLISECONDS = 500;

interface PaginatedUserSelectorProps extends SelectProps {
  filterParams: GetUsersParams;
  initiallySelected?: Omit<SimpleUser, "type">;
}
const PaginatedUserSelector = (props: PaginatedUserSelectorProps) => {
  const [userNameQuery, setUserNameQuery] = useState("");
  const { data: userData, isFetching } = useGetUsersQuery({
    ...props.filterParams,
    name: userNameQuery,
  });
  const debouncedSearch = useMemo(
    () =>
      debounce((query: string) => {
        setUserNameQuery(query);
      }, DEBOUNCE_TIME_MILLISECONDS),
    [],
  );

  return (
    <BaseUserSelector
      {...props}
      users={userData?.data ?? []}
      initiallySelected={props.initiallySelected}
      loading={isFetching}
      onSearch={debouncedSearch}
    />
  );
};

export default PaginatedUserSelector;
