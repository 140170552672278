import BulkUploadQuestions from "./components/BulkUploadQuestions";
import UploadSingleQuestion from "./components/UploadSingleQuestion";

import { Route } from "react-router-dom";
import AppRoutes from "../../components/AppRoutes";
import PendingQuestionsInternal from "./components/PendingQuestionsInternal";
const PendingQuestionsInternalContainer = (): JSX.Element => {
  return (
    <AppRoutes>
      <Route path="/" element={<PendingQuestionsInternal />} />
      <Route
        path="/upload-single-question"
        element={<UploadSingleQuestion />}
      />
      <Route path="/bulk-upload-questions" element={<BulkUploadQuestions />} />
    </AppRoutes>
  );
};

export default PendingQuestionsInternalContainer;
