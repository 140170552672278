import { Select, SelectProps } from "antd";
import { Company, CompanyId, FullCompany } from "../../features/API/types";
import { selectSortedCompanies } from "../../features/Layout";
import { useAppSelector } from "../../utils/hooks";

interface CompanyOption {
  value: CompanyId;
  label: string;
}

type CompanySelectorProps = Omit<
  SelectProps,
  "options" | "filterOptions" | "showSearch"
> & {
  companies?: (Company | FullCompany)[];
};

const CompanySelector = (props: CompanySelectorProps): JSX.Element => {
  const defaultCompanies = useAppSelector(selectSortedCompanies);
  const companyOptions = (props.companies ?? defaultCompanies).map((c) => ({
    value: c.id,
    label: c.attributes.displayName,
  }));

  return (
    <Select<CompanyId, CompanyOption>
      showSearch
      filterOption={(inputVal, option) =>
        option?.label.toLowerCase().includes(inputVal.toLowerCase()) ?? false
      }
      options={companyOptions}
      aria-label="Company selector"
      placeholder="Select a Company"
      {...props}
    />
  );
};

export default CompanySelector;
