import { Dayjs } from "dayjs";
import { Array, Record, Static, String } from "runtypes";
import { SortDirections } from "shared_frontend/src/types/assuranceProfile";
import {
  QuestionnaireTypeOption,
  QuestionnaireTypeOptionRT,
} from "../API/MissionControl/types";
import { DashboardQuestionnaireStatus, ProductId, UserId } from "../API/types";
import { KnowledgeLibraryTagId } from "../KnowledgeLibrary/types";

const DashboardQuestionnaireSenderRT = Record({
  name: String,
  email: String,
});

export type DashboardQuestionnaireSender = Static<
  typeof DashboardQuestionnaireSenderRT
>;

export const DashboardQuestionnaireSendersReturnDataRT = Record({
  senders: Array(DashboardQuestionnaireSenderRT),
});

export type DashboardQuestionnaireSendersReturnData = Static<
  typeof DashboardQuestionnaireSendersReturnDataRT
>;

export const DashboardQuestionnaireFormatsReturnDataRT = Record({
  formats: Array(QuestionnaireTypeOptionRT),
});

export type DashboardQuestionnaireFormatsReturnData = Static<
  typeof DashboardQuestionnaireFormatsReturnDataRT
>;

export type DashboardFilters = {
  statuses?: DashboardQuestionnaireStatus[] | null;
  productIds?: ProductId[] | null;
  acceptedBeforeDate?: Dayjs | null;
  acceptedAfterDate?: Dayjs | null;
  dueBeforeDate?: Dayjs | null;
  dueAfterDate?: Dayjs | null;
  returnedBeforeDate?: Dayjs | null;
  returnedAfterDate?: Dayjs | null;
  sortBy?: string | null;
  sortDirection?: SortDirections | null;
  query?: string | null;
  minCompletionPercentage?: number | null;
  maxCompletionPercentage?: number | null;
  formats?: QuestionnaireTypeOption[] | null;
  expedited?: boolean | null;
  tagIds?: KnowledgeLibraryTagId[] | null;
  senders?: { name: string; email: string }[] | null;
  assigneesIds?: UserId[];
};
