import { Undefined } from "runtypes";
import { privateApi } from ".";
import { APIEndpoints } from "../../utils/constants";
import { UserAuthData, UserAuthDataRT } from "../UserInfo/types";
import { mutationEndpointBuilder } from "./utils";

const socialAuthApi = privateApi.injectEndpoints({
  endpoints: (builder) => ({
    loginWithGoogle: mutationEndpointBuilder<void, UserAuthData, string>({
      builder,
      metaRuntype: Undefined,
      dataRuntype: UserAuthDataRT,
      url: () => APIEndpoints.socialAuth.GOOGLE_LOGIN,
      body: (authToken) => ({ authToken }),
      extraOptions: {
        unauthed: true,
      },
    }),
    loginWithMicrosoft: mutationEndpointBuilder<void, UserAuthData, string>({
      builder,
      metaRuntype: Undefined,
      dataRuntype: UserAuthDataRT,
      url: () => APIEndpoints.socialAuth.MICROSOFT_LOGIN,
      body: (code) => ({ code }),
      extraOptions: {
        unauthed: true,
      },
    }),
  }),
  overrideExisting: false,
});

export const { useLoginWithGoogleMutation, useLoginWithMicrosoftMutation } =
  socialAuthApi;

export default socialAuthApi;
