import { Button, Modal, Tooltip } from "antd";
import clsx from "clsx";
import saveAs from "file-saver";
import { useState } from "react";
import DocumentViewerContent, {
  DocumentViewerProps,
} from "../DocumentViewerContent";
import styles from "./NdaDocumentModal.module.scss";
interface NdaDocumentModalProps extends DocumentViewerProps {
  title: string;
  onCancel: () => void;
  ndaBlob: Blob;
  previewError?: boolean;
  onNdaSigned: () => void;
  isDownloadNDAEnabled?: boolean;
}
const NdaDocumentModal = ({
  ndaBlob,
  title,
  onCancel,
  previewError,
  onNdaSigned,
  isDownloadNDAEnabled,
}: NdaDocumentModalProps): JSX.Element => {
  const [hasScrolledToBottom, setHasScrolledToBottom] = useState(false);

  return (
    <Modal
      className={clsx({
        [styles.NdaDocumentModal]: !previewError,
      })}
      open={true}
      onCancel={onCancel}
      title={title}
      width="90%"
      destroyOnClose
      footer={
        <div className={styles.SignButtonContainer}>
          <Tooltip
            title={
              hasScrolledToBottom
                ? undefined
                : "Scroll to the bottom to accept NDA"
            }
          >
            <div className={styles.ButtonContainer}>
              <Button
                size="large"
                disabled={!hasScrolledToBottom}
                type="primary"
                onClick={onNdaSigned}
              >
                Accept NDA
              </Button>
              {isDownloadNDAEnabled && (
                <Button
                  size="large"
                  disabled={!hasScrolledToBottom}
                  type="primary"
                  onClick={() => {
                    saveAs(ndaBlob, title);
                  }}
                >
                  Download NDA
                </Button>
              )}
            </div>
          </Tooltip>
        </div>
      }
    >
      <DocumentViewerContent
        previewBlob={ndaBlob}
        previewError={previewError}
        onScrolledToBottom={() => setHasScrolledToBottom(true)}
      />
    </Modal>
  );
};

export default NdaDocumentModal;
