import jwt_decode from "jwt-decode";
import { v4 as uuidv4 } from "uuid";
import { UserId } from "../features/API/types";
import { TokenData, TokenDataRT } from "../features/UserInfo/types";
import {
  APIEndpoints,
  APIHeaders,
  APIHeadersForMultipartFormData,
  X_REQUEST_ID_HEADER_NAME,
} from "../utils/constants";

const LOCAL_STORAGE_KEY = "tokens";

type JWT = {
  exp: number;
  iat: number;
  jti: string;
  token_type: string;
  user_id: UserId;
};

const loadTokens = (): TokenData | null => {
  try {
    const serializedState = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (!serializedState) return null;

    const parsedData = JSON.parse(serializedState);
    if (TokenDataRT.guard(parsedData)) {
      return parsedData;
    }
    return null;
  } catch {
    return null;
  }
};

const saveTokens = (tokens: TokenData | null): void => {
  if (tokens) {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(tokens));
  } else {
    localStorage.removeItem(LOCAL_STORAGE_KEY);
  }
};

class TokenRefresher {
  private static requests: Map<string, Promise<TokenData | null>> = new Map();

  static async refreshTokens(refresh: string): Promise<TokenData | null> {
    const existingRequest = this.requests.get(refresh);
    if (existingRequest) {
      const tokens = await existingRequest;
      return tokens;
    }
    const newRequest = this.requestRefresh(refresh);
    this.requests.set(refresh, newRequest);
    const tokens = await newRequest;
    this.requests.delete(refresh);
    return tokens;
  }

  private static async requestRefresh(
    refresh: string,
  ): Promise<TokenData | null> {
    try {
      const request = await fetch(APIEndpoints.auth.REFRESH_TOKEN, {
        method: "POST",
        body: JSON.stringify({ refresh }),
        headers: {
          [X_REQUEST_ID_HEADER_NAME]: uuidv4(),
          ...APIHeaders,
        },
      });
      const tokensJson = await request.json();
      if (!tokensJson) {
        return null;
      }
      return TokenDataRT.check(tokensJson);
    } catch {
      return null;
    }
  }
}

interface TokensWithExpiry {
  tokens: TokenData;
  accessTokenExpiryTime: number;
}

class TokenHandler {
  private static tokensWithExpiry: TokensWithExpiry | null = null;

  static clearTokens(): void {
    this.tokensWithExpiry = null;
    this.saveTokens();
  }

  static loadTokens(): void {
    const tokens = loadTokens();
    if (tokens) {
      this.updateTokens(tokens);
    }
  }

  static updateTokens(tokens: TokenData): void {
    this.setTokens(tokens);
    this.saveTokens();
  }

  private static setTokens(tokens: TokenData): void {
    const decodedJWT = jwt_decode<JWT>(tokens.access);
    const accessTokenExpiryTime = (decodedJWT.exp - 30) * 1000; // Expire 30 seconds early in case of clock skew
    this.tokensWithExpiry = {
      tokens,
      accessTokenExpiryTime,
    };
  }

  private static saveTokens(): void {
    saveTokens(this.tokensWithExpiry?.tokens ?? null);
  }

  static getRefreshToken(): string | null {
    return this.tokensWithExpiry?.tokens.refresh || null;
  }

  static async getAccessToken(): Promise<string | null> {
    if (!this.tokensWithExpiry) {
      return null;
    }
    if (Date.now() < this.tokensWithExpiry.accessTokenExpiryTime) {
      return this.tokensWithExpiry.tokens.access;
    }

    // Before attempting to refresh, load the tokens from localStorage, in case
    // there was a refresh performed in another tab.
    // This is also important because we blacklist refresh tokens that were
    // already used, so if the in-memory refresh token was used, it will 403.
    this.loadTokens();
    if (!this.tokensWithExpiry) {
      return null;
    }
    if (Date.now() < this.tokensWithExpiry.accessTokenExpiryTime) {
      return this.tokensWithExpiry.tokens.access;
    }

    const tokens = await TokenRefresher.refreshTokens(
      this.tokensWithExpiry.tokens.refresh,
    );
    // Because we may have multiple refreshes happening concurrently, we
    // may end up making multiple calls to `updateTokens` / `clearTokens`
    // to flush to local storage. We could avoid this, but it's not important
    // since those calls are idempotent and not particularly expensive.
    if (tokens) {
      this.updateTokens(tokens);
    } else {
      this.clearTokens();
    }
    return tokens?.access ?? null;
  }

  static async authHeaders(): Promise<Record<string, string>> {
    const token = await this.getAccessToken();
    return { ...APIHeaders, Authorization: `Bearer ${token}` };
  }

  static async authHeadersForMultipartFormData(): Promise<
    Record<string, string>
  > {
    const token = await this.getAccessToken();
    return {
      ...APIHeadersForMultipartFormData,
      Authorization: `Bearer ${token}`,
    };
  }
}

export default TokenHandler;
