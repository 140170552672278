import { Permission } from "../../features/API/permissions";
import { hasPermission } from "../../features/API/types";
import { UserData } from "../../features/UserInfo/types";
export interface SideMenuItem {
  name: React.ReactNode;
  component: React.ComponentType;
  icon: React.ReactElement;
  path: string;
  requiresCompanyAffiliation?: boolean;
  devOnly?: boolean;
  enablingFeatureFlag?: string;
  disablingFeatureFlag?: string;
  enablingPermissions?: Permission[];
  externalFacing?: boolean;
  isBottomMenu?: boolean;
}

export const shouldShowSideMenuItem = (
  item: SideMenuItem,
  userData: UserData,
): boolean => {
  // hide internal only side menu items unless the user is staff
  if (!item.externalFacing && !userData.user.attributes.isStaff) {
    return false;
  }
  if (
    item.enablingFeatureFlag &&
    !userData.knownFeatureFlags?.[item.enablingFeatureFlag]
  ) {
    // If enablingFeatureFlag is set and the flag is not present
    // return false.
    return false;
  }
  if (
    item.disablingFeatureFlag &&
    userData.knownFeatureFlags?.[item.disablingFeatureFlag]
  ) {
    // If disablingFeatureFlag is set and the flag is present, even
    // if there is a corresponding enabling flag present, return null.
    return false;
  }

  if (item.devOnly && import.meta.env.MODE !== "development") {
    return false;
  }

  if (item.enablingPermissions) {
    let allowedToView = false;
    for (let i = 0; i < item.enablingPermissions.length; i++) {
      if (hasPermission(userData.user, item.enablingPermissions[i])) {
        allowedToView = true;
      }
    }
    if (!allowedToView) {
      return false;
    }
  }

  if (
    !userData.user.relationships?.company &&
    item.requiresCompanyAffiliation
  ) {
    return false;
  }

  return true;
};
