import { Popover } from "antd";
import { ReactNode } from "react";
import PaginatedUserSelector from "../../components/UserSelector/PaginatedUserSelector";
import { useGetMeQuery } from "../../features/API/auth";
import { useAssignUserPendingQuestionMutation } from "../../features/API/pendingQuestions";
import { SimpleUser, UserId } from "../../features/API/types";
import type { PendingQuestionResponse } from "../../features/pendingQuestions/types";
import { useMessageForMutationErrorHook } from "../../utils/hooks";
import styles from "./AssignUserPopover.module.css";
interface AssignUserPopoverProps {
  pendingQuestion: PendingQuestionResponse;
  children?: ReactNode;
  popoverOpen: boolean;
  setPopoverOpen: (open: boolean) => void;
}

const AssignUserPopover = ({
  pendingQuestion,
  children,
  popoverOpen,
  setPopoverOpen,
}: AssignUserPopoverProps): JSX.Element => {
  const { data: meData } = useGetMeQuery();
  const securityPalCompanyId = meData?.user.relationships?.company.id;

  const [assignUser, { status, error }] =
    useAssignUserPendingQuestionMutation();
  useMessageForMutationErrorHook(
    status,
    error,
    "Error assigning user to pending question",
  );

  if (!securityPalCompanyId) {
    return <></>;
  }

  return (
    <Popover
      open={popoverOpen}
      placement="bottom"
      trigger="click"
      onOpenChange={setPopoverOpen}
      getPopupContainer={(trigger) => trigger.parentElement ?? document.body}
      content={
        <PaginatedUserSelector
          className={styles.UserSelector}
          onSelect={(id: UserId) => {
            assignUser({
              pendingQuestionId: pendingQuestion.id,
              assigneeId: id,
            });
            setPopoverOpen(false);
          }}
          onClear={() => {
            assignUser({
              pendingQuestionId: pendingQuestion.id,
              assigneeId: null,
            });
            setPopoverOpen(false);
          }}
          allowClear
          filterParams={{ companyId: securityPalCompanyId }}
          initiallySelected={
            pendingQuestion.relationships.assignedUser
              ? (pendingQuestion.relationships.assignedUser as SimpleUser)
              : undefined
          }
          value={pendingQuestion.relationships.assignedUser}
        />
      }
    >
      {children}
    </Popover>
  );
};

export default AssignUserPopover;
