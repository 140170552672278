import { Typography } from "antd";
import Color from "color";
import { FaGift } from "react-icons/fa";
import { ProfileVersion } from "../types/assuranceProfile";
import styles from "./AssuranceProfileBanner.module.css";

const AssuranceProfileBanner = ({
  profileVersion,
}: { profileVersion: ProfileVersion }) => {
  const accentColor =
    profileVersion.relationships.styleSettings.attributes.primaryColor;

  return (
    <Typography.Link
      href="https://www.securitypalhq.com/free-assurance-profile"
      style={{
        backgroundColor: accentColor,
        color: Color(accentColor).isLight() ? "black" : "white",
      }}
      className={styles.bannerTextWrap}
    >
      <FaGift />
      Click here to get a complimentary assurance profile crafted for your
      organization
    </Typography.Link>
  );
};

export default AssuranceProfileBanner;
