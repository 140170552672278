import { String, Undefined, Union } from "runtypes";
import { privateApi } from "..";
import { APIEndpoints } from "../../../utils/constants";
import { FileId } from "../../MissionControl/types/base";
import {
  GmailDraftMessage,
  GmailDraftMessageId,
  GmailDraftMessageRT,
  GmailMessage,
  GmailMessageRT,
} from "../../MissionControl/types/gmail";
import {
  CreateDraftParams,
  PostCreateDraftParams,
  ScheduleSendDraftResponse,
  ScheduleSendDraftResponseRT,
  UpsertDraftParams,
} from "../../MissionControl/types/schema";
import { mutationEndpointBuilder, queryEndpointBuilder } from "../utils";

const missionControlDraftsApi = privateApi.injectEndpoints({
  endpoints: (builder) => ({
    bulkUploadAttachments: mutationEndpointBuilder<
      void,
      GmailDraftMessage,
      FormData
    >({
      builder,
      dataRuntype: GmailDraftMessageRT,
      metaRuntype: Undefined,
      url: () => APIEndpoints.missionControl.BULK_UPLOAD_ATTACHMENTS,
      body: (params) => params,
    }),
    bulkDeleteAttachments: mutationEndpointBuilder<
      undefined,
      GmailDraftMessage,
      { draftId: GmailDraftMessageId; attachmentIds: FileId[] }
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: GmailDraftMessageRT,
      url: () => APIEndpoints.missionControl.BULK_DELETE_ATTACHMENTS,
      body: (params) => params,
    }),
    previewGmailImages: queryEndpointBuilder<
      undefined,
      string,
      { storagePath: string }
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: String,
      url: (params) => {
        return `${APIEndpoints.missionControl.PREVIEW_GMAIL_IMAGE}?storagePath=${params.storagePath}`;
      },
    }),
    createGmailDraft: mutationEndpointBuilder<
      undefined,
      GmailDraftMessage | GmailMessage,
      CreateDraftParams
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: Union(GmailDraftMessageRT, GmailMessageRT),
      url: () => APIEndpoints.missionControl.CREATE_GMAIL_DRAFT,
      body: (params) => {
        const formData = new FormData();
        [
          ["to", params.to],
          ["cc", params.cc],
          ["bcc", params.bcc],
        ].forEach(([key, emails]) => {
          (emails as string[] | undefined)?.forEach((email) => {
            formData.append(key as string, email);
          });
        });
        params.files?.forEach((file) => {
          formData.append("files", file.originFileObj as Blob);
        });
        if (params.gmailMessageId) {
          formData.append("gmailMessageId", params.gmailMessageId.toString());
        }
        if (params.sendImmediately) {
          formData.append("sendImmediately", params.sendImmediately.toString());
        }
        if (params.globalMessageId) {
          formData.append("globalMessageId", params.globalMessageId);
        }
        if (params.body) {
          formData.append("body", params.body);
        }
        if (params.draftId) {
          formData.append("draftId", params.draftId);
        }
        if (params.subject) {
          formData.append("subject", params.subject);
        }
        return formData;
      },
    }),
    postProcessGmailDraftAfterSignedUpload: mutationEndpointBuilder<
      undefined,
      GmailDraftMessage | GmailMessage,
      PostCreateDraftParams
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: Union(GmailDraftMessageRT, GmailMessageRT),
      url: () => APIEndpoints.missionControl.POST_SIGNED_UPLOAD_URLS,
      body: (params) => params,
    }),
    deleteDraft: mutationEndpointBuilder<void, void, GmailDraftMessageId>({
      builder,
      metaRuntype: Undefined,
      dataRuntype: Undefined,
      url: () => APIEndpoints.missionControl.DELETE_DRAFT,
      body: (draftId) => ({ draftId }),
    }),
    scheduleSendDraft: mutationEndpointBuilder<
      void,
      ScheduleSendDraftResponse,
      GmailDraftMessageId
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: ScheduleSendDraftResponseRT,
      url: () => APIEndpoints.missionControl.SCHEDULE_SEND_DRAFT,
      body: (draftId) => ({ draftId }),
    }),
    undoSendDraft: mutationEndpointBuilder<
      void,
      GmailDraftMessage,
      { draftId: GmailDraftMessageId; taskName: string }
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: GmailDraftMessageRT,
      url: () => APIEndpoints.missionControl.UNDO_SEND_DRAFT,
      body: (params) => params,
    }),
    updateGmailDraft: mutationEndpointBuilder<
      void,
      GmailDraftMessage,
      UpsertDraftParams
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: GmailDraftMessageRT,
      url: () => APIEndpoints.missionControl.UPDATE_GMAIL_DRAFT,
      body: (params) => params,
    }),
    takeOverDraft: mutationEndpointBuilder<
      void,
      GmailDraftMessage,
      GmailDraftMessageId
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: GmailDraftMessageRT,
      url: () => APIEndpoints.missionControl.TAKE_OVER_DRAFT,
      body: (draftId) => ({ draftId }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useBulkDeleteAttachmentsMutation,
  useBulkUploadAttachmentsMutation,
  useCreateGmailDraftMutation,
  usePostProcessGmailDraftAfterSignedUploadMutation,
  useDeleteDraftMutation,
  useScheduleSendDraftMutation,
  useUpdateGmailDraftMutation,
  useUndoSendDraftMutation,
  useTakeOverDraftMutation,
  usePreviewGmailImagesQuery,
} = missionControlDraftsApi;

export default missionControlDraftsApi;
