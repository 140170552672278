import { Literal, Static, Union } from "runtypes";

export const QuestionnaireTypeOptionRT = Union(
  Literal("OneTrust Portal"),
  Literal("Another Portal"),
  Literal("Whistic Portal"),
  Literal("Excel Sheet Attachment"),
  Literal("Doc Attachment"),
  Literal("PDF Attachment"),
  Literal("In email"),
  Literal("Online Form"),
  Literal("Google Sheet"),
  Literal("ServiceNow"),
  Literal("CyberGRX"),
  Literal("Google Doc"),
  Literal("SureCloud"),
  Literal("Panorays"),
  Literal("Certa"),
  Literal("Prevalent"),
  Literal("Venminder"),
  Literal("ProcessUnity"),
  Literal("Full SIG"),
  Literal("SIG Lite"),
  Literal("ZenGRC"),
  Literal("CAIQ"),
  Literal("Tugboat"),
  Literal("Aptible"),
  Literal("Alyne"),
  Literal("Onspring"),
  Literal("Security Scorecard"),
  Literal("Archer"),
  Literal("UpGuard"),
  Literal("HECVAT"),
  Literal("Dropbox"),
  Literal("CAIQ Lite"),
  Literal("SIG Core"),
  Literal("LogicGate"),
  Literal("GraphiteConnect"),
  Literal("Coupa"),
  Literal("VSAQ"),
  Literal("Atlassian"),
  Literal("CVAT"),
  Literal("Pages"),
  Literal("Rsam"),
  Literal("Amazon Portal"),
  Literal("Third Party Trust"),
  Literal("HECVAT-Lite"),
  Literal("auditboard portal"),
  Literal("Compass Portal"),
  Literal("Google Doc Survey (Portal)"),
  Literal("Facebook portal"),
  Literal("Force Portal"),
  Literal("Coupa/Hiperos"),
  Literal("Coupa/Hiperos portal"),
  Literal("Aravo Portal"),
  Literal("Exterro Portal"),
  Literal("Privva Portal"),
  Literal("Terms and Conditions"),
  Literal("KnowBe4 Portal"),
  Literal("Keylight Portal"),
  Literal("MasterCard Portal"),
  Literal("Smartsheet"),
  Literal("OmniCom Portal"),
  Literal("Workday Portal"),
  Literal("typeform Portal"),
  Literal("Microsoft Portal"),
  Literal("Aris Portal"),
  Literal("snowflake portal"),
  Literal("ky3p"),
  Literal("zip portal"),
  Literal("Brinqa Portal"),
  Literal("TrustArc/Truste"),
  Literal("nuxeo"),
  Literal("security studio"),
  Literal("ibpm"),
  Literal("concordnow"),
  Literal("Communisis portal"),
  Literal("CF Portal"),
  Literal("Avertro"),
  Literal("Hellios"),
  Literal("Appspot"),
  Literal("Scout RFP"),
  Literal("Allgress"),
  Literal("Qualcomm"),
  Literal("Docusign"),
  Literal("Rescana"),
  Literal("Akamai"),
  Literal("booking.com"),
  Literal("LogicManager"),
  Literal("NiSource Portal"),
  Literal("HICX Portal"),
  Literal("HIG Portal"),
  Literal("KSM portal"),
  Literal("Quantivate"),
  Literal("wax-live"),
  Literal("SCS"),
  Literal("Cisco Portal"),
  Literal("3grc portal"),
  Literal("Cornerstone"),
  Literal("atlas"),
  Literal("APP Portal"),
  Literal("TMP"),
  Literal("svdp portal"),
  Literal("Hyperproof portal"),
  Literal("rfi"),
  Literal("Risk Ledger Portal"),
  Literal("Vanta Portal"),
  Literal("MetricStream"),
  Literal("Lockpath Portal"),
  Literal("Formstack"),
  Literal("Securimate"),
  Literal("VendorRisk"),
  Literal("pan"),
  Literal("Highbond"),
  Literal("VendorManagement"),
  Literal("HITRUST Portal"),
  Literal("Status"),
  Literal("Conveyor"),
  Literal("PWC Portal"),
  Literal("Ariba Portal"),
  Literal("CORL Tech Portal"),
  Literal("ByteDance Portal"),
  Literal("IRM Security Portal"),
  Literal("Optum Portal"),
  Literal("Nexdigm Portal"),
  Literal("Phinityrisk"),
  Literal("Ncontracts"),
  Literal("Cobra Portal"),
  Literal("VisoTrust"),
  Literal("Medius"),
  Literal("Centrl"),
  Literal("CyberVadis"),
  Literal("TechnologyRisk"),
  Literal("Navex portal"),
  Literal("Terra True Portal"),
  Literal("CVRM Portal"),
  Literal("CVENT"),
  Literal("SupplierCheck"),
  Literal("Rochester.edu"),
  Literal("VendorBolt"),
  Literal("Carnie Fulton"),
  Literal("Nextgen Control"),
  Literal("ESR-Vendorform"),
  Literal("StandardFusion"),
  Literal("ComplyAuto"),
  Literal("Procurement"),
  Literal("ThompsonRueters"),
  Literal("VISR"),
  Literal("Assured Portal"),
  Literal("DigitalRisk"),
  Literal("Abriska"),
  Literal("PPT"),
);

export type QuestionnaireTypeOption = Static<typeof QuestionnaireTypeOptionRT>;
