import { PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit";
import { privateApi } from "../API";
import { Permission } from "../API/permissions";
import { Company, CompanyId, Product, ProductId } from "../API/types";
import { sortProductsByName } from "./types";

export interface LayoutState {
  activeProduct: Product | null;
  productPermissions?: {
    [key: ProductId]: Permission[];
  };
  isDarkModeOn: boolean;
  isSidebarCollapsed: boolean;
}

const initialState = {
  activeProduct: null,
  error: null,
  isDarkModeOn: false,
  isSidebarCollapsed: false,
} as LayoutState;

export const LAYOUT_KEY = "layout";

const layoutSlice = createSlice({
  name: LAYOUT_KEY,
  initialState,
  reducers: {
    setActiveProduct(state, action: PayloadAction<Product>) {
      state.activeProduct = action.payload;
    },
    clearActiveProduct(state) {
      state.activeProduct = null;
    },
    toggleDarkMode(state) {
      state.isDarkModeOn = !state.isDarkModeOn;
    },
    toggleSideBar(state) {
      state.isSidebarCollapsed = !state.isSidebarCollapsed;
    },
  },
  extraReducers(builder) {
    builder.addMatcher(
      privateApi.endpoints.getProducts.matchFulfilled,
      (state, action) => {
        const products = action.payload.data;
        if (state.activeProduct) {
          const currentId = state.activeProduct.id;
          const activeProductIndex = products.findIndex(
            (p) => p.id === currentId,
          );
          if (activeProductIndex >= 0) {
            state.activeProduct = products[activeProductIndex];
          }
        } else if (products.length >= 1) {
          state.activeProduct = products[0];
        }

        state.productPermissions = action.payload.meta?.permissions;
      },
    );
  },
});

export const {
  setActiveProduct,
  clearActiveProduct,
  toggleDarkMode,
  toggleSideBar,
} = layoutSlice.actions;

const internalSelectProducts = privateApi.endpoints.getProducts.select();

export const selectSortedProducts = createSelector(
  internalSelectProducts,
  (products) =>
    products?.data?.data?.map((c: Product) => c)?.sort(sortProductsByName) ||
    [],
);

export const selectSortedCompanies = createSelector(
  internalSelectProducts,
  (products) => {
    const companies =
      products?.data?.data?.map((c: Product) => c.relationships.company.data) ||
      [];
    const idsToCompanies: Record<CompanyId, Company> = {};
    companies.forEach((c) => {
      idsToCompanies[c.id] = c;
    });
    return Object.values(idsToCompanies).sort((a, b) =>
      a.attributes.displayName.localeCompare(b.attributes.displayName),
    );
  },
);

export default layoutSlice.reducer;
