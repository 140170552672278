// biome-ignore lint/suspicious/noExplicitAny: spread props that could apply to this element
export const BellIcon = (rest: any) => (
  <svg
    width="103"
    height="96"
    viewBox="0 0 103 96"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M4 83.403C4 81.0474 4.95666 78.7886 6.65951 77.1226C8.36237 75.457 10.6719 74.5211 13.0801 74.5211H85.7209C88.129 74.5211 90.4387 75.457 92.1415 77.1226C93.8443 78.7886 94.801 81.0474 94.801 83.403V92.2849H4V83.403Z"
      stroke="currentColor"
      strokeWidth="6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M85.721 74.5211C85.721 65.0989 81.8944 56.0622 75.0829 49.3994C68.2719 42.7366 59.0336 38.9937 49.4006 38.9937C39.7679 38.9937 30.5297 42.7366 23.7183 49.3994C16.9069 56.0622 13.0803 65.0989 13.0803 74.5211"
      stroke="currentColor"
      strokeWidth="6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M49.4005 21.2303V38.9941"
      stroke="currentColor"
      strokeWidth="6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M40.3204 21.2303H58.4807"
      stroke="currentColor"
      strokeWidth="6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M88.6979 3.92547L81.4206 3.14035C80.994 3.09416 80.5897 3.3383 80.44 3.7325L74.2584 20.0527C74.0462 20.6114 74.4282 21.2159 75.0337 21.2812L82.2154 22.0563C82.803 22.1196 83.1842 22.6926 83.0055 23.2436L78.6937 36.5361C78.3532 37.5853 79.8471 38.2221 80.4011 37.2641L93.2101 15.1119C93.5415 14.5395 93.1662 13.8227 92.498 13.7506L85.906 13.0392C85.214 12.9646 84.8444 12.2039 85.2217 11.6321L89.3816 5.33281C89.7595 4.76083 89.3899 4.00011 88.6979 3.92547Z"
      fill="currentColor"
    />
  </svg>
);
