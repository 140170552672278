import { skipToken } from "@reduxjs/toolkit/query";
import { Undefined } from "runtypes";
import { privateApi } from ".";
import { APIEndpoints } from "../../utils/constants";
import { useAppSelector } from "../../utils/hooks";
import {
  CheckAuthenticationResult,
  CheckAuthenticationResultRT,
} from "../Authentication/types";
import { USER_AUTH_KEY } from "../UserInfo";
import { UserAuthData, UserAuthDataRT, UserData } from "../UserInfo/types";
import {
  AccessGroupId,
  AllRtkQueryTags,
  Company,
  CompanyRT,
  SimpleUser,
  SimpleUserRT,
  UserId,
} from "./types";
import { mutationEndpointBuilder, queryEndpointBuilder } from "./utils";

const authApi = privateApi.injectEndpoints({
  endpoints: (builder) => ({
    login: mutationEndpointBuilder<
      void,
      UserAuthData,
      { email: string; password: string }
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: UserAuthDataRT,
      body: (params) => params,
      url: () => APIEndpoints.auth.LOGIN,
      extraOptions: {
        unauthed: true,
      },
    }),
    logout: builder.mutation<
      void,
      { refresh?: string; partialAuthToken?: string }
    >({
      query: (params) => ({
        url: APIEndpoints.auth.LOGOUT,
        method: "POST",
        body: params,
      }),
      extraOptions: {
        unauthed: true,
      },
    }),
    register: builder.mutation<
      UserAuthData,
      { uidb64: string; token: string; password: string; name: string }
    >({
      query: ({ uidb64, token, password, name }) => ({
        url: `${APIEndpoints.auth.REGISTER}/${uidb64}/${token}`,
        method: "POST",
        body: { password, name },
      }),
      extraOptions: {
        unauthed: true,
      },
    }),
    resetPassword: builder.mutation<
      void,
      { uidb64: string; token: string; password: string }
    >({
      query: ({ uidb64, token, password }) => ({
        url: `${APIEndpoints.auth.RESET_PASSWORD}/${uidb64}/${token}`,
        method: "POST",
        body: { password },
      }),
      extraOptions: {
        unauthed: true,
      },
    }),
    sendResetCode: builder.mutation<void, string>({
      query: (email) => ({
        url: APIEndpoints.auth.SEND_RESET_CODE,
        method: "POST",
        body: { email },
      }),
      extraOptions: {
        unauthed: true,
      },
    }),
    updatePassword: mutationEndpointBuilder<
      void,
      void,
      { currentPassword: string; newPassword: string }
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: Undefined,
      url: () => APIEndpoints.auth.UPDATE_PASSWORD,
      body: (params) => params,
    }),
    enrollMFA: builder.mutation<
      { qrcode: string },
      { partialAuthToken?: string; refresh?: string }
    >({
      query: (params) => ({
        url: APIEndpoints.auth.ENROLL_MFA,
        method: "POST",
        body: params,
      }),
      extraOptions: {
        unauthed: true,
      },
      invalidatesTags: ["MeData"],
    }),
    unenrollMFA: builder.mutation<{ isEnrolledInMFA: boolean }, void>({
      query: () => ({
        url: APIEndpoints.auth.UNENROLL_MFA,
        method: "POST",
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          authApi.util.updateQueryData("getMe", undefined, (draft) => {
            Object.assign(draft, { isEnrolledInMFA: false });
          }),
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
      invalidatesTags: ["MeData"],
    }),
    validateMFA: mutationEndpointBuilder<
      void,
      UserAuthData,
      { mfaCode: string; partialAuthToken?: string; refresh?: string }
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: UserAuthDataRT,
      url: () => APIEndpoints.auth.VALIDATE_MFA,
      body: (params) => params,
      invalidatesTags: ["MeData"],
      extraOptions: { unauthed: true },
    }),
    enforceMFA: builder.mutation<Company, { enforceMfa: boolean }>({
      query: (params) => ({
        url: APIEndpoints.auth.ENFORCE_MFA,
        method: "POST",
        body: params,
      }),
      async onQueryStarted(params, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          authApi.util.updateQueryData(
            "getCompanySettings",
            undefined,
            (draft) => {
              Object.assign(draft, { mfaEnforced: params.enforceMfa });
            },
          ),
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
      invalidatesTags: ["CompanySettings"],
    }),
    getCompanySettings: builder.query<
      { mfaEnforced: boolean; autoActivateNewUsers: boolean },
      void
    >({
      query: () => ({
        url: APIEndpoints.auth.GET_COMPANY_SETTINGS,
        method: "GET",
      }),
      providesTags: ["CompanySettings"],
    }),
    getMe: builder.query<UserData, void>({
      query: () => ({
        url: APIEndpoints.auth.GET_ME,
        method: "GET",
      }),
      providesTags: ["MeData"],
    }),
    checkAuthProvider: queryEndpointBuilder<
      void,
      CheckAuthenticationResult,
      string
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: CheckAuthenticationResultRT,
      url: (email: string) =>
        `${APIEndpoints.auth.CHECK_AUTH_PROVIDER}?email=${encodeURIComponent(
          email,
        )}`,
      extraOptions: {
        unauthed: true,
      },
    }),
    updateAutoActivateNewUsers: mutationEndpointBuilder<
      void,
      Company,
      { enabled: boolean; accessGroupId?: AccessGroupId }
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: CompanyRT,
      url: () => APIEndpoints.auth.AUTO_ACTIVATE_NEW_USERS,
      body: (params) => params,
      invalidatesTags: ["CompanySettings", "AccessGroup"],
    }),
    ssoCallback: mutationEndpointBuilder<
      void,
      UserAuthData,
      { code: string; email: string | null }
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: UserAuthDataRT,
      url: () => APIEndpoints.auth.SSO_CALLBACK,
      body: (params) => params,
      extraOptions: { unauthed: true },
    }),
    clearImpersonatedUser: mutationEndpointBuilder<void, void, void>({
      builder,
      metaRuntype: Undefined,
      dataRuntype: Undefined,
      url: () => APIEndpoints.auth.CLEAR_IMPERSONATED_USER,
      body: () => ({}),
      invalidatesTags: AllRtkQueryTags,
    }),
    impersonateUser: mutationEndpointBuilder<void, void, UserId>({
      builder,
      metaRuntype: Undefined,
      dataRuntype: Undefined,
      url: () => APIEndpoints.auth.IMPERSONATE_USER,
      body: (userId) => ({ userToImpersonateId: userId }),
      invalidatesTags: AllRtkQueryTags,
    }),
    currentlyImpersonating: queryEndpointBuilder<
      void,
      SimpleUser | undefined,
      void
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: SimpleUserRT.Or(Undefined),
      url: () => APIEndpoints.auth.CURRENTLY_IMPERSONATING,
      providesTags: ["Impersonating"],
    }),
  }),
  overrideExisting: false,
});

// If user is not fully authed, hitting getMe will return a 401 which will log the user out.
// this wrapper ensures we wait until the user is fully authed before requesting data
export const useGetMeQuery = () => {
  const authData = useAppSelector((state) => state[USER_AUTH_KEY].userAuthData);
  const isFullyAuthed = authData?.authResultType === "full";
  return authApi.useGetMeQuery(isFullyAuthed ? undefined : skipToken);
};

export const useGetMyCompany = () => {
  const { data: userData } = useGetMeQuery();
  return userData?.user?.relationships?.company;
};

export const {
  useLoginMutation,
  useLogoutMutation,
  useRegisterMutation,
  useResetPasswordMutation,
  useSendResetCodeMutation,
  useUpdatePasswordMutation,
  useEnrollMFAMutation,
  useUnenrollMFAMutation,
  useValidateMFAMutation,
  useEnforceMFAMutation,
  useGetCompanySettingsQuery,
  useUpdateAutoActivateNewUsersMutation,
  useLazyCheckAuthProviderQuery,
  useSsoCallbackMutation,
  useClearImpersonatedUserMutation,
  useImpersonateUserMutation,
  useCurrentlyImpersonatingQuery,
} = authApi;

export default authApi;
