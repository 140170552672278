/// <reference types="vite-plugin-svgr/client" />
import Wordmark from "../assets/images/security-pal-logo-word-mark.svg?react";
import styles from "./Footer.module.css";

const ProfileFooter = (): JSX.Element => {
  return (
    <div className={styles.Background}>
      Managed by
      <a
        href="https://securitypalhq.com"
        target="_blank"
        rel="noreferrer"
        aria-label="SecurityPal - opens website in a new tab."
      >
        <Wordmark className={styles.Wordmark} />
      </a>
    </div>
  );
};

export default ProfileFooter;
