export const loadReduxState = (): Record<string, unknown> | undefined => {
  try {
    const serializedState = localStorage.getItem("state");
    if (!serializedState) return undefined;

    return JSON.parse(serializedState);
  } catch (_error) {
    return undefined;
  }
};

export const saveState = (state: Record<string, unknown>): boolean => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("state", serializedState);
    return true;
  } catch (_error) {
    return false;
  }
};
