import { AutoComplete, AutoCompleteProps } from "antd";
import { answerList } from "../../utils/constants";
import { AnswerBubble } from "../AnswerDetails";

const AnswerSelect = ({ value, onChange, ...rest }: AutoCompleteProps) => {
  const options = answerList.map((answerValue) => ({
    value: answerValue,
    label: answerValue,
  }));

  return (
    <AutoComplete
      value={value}
      {...rest}
      maxLength={20}
      onChange={(val, option) => onChange?.(val, option)}
    >
      {options.map((option) => (
        <AutoComplete.Option key={option.value} value={option.value}>
          <AnswerBubble answer={option.value} />
        </AutoComplete.Option>
      ))}
    </AutoComplete>
  );
};

export default AnswerSelect;
