import { v4 as uuidv4 } from "uuid";
import MagicLinkTokenHandler from "../containers/PrivateDataRoomFlowContainer/token";
import store from "../store";
import { clearLoggedInState } from "../store/clearLoggedInState";
import TokenHandler from "../utils/token";
import { X_REQUEST_ID_HEADER_NAME } from "./constants";

export const uncachedFetch = async (
  input: RequestInfo,
  init?: RequestInit,
  multipart?: boolean,
): Promise<Response> => {
  const params = { ...(init || {}) };
  params.headers = {
    [X_REQUEST_ID_HEADER_NAME]: uuidv4(),
    ...(params.headers || {}),
    ...(multipart
      ? await TokenHandler.authHeadersForMultipartFormData()
      : await TokenHandler.authHeaders()),
  };
  const r = await fetch(input, params);
  if (r.status === 401) {
    store.dispatch(clearLoggedInState());
  }
  return r;
};

export const magicLinkUncachedFetch = async (
  input: RequestInfo,
): Promise<Response> => {
  const accessToken = await MagicLinkTokenHandler.loadToken()?.accessToken;
  const params = {
    headers: {
      [X_REQUEST_ID_HEADER_NAME]: uuidv4(),
      authorization: `Bearer ${accessToken}`,
    },
  };

  const r = await fetch(input, params);
  if (r.status === 401) {
    MagicLinkTokenHandler.clearToken();
  }
  return r;
};
