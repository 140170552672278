import { Array, Undefined } from "runtypes";
import { HexColor } from "shared_frontend/src/types/colors";
import { privateApi } from "..";
import { APIEndpoints } from "../../../utils/constants";
import { Label, LabelId, LabelRT } from "../../MissionControl/types/labels";
import { mutationEndpointBuilder, queryEndpointBuilder } from "../utils";

const missionControlLabelsApi = privateApi.injectEndpoints({
  endpoints: (builder) => ({
    getLabels: queryEndpointBuilder<void, Label[], void>({
      builder,
      metaRuntype: Undefined,
      dataRuntype: Array(LabelRT),
      url: () => APIEndpoints.missionControl.GET_LABELS,
      providesTags: ["Label"],
    }),
    createLabel: mutationEndpointBuilder<
      void,
      Label,
      {
        name: string;
        color?: { textColor: HexColor; backgroundColor: HexColor };
        parentLabelId?: LabelId;
      }
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: LabelRT,
      url: () => APIEndpoints.missionControl.CREATE_LABEL,
      body: (params) => params,
      invalidatesTags: ["Label"],
    }),
    editLabel: mutationEndpointBuilder<
      void,
      Label,
      {
        id: LabelId;
        name?: string;
        color?: { textColor: HexColor; backgroundColor: HexColor };
        parentLabelId?: LabelId;
      }
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: LabelRT,
      url: () => APIEndpoints.missionControl.EDIT_LABEL,
      body: (params) => params,
      invalidatesTags: ["Label"],
    }),
    deleteLabel: mutationEndpointBuilder<void, void, { id: LabelId }>({
      builder,
      metaRuntype: Undefined,
      dataRuntype: Undefined,
      url: () => APIEndpoints.missionControl.DELETE_LABEL,
      body: (params) => params,
      invalidatesTags: ["Label"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetLabelsQuery,
  useCreateLabelMutation,
  useEditLabelMutation,
  useDeleteLabelMutation,
  util: labelUtil,
} = missionControlLabelsApi;

export default missionControlLabelsApi;
