import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export const PREAUTH_KEY = "preAuth";

interface PreAuthState {
  email: string | undefined;
}

const initialState = {
  email: undefined,
} as PreAuthState;

const preAuthSlice = createSlice({
  name: PREAUTH_KEY,
  initialState,
  reducers: {
    setEmail: (state, action: PayloadAction<string | undefined>) => {
      state.email = action.payload;
    },
  },
});

export const { setEmail } = preAuthSlice.actions;
export default preAuthSlice.reducer;
