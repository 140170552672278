/* Implements useful wrappers for analytics */
import { RcFile } from "antd/lib/upload";
import { CompanyId, ProductId } from "./features/API/types";
import { CopilotAnswerRequest } from "./features/Copilot/types";
import { DashboardFilters } from "./features/Dashboard/types";
import {
  AnswerLibraryEntry,
  AnswerLibraryEntryId,
  CanonicalQuestionId,
  Document,
  DocumentId,
  DocumentVersion,
  KnowledgeLibraryEntityType,
  KnowledgeLibraryTag,
} from "./features/KnowledgeLibrary/types";
import { GmailMessageId } from "./features/MissionControl/types/gmail";
import { QuestionnaireTaskId } from "./features/MissionControl/types/tasks";
import { QuestionnaireSession } from "./features/Questionnaires/types";
import { UserData } from "./features/UserInfo/types";
export const initialize = (): void => {
  initializeSegment();
};

const initializeSegment = (): void => {
  if (import.meta.env.MODE !== "development") {
    if (window.ENV_VARIABLE_SEGMENT_FRONTEND_WRITE_KEY) {
      window.analytics.load(window.ENV_VARIABLE_SEGMENT_FRONTEND_WRITE_KEY);
      window.analytics.page();
    }
  }
};

const identifySegment = ({ user, analyticsUUID }: UserData): void => {
  if (import.meta.env.MODE !== "development") {
    window.analytics.identify(analyticsUUID, {
      email: user.attributes.email,
      name: user.attributes.name,
      companyId: user.relationships?.company.id,
    });
  }
};

export const identify = (userData: UserData): void => {
  if (import.meta.env.MODE === "development") {
    // biome-ignore lint/suspicious/noConsoleLog:
    console.log(`[Analytics]: Identifying ${userData.user.attributes.email}`);
    return;
  }
  identifySegment(userData);
};

enum SegmentEventName {
  // Answer Library
  SEARCH_ANSWER_LIBRARY = "search_answer_library",
  VIEW_VERSION_HISTORY = "view_version_history",
  COPY_ANSWER_LIBRARY_ENTRY = "copy_answer_library_entry",
  ADD_ANSWER_LIBRARY_ENTRY = "add_answer_library_entry",
  UPDATE_ANSWER_LIBRARY_ENTRY = "update_answer_library_entry",
  SUGGEST_UPDATE_ANSWER_LIBRARY_ENTRY = "suggest_update_answer_library_entry",
  DOWNLOAD_ANSWER_LIBRARY_CSV = "download_answer_library_csv",
  // Documents
  SEARCH_SECURITY_DOCUMENTS = "search_security_documents",
  BULK_UPLOAD_SECURITY_DOCUMENTS = "bulk_upload_security_documents",
  VIEW_SECURITY_DOCUMENT = "view_security_document",
  DELETE_SECURITY_DOCUMENT = "delete_security_document",
  DOWNLOAD_SECURITY_DOCUMENT = "download_security_document",
  CREATE_DOCUMENT_VERSION = "create_document_version",
  DELETE_DOCUMENT_VERSION = "delete_document_version",
  // Answer Library / Document Review
  ASSIGN_LIBRARY_OWNERS = "assign_library_owners",
  ADD_LIBRARY_REVIEW = "add_library_review",
  UPDATE_LIBRARY_REVIEW_CYCLE = "update_library_review_cycle",

  BULK_ASSIGN_LIBRARY_OWNERS = "bulk_assign_library_owners",
  BULK_ADD_LIBRARY_REVIEW = "bulk_add_library_review",
  BULK_UPDATE_LIBRARY_REVIEW_CYCLE = "bulk_update_library_review_cycle",
  // Knowledge Library Tagging
  CREATE_LIBRARY_TAG = "create_library_tag",
  EDIT_LIBRARY_TAG = "edit_library_tag",
  DELETE_LIBRARY_TAG = "delete_library_tag",
  BULK_UPLOAD_LIBRARY_TAGS = "bulk_upload_library_tags",
  // Questionnaire Wizard
  QUESTION_DISPLAYED = "question_displayed",
  QUESTION_EXITED = "question_exited",
  ANSWERED_FROM_SUGGESTIONS = "answered_from_suggestions",
  ANSWERED_FROM_SEARCH = "answered_from_search",
  //Dashboard
  FILTER_DASHBOARD = "filter_dashboard",
  EXPORT_DASHBOARD_CSV = "export_dashboard_csv",
  SUBMIT_QUESTIONNAIRE = "dashboard_submit_questionnaire",
  VIEW_QUESTIONNAIRE_DETAIL = "view_questionnaire_detail",

  //Homepage
  QUESTIONNAIRES_REQUIRING_ATTENTION = "questionnaires_requiring_attention",
  KL_ENTRIES_REQUIRING_ATTENTION = "kl_entries_requiring_attention",
  DOCUMENTS_REQUIRING_ATTENTION = "documents_requiring_attention",

  //Questionnaire Detail
  QUESTIONNAIRE_DETAIL_UPLOAD_FINAL_VERSION = "questionnaire_detail_upload_final_version",
  QUESTIONNAIRE_DETAIL_SEE_FULL_MESSAGE = "questionnaire_detail_see_full_message",

  // Copilot
  COPY_COPILOT_ANSWER = "copy_copilot_answer",
  // Other..
  DIFF_QUESTIONNAIRES = "diff_questionnaires",
  IGNORE_MISPELLED_WORD = "ignore_mispelled_word",
  VIEW_REFER_PAGE = "view_refer_page",
}
type SegmentEventProperties = Record<string, unknown>;

interface SegmentEventProps {
  name: SegmentEventName;
  segment: SegmentEventProperties;
}

const trackEvent = ({ name, segment }: SegmentEventProps): void => {
  trackSegment({
    name: name,
    properties: segment,
  });
};

interface TrackSegmentProps {
  name: SegmentEventName;
  properties: SegmentEventProperties;
}
const trackSegment = (ev: TrackSegmentProps): void => {
  if (import.meta.env.MODE !== "development") {
    window.analytics.track(ev.name, ev.properties);
  } else {
    // biome-ignore lint/suspicious/noConsoleLog:
    console.log(
      `[Analytics]: Tracking ${ev.name}, ${JSON.stringify(ev.properties)}`,
    );
  }
};

export const trackQuestionnairesRequiringAttention = (
  productId: ProductId,
): void => {
  trackEvent({
    name: SegmentEventName.QUESTIONNAIRES_REQUIRING_ATTENTION,
    segment: {
      productId,
    },
  });
};

export const trackKlEntriesRequiringAttention = (
  productId: ProductId,
): void => {
  trackEvent({
    name: SegmentEventName.KL_ENTRIES_REQUIRING_ATTENTION,
    segment: {
      productId,
    },
  });
};
export const trackDocumentsRequiringAttention = (
  productId: ProductId,
): void => {
  trackEvent({
    name: SegmentEventName.DOCUMENTS_REQUIRING_ATTENTION,
    segment: {
      productId,
    },
  });
};

export const trackSearchAnswerLibrary = (
  query: string,
  productId: ProductId,
): void => {
  trackEvent({
    name: SegmentEventName.SEARCH_ANSWER_LIBRARY,
    segment: {
      query,
      productId,
    },
  });
};

export const trackSearchSecurityDocuments = (
  query: string,
  productId: ProductId,
): void => {
  trackEvent({
    name: SegmentEventName.SEARCH_SECURITY_DOCUMENTS,
    segment: {
      query,
      productId,
    },
  });
};

const getAnswerLibraryEntryTrackingProperties = ({
  id,
  attributes,
}: AnswerLibraryEntry) => ({
  entryId: id,
  canonicalQuestionId: attributes.canonicalQuestionId,
});

export const trackCopyAnswerLibraryEntry = (
  entry: AnswerLibraryEntry,
): void => {
  const properties = getAnswerLibraryEntryTrackingProperties(entry);
  trackEvent({
    name: SegmentEventName.COPY_ANSWER_LIBRARY_ENTRY,
    segment: properties,
  });
};

export const trackAddAnswerLibraryEntry = (entry: AnswerLibraryEntry): void => {
  const properties = getAnswerLibraryEntryTrackingProperties(entry);
  trackEvent({
    name: SegmentEventName.ADD_ANSWER_LIBRARY_ENTRY,
    segment: properties,
  });
};

export const trackUpdateAnswerLibraryEntry = (
  entry: AnswerLibraryEntry,
): void => {
  const properties = getAnswerLibraryEntryTrackingProperties(entry);
  trackEvent({
    name: SegmentEventName.UPDATE_ANSWER_LIBRARY_ENTRY,
    segment: properties,
  });
};

export const trackSuggestUpdateAnswerLibraryEntry = (
  entry: AnswerLibraryEntry,
): void => {
  const properties = getAnswerLibraryEntryTrackingProperties(entry);
  trackEvent({
    name: SegmentEventName.SUGGEST_UPDATE_ANSWER_LIBRARY_ENTRY,
    segment: properties,
  });
};

// Answer Library / Document Review
export const trackAssignLibraryOwners = (
  entityId: CanonicalQuestionId | DocumentId,
  entityType: KnowledgeLibraryEntityType,
): void => {
  trackEvent({
    name: SegmentEventName.ASSIGN_LIBRARY_OWNERS,
    segment: { entityId, entityType },
  });
};

export const trackAddLibraryReview = (
  entityId: CanonicalQuestionId | DocumentId,
  entityType: KnowledgeLibraryEntityType,
): void => {
  trackEvent({
    name: SegmentEventName.ADD_LIBRARY_REVIEW,
    segment: { entityId, entityType },
  });
};

export const trackUpdateLibraryReviewCycle = (
  entityId: CanonicalQuestionId | DocumentId,
  entityType: KnowledgeLibraryEntityType,
): void => {
  trackEvent({
    name: SegmentEventName.UPDATE_LIBRARY_REVIEW_CYCLE,
    segment: { entityId, entityType },
  });
};

export const trackBulkAssignLibraryOwners = (
  entityType: KnowledgeLibraryEntityType,
): void => {
  trackEvent({
    name: SegmentEventName.BULK_ASSIGN_LIBRARY_OWNERS,
    segment: { entityType },
  });
};

export const trackBulkUpdateLibraryReviewCycle = (
  productId: ProductId,
  entityType: KnowledgeLibraryEntityType,
): void => {
  trackEvent({
    name: SegmentEventName.UPDATE_LIBRARY_REVIEW_CYCLE,
    segment: { productId, entityType },
  });
};

const getSecurityDocumentTrackingProperties = ({ id }: Document) => ({
  documentId: id,
});

const getSecurityDocumentVersionTrackingProperties = (
  { id: docId }: Document,
  { id: versionId }: DocumentVersion,
) => ({
  documentId: docId,
  versionId: versionId,
});

export const trackBulkUploadSecurityDocuments = (): void => {
  trackEvent({
    name: SegmentEventName.BULK_UPLOAD_SECURITY_DOCUMENTS,
    segment: {},
  });
};

export const trackViewSecurityDocument = (doc: Document): void => {
  const properties = getSecurityDocumentTrackingProperties(doc);
  trackEvent({
    name: SegmentEventName.VIEW_SECURITY_DOCUMENT,
    segment: properties,
  });
};

export const trackDeleteSecurityDocument = (doc: Document): void => {
  const properties = getSecurityDocumentTrackingProperties(doc);
  trackEvent({
    name: SegmentEventName.DELETE_SECURITY_DOCUMENT,
    segment: properties,
  });
};

export const trackDownloadSecurityDocument = (doc: Document): void => {
  const properties = getSecurityDocumentTrackingProperties(doc);
  trackEvent({
    name: SegmentEventName.DOWNLOAD_SECURITY_DOCUMENT,
    segment: properties,
  });
};

export const trackCreateSecurityDocumentVersion = (
  version: DocumentVersion,
  doc: Document,
): void => {
  const properties = getSecurityDocumentVersionTrackingProperties(doc, version);
  trackEvent({
    name: SegmentEventName.CREATE_DOCUMENT_VERSION,
    segment: properties,
  });
};

export const trackDeleteSecurityDocumentVersion = (
  version: DocumentVersion,
  doc: Document,
): void => {
  const properties = getSecurityDocumentVersionTrackingProperties(doc, version);
  trackEvent({
    name: SegmentEventName.DELETE_DOCUMENT_VERSION,
    segment: properties,
  });
};

const _getLibraryTagProperties = (
  productId: ProductId,
  tag: KnowledgeLibraryTag,
) => ({
  productId,
  tagId: tag.id,
  tagName: tag.attributes.name,
});

export const trackCreateLibraryTag = (
  productId: ProductId,
  tag: KnowledgeLibraryTag,
): void => {
  const properties = _getLibraryTagProperties(productId, tag);
  trackEvent({
    name: SegmentEventName.CREATE_LIBRARY_TAG,
    segment: properties,
  });
};

export const trackEditLibraryTag = (
  productId: ProductId,
  newTag: KnowledgeLibraryTag,
): void => {
  const properties = _getLibraryTagProperties(productId, newTag);
  trackEvent({
    name: SegmentEventName.EDIT_LIBRARY_TAG,
    segment: properties,
  });
};

export const trackDeleteLibraryTag = (
  productId: ProductId,
  oldTag: KnowledgeLibraryTag,
): void => {
  const properties = _getLibraryTagProperties(productId, oldTag);
  trackEvent({
    name: SegmentEventName.DELETE_LIBRARY_TAG,
    segment: properties,
  });
};

export const trackBulkUploadLibraryTags = (productId: ProductId): void => {
  trackEvent({
    name: SegmentEventName.BULK_UPLOAD_LIBRARY_TAGS,
    segment: {
      productId,
    },
  });
};

export const trackDiffQuestionnaires = (
  originalFile: RcFile,
  updatedFile: RcFile,
): void => {
  trackEvent({
    name: SegmentEventName.DIFF_QUESTIONNAIRES,
    segment: {
      originalFilename: originalFile.name,
      updatedFilename: updatedFile.name,
    },
  });
};

export const trackIgnoreMisspelledWord = (word: string): void => {
  trackEvent({
    name: SegmentEventName.IGNORE_MISPELLED_WORD,
    segment: { word },
  });
};

export const trackViewVersionHistory = (entry: AnswerLibraryEntry): void => {
  const properties = getAnswerLibraryEntryTrackingProperties(entry);
  trackEvent({
    name: SegmentEventName.VIEW_VERSION_HISTORY,
    segment: properties,
  });
};

export const trackQuestionDisplayed = (
  questionnaireSession: QuestionnaireSession,
): void => {
  trackEvent({
    name: SegmentEventName.QUESTION_DISPLAYED,
    segment: {
      ...questionnaireSession,
    },
  });
};

export const trackQuestionExited = (
  hasUserInput: boolean,
  isAnswered: boolean,
  questionnaireSession: QuestionnaireSession,
): void => {
  trackEvent({
    name: SegmentEventName.QUESTION_EXITED,
    segment: {
      hasUserInput,
      isAnswered,
      ...questionnaireSession,
    },
  });
};

export const trackAnsweredFromSuggestions = (
  answerID: AnswerLibraryEntryId,
  answerPreviewListIndex: number,
  searchQuery: string,
  questionnaireSession: QuestionnaireSession,
): void => {
  trackEvent({
    name: SegmentEventName.ANSWERED_FROM_SUGGESTIONS,
    segment: {
      answerID,
      answerPreviewListIndex,
      searchQuery,
      ...questionnaireSession,
    },
  });
};

export const trackAnsweredFromSearch = (
  answerID: AnswerLibraryEntryId,
  answerPreviewListIndex: number,
  searchQuery: string,
  questionnaireSession: QuestionnaireSession,
): void => {
  trackEvent({
    name: SegmentEventName.ANSWERED_FROM_SEARCH,
    segment: {
      answerID,
      answerPreviewListIndex,
      searchQuery,
      ...questionnaireSession,
    },
  });
};

export const trackFilterDashboard = (
  companyId: CompanyId,
  filterParams: DashboardFilters,
): void =>
  trackEvent({
    name: SegmentEventName.FILTER_DASHBOARD,
    segment: {
      companyId,
      filterParams,
    },
  });

export const trackExportDashboardCsv = (
  companyId: CompanyId,
  filterParams: DashboardFilters,
): void =>
  trackEvent({
    name: SegmentEventName.EXPORT_DASHBOARD_CSV,
    segment: {
      companyId,
      filterParams,
    },
  });

type CopilotAnswerRequestWithAnswer = CopilotAnswerRequest & {
  answer: string;
  source: string;
};

export const trackCopyCopilotAnswer = ({
  productId,
  question,
  instruction,
  answer,
}: CopilotAnswerRequestWithAnswer): void => {
  trackEvent({
    name: SegmentEventName.COPY_COPILOT_ANSWER,
    segment: {
      productId,
      question,
      instruction,
      answer,
    },
  });
};

export const trackSubmitQuestionnaireTask = (
  taskId: QuestionnaireTaskId,
  prospect: string,
): void => {
  trackEvent({
    name: SegmentEventName.SUBMIT_QUESTIONNAIRE,
    segment: {
      taskId,
      prospect,
    },
  });
};

export const trackViewQuestionnaireDetail = (
  questionnaireTaskId: QuestionnaireTaskId,
): void => {
  trackEvent({
    name: SegmentEventName.VIEW_QUESTIONNAIRE_DETAIL,
    segment: {
      questionnaireTaskId,
    },
  });
};

export const trackUploadFinalVersion = (
  questionnaireTaskId: QuestionnaireTaskId,
): void => {
  trackEvent({
    name: SegmentEventName.QUESTIONNAIRE_DETAIL_UPLOAD_FINAL_VERSION,
    segment: {
      questionnaireTaskId,
    },
  });
};

export const trackSeeFullMessage = (
  questionnaireTaskId: QuestionnaireTaskId,
  messageId: GmailMessageId,
): void => {
  trackEvent({
    name: SegmentEventName.QUESTIONNAIRE_DETAIL_SEE_FULL_MESSAGE,
    segment: {
      questionnaireTaskId,
      messageId,
    },
  });
};

export const trackViewReferPage = (companyId: CompanyId | undefined): void => {
  trackEvent({
    name: SegmentEventName.VIEW_REFER_PAGE,
    segment: {
      companyId,
    },
  });
};
