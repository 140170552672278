import jwt_decode from "jwt-decode";
import {
  MagicLinkAuthToken,
  MagicLinkAuthTokenRT,
} from "../../features/AssuranceProfile/types";

const LOCAL_STORAGE_KEY = "privateDataRoom.tokens";

type JWT = {
  exp: number;
  iat: number;
  jti: string;
  aud: string; // user email
};

const loadAndParseToken = (): MagicLinkAuthToken | null => {
  try {
    const serializedState = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (!serializedState) return null;

    const parsedData = JSON.parse(serializedState);
    if (MagicLinkAuthTokenRT.guard(parsedData)) {
      return parsedData;
    }
    return null;
  } catch {
    return null;
  }
};

const storeToken = (token: MagicLinkAuthToken | null): void => {
  if (token) {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(token));
  } else {
    removeToken();
  }
};

const removeToken = (): void => {
  localStorage.removeItem(LOCAL_STORAGE_KEY);
};

interface TokenWithExpiry {
  token: MagicLinkAuthToken;
  accessTokenExpiryTime: number;
  email: string;
}

class MagicLinkTokenHandler {
  private static tokenWithExpiry: TokenWithExpiry | null = null;

  static clearToken(): void {
    this.tokenWithExpiry = null;
    this.saveToken();
  }

  static loadToken(): MagicLinkAuthToken | null {
    const token = loadAndParseToken();
    if (token) {
      this.updateToken(token);
      return token;
    }
    return null;
  }

  static getEmail(): string | null {
    return this.tokenWithExpiry?.email ?? null;
  }

  static updateToken(token: MagicLinkAuthToken): void {
    this.setToken(token);
    this.saveToken();
  }

  static isTokenExpired(): boolean {
    if (this.tokenWithExpiry === null) {
      return true;
    } else {
      return this.tokenWithExpiry.accessTokenExpiryTime < Date.now();
    }
  }

  private static setToken(token: MagicLinkAuthToken): void {
    const decodedJWT = jwt_decode<JWT>(token.accessToken);
    const accessTokenExpiryTime = (decodedJWT.exp - 30) * 1000; // Expire 30 seconds early in case of clock skew
    this.tokenWithExpiry = {
      token,
      accessTokenExpiryTime,
      email: decodedJWT.aud,
    };
  }

  private static saveToken(): void {
    storeToken(this.tokenWithExpiry?.token ?? null);
  }
}

export default MagicLinkTokenHandler;
