import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ProductId } from "../API/types";

interface NewAnswerRequestParams {
  productId: ProductId;
  question: string;
  category: string;
}

export interface AsktoAnswerState {
  showNewRequestModal: boolean;
  showViewRequestModal: boolean;
  newAnswerRequestForm: NewAnswerRequestParams;
}

const initialState = {
  showNewRequestModal: false,
  showViewRequestModal: false,
  newAnswerRequestForm: {},
} as AsktoAnswerState;

export const ASK_TO_ANSWER_KEY = "askToAnswer";

const askToAnswerSlice = createSlice({
  name: ASK_TO_ANSWER_KEY,
  initialState,
  reducers: {
    toggleNewRequestModal(state) {
      state.showNewRequestModal = !state.showNewRequestModal;
      state.newAnswerRequestForm = {} as NewAnswerRequestParams;
    },
    toggleViewRequestModal(state) {
      state.showViewRequestModal = !state.showViewRequestModal;
    },
    setNewRequestProductID(state, action: PayloadAction<ProductId>) {
      state.newAnswerRequestForm.productId = action.payload;
    },
    setNewRequestQuestion(state, action: PayloadAction<string>) {
      state.newAnswerRequestForm.question = action.payload;
    },
    setNewRequestCategory(state, action: PayloadAction<string>) {
      state.newAnswerRequestForm.category = action.payload;
    },
  },
});

export const {
  toggleNewRequestModal,
  toggleViewRequestModal,
  setNewRequestProductID,
  setNewRequestQuestion,
  setNewRequestCategory,
} = askToAnswerSlice.actions;

export default askToAnswerSlice.reducer;
