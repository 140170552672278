import { TagDescription } from "@reduxjs/toolkit/query";
import { Array, Undefined } from "runtypes";
import { privateApi } from "..";
import { APIEndpoints } from "../../../utils/constants";
import { RtkQueryTag } from "../../../utils/webSockets/generatedTypes";
import {
  DefaultRecipient,
  DefaultRecipientRT,
  RequestThreadId,
} from "../../MissionControl/types/base";
import { RequestThreadInbox } from "../../MissionControl/types/base_threads";
import { LabelId } from "../../MissionControl/types/labels";
import { GetThreadsQueryParams } from "../../MissionControl/types/schema";
import {
  RequestThread,
  RequestThreadRT,
} from "../../MissionControl/types/unions";
import { CompanyId, TotalCountMeta, TotalCountMetaRT, UserId } from "../types";
import { mutationEndpointBuilder, queryEndpointBuilder } from "../utils";

const missionControlThreadsApi = privateApi.injectEndpoints({
  endpoints: (builder) => ({
    getThreads: queryEndpointBuilder<
      TotalCountMeta,
      RequestThread[],
      GetThreadsQueryParams
    >({
      builder,
      metaRuntype: TotalCountMetaRT,
      dataRuntype: Array(RequestThreadRT),
      url: ({ searchQuery, limit, offset, ordering }) =>
        `${APIEndpoints.missionControl.GET_THREADS}?${
          searchQuery
            ? `query=${encodeURIComponent(JSON.stringify(searchQuery))}`
            : ""
        }${limit ? `&limit=${limit}` : ""}${offset ? `&offset=${offset}` : ""}${
          ordering ? `&ordering=${ordering}` : ""
        }`,
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({
                type: "Threads" as const,
                id,
              })),
              "Threads",
            ]
          : ["Threads"],
    }),
    getThread: queryEndpointBuilder<undefined, RequestThread, RequestThreadId>({
      builder,
      metaRuntype: Undefined,
      dataRuntype: RequestThreadRT,
      url: (threadId) =>
        `${APIEndpoints.missionControl.GET_THREAD}?id=${threadId}`,
      providesTags: (result) =>
        result
          ? [
              {
                type: "Threads" as const,
                id: result.data.id,
              },
              "Threads",
            ]
          : ["Threads"],
    }),
    threadSetCompany: mutationEndpointBuilder<
      undefined,
      RequestThread,
      { companyId: CompanyId | null; threadId: RequestThreadId }
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: RequestThreadRT,
      url: () => APIEndpoints.missionControl.SET_COMPANY,
      body: (params) => params,
      invalidatesTags: (_, __, arg) => [
        {
          type: "Threads",
          id: arg.threadId,
        },
      ],
    }),
    assignUser: mutationEndpointBuilder<
      undefined,
      RequestThread,
      { threadId: RequestThreadId; userId: UserId | undefined }
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: RequestThreadRT,
      url: () => APIEndpoints.missionControl.ASSIGN_USER,
      body: (params) => params,
      invalidatesTags: ["Threads"],
    }),
    updateSeen: mutationEndpointBuilder<
      void,
      void,
      { threadIds: RequestThreadId[]; seen: boolean }
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: Undefined,
      url: () => APIEndpoints.missionControl.UPDATE_SEEN,
      body: (params) => params,
    }),
    updateBookmarked: mutationEndpointBuilder<
      void,
      void,
      { threadIds: RequestThreadId[]; bookmarked: boolean }
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: Undefined,
      url: () => APIEndpoints.missionControl.UPDATE_BOOKMARKED,
      body: (params) => params,
      invalidatesTags: ["Threads"],
    }),
    updateLabels: mutationEndpointBuilder<
      void,
      void,
      {
        threadIds: RequestThreadId[];
        labelIdsToAdd: LabelId[];
        labelIdsToRemove: LabelId[];
      }
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: Undefined,
      url: () => APIEndpoints.missionControl.UPDATE_LABELS,
      body: (params) => params,
      invalidatesTags: (_, __, arg) =>
        arg.threadIds.map((id) => ({ type: "Threads", id })),
    }),
    setInbox: mutationEndpointBuilder<
      void,
      void,
      {
        threadIds: RequestThreadId[];
        inbox: RequestThreadInbox;
      }
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: Undefined,
      url: () => APIEndpoints.missionControl.SET_INBOX,
      body: (params) => params,
      invalidatesTags: (_, __, arg) => {
        const tags: TagDescription<RtkQueryTag>[] = ["InboxMetadata"];
        return tags.concat(
          arg.threadIds.map((id) => ({ type: "Threads", id })),
        );
      },
    }),
    archive: mutationEndpointBuilder<
      void,
      void,
      {
        threadIds: RequestThreadId[];
        archive: boolean;
      }
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: Undefined,
      url: () => APIEndpoints.missionControl.ARCHIVE,
      body: (params) => params,
      invalidatesTags: (_, __, arg) => {
        const tags: TagDescription<RtkQueryTag>[] = ["InboxMetadata"];
        return tags.concat(
          arg.threadIds.map((id) => ({ type: "Threads", id })),
        );
      },
    }),
    defaultRecipients: queryEndpointBuilder<
      void,
      DefaultRecipient[],
      CompanyId
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: Array(DefaultRecipientRT),
      url: (companyId) =>
        `${APIEndpoints.missionControl.DEFAULT_RECIPIENTS}?company_id=${companyId}`,
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetThreadsQuery,
  useGetThreadQuery,
  useThreadSetCompanyMutation,
  useAssignUserMutation,
  useUpdateSeenMutation,
  useUpdateBookmarkedMutation,
  useUpdateLabelsMutation,
  useSetInboxMutation,
  useArchiveMutation,
  useDefaultRecipientsQuery,
  util: threadsUtil,
} = missionControlThreadsApi;

export default missionControlThreadsApi;
