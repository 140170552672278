import {
  Array,
  Lazy,
  Literal,
  Record,
  Runtype,
  Static,
  String,
} from "runtypes";
import { HexColor, HexColorRT } from "shared_frontend/src/types/colors";

//Our id for the label
export const LabelIdRT = String.withBrand("LabelId");
export type LabelId = Static<typeof LabelIdRT>;

//We need to define this so we can have a recursive definition of the runtype
export type Label = {
  type: "label";
  id: LabelId;
  attributes: {
    name: string;
    textColor: HexColor | null;
    backgroundColor: HexColor | null;
  };
  relationships: {
    parentId: LabelId | null;
    children: Label[];
  };
};

export const LabelRT: Runtype<Label> = Lazy(() =>
  Record({
    type: Literal("label"),
    id: LabelIdRT,
    attributes: Record({
      name: String,
      textColor: HexColorRT.nullable(),
      backgroundColor: HexColorRT.nullable(),
    }),
    relationships: Record({
      parentId: LabelIdRT.nullable(),
      children: Array(LabelRT),
    }),
  }),
);
