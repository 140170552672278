import { AppProps, Divider, Typography } from "antd";
import type { SearchableItem } from ".";
import styles from "./AssuranceProfileSearch.module.scss";
import SearchResult from "./SearchResult";

interface SelectedItemProps extends AppProps {
  item: SearchableItem;
  source: string;
  status?: string | null;
  details?: string | null;
  publishedAt?: string;
  searchQuery: string;
  isDarkModeOn: boolean;
}

const { Paragraph } = Typography;

const SelectedSearchItem = ({
  item,
  source,
  status,
  details,
  publishedAt,
  searchQuery,
  isDarkModeOn,
}: SelectedItemProps): JSX.Element => {
  // https://stackoverflow.com/a/77305170/7723476
  const handleCopy = async (rich: string, plain: string) => {
    if (typeof ClipboardItem !== "undefined") {
      // New ClipboardItem API, not fully supported in Firefox.
      const html = new Blob([rich], { type: "text/html" });
      const text = new Blob([plain], { type: "text/plain" });
      const data = new ClipboardItem({
        "text/html": html,
        "text/plain": text,
      });
      await navigator.clipboard.write([data]);
    } else {
      // Fallback using the deprecated `document.execCommand`.
      // Remove once Firefox supports the new ClipboardItem API.
      const cb = (e: ClipboardEvent) => {
        e?.clipboardData?.setData?.("text/html", rich);
        e?.clipboardData?.setData?.("text/plain", plain);
        e.preventDefault();
      };
      document.addEventListener("copy", cb);
      document.execCommand("copy");
      document.removeEventListener("copy", cb);
    }
  };

  return (
    <div className={styles.SelectedItemPane}>
      <SearchResult
        item={item}
        copyable
        searchQuery={searchQuery}
        isDarkModeOn={isDarkModeOn}
        handleCopy={handleCopy}
      />

      <Divider />

      <div className={styles.SelectedItemInfo}>
        <Paragraph strong>Posture Metadata</Paragraph>
        <Paragraph>Source: {source}</Paragraph>
        {status && <Paragraph>Status: {status}</Paragraph>}
        {details && <Paragraph>Details: {details}</Paragraph>}
        {publishedAt && <Paragraph>Updated: {publishedAt}</Paragraph>}
      </div>
    </div>
  );
};

export default SelectedSearchItem;
