import { SecurityScanOutlined } from "@ant-design/icons";
import { Button, Modal, ModalProps } from "antd";
import { useState } from "react";
import CardHeader from "../CardHeader";
import {
  CertificationSelection,
  CertificationSelectionId,
} from "../types/assuranceProfile";
import CertificationSelectionsList from "./CertificationSelectionsList";
import styles from "./ViewAllCertificationsModal.module.scss";

interface ViewAllCertificationsModalProps extends ModalProps {
  certificationSelections: CertificationSelection[];
  CreateModal?: React.FC<ModalProps>;
  onMoveCertificationSelection?: (
    certificationSelectionId: CertificationSelectionId,
    direction: string,
  ) => void;
}

const ViewAllCertificationsModal = ({
  certificationSelections,
  CreateModal,
  onMoveCertificationSelection,
  ...rest
}: ViewAllCertificationsModalProps) => {
  const [
    isViewAllCertificationsModalOpen,
    setIsViewAllCertificationsModalOpen,
  ] = useState(false);

  return (
    <div>
      <Button
        type="link"
        className={styles.ViewAllButton}
        onClick={() => setIsViewAllCertificationsModalOpen(true)}
      >
        View all
      </Button>

      <Modal
        title={
          <div className={styles.CardHeader}>
            <CardHeader
              icon={<SecurityScanOutlined />}
              title="Compliance and Certifications"
              titleLevel={2}
            />
          </div>
        }
        width="50%"
        open={isViewAllCertificationsModalOpen}
        onOk={() => setIsViewAllCertificationsModalOpen(false)}
        onCancel={() => setIsViewAllCertificationsModalOpen(false)}
        footer={null}
        destroyOnClose
        {...rest}
        className={styles.Modal}
      >
        <div className={styles.CertificationSelectionsWrapper}>
          <CertificationSelectionsList
            certificationSelections={certificationSelections}
            CreateModal={CreateModal}
            onMoveCertificationSelection={onMoveCertificationSelection}
          />
        </div>
      </Modal>
    </div>
  );
};

export default ViewAllCertificationsModal;
