import { Spin, SpinProps } from "antd";

import clsx from "clsx";
import styles from "./Spinner.module.css";

type CenteredSpinnerProps = SpinProps & {
  vertical?: boolean;
};

export const CenteredSpinner = ({
  vertical = false,
  size = "large",
  spinning = true,
  children,
  ...rest
}: CenteredSpinnerProps): JSX.Element => {
  if (!spinning) {
    return <>{children}</>;
  }
  return (
    <div
      className={clsx(
        styles.CenteredSpinnerContainer,
        vertical && styles.CenteredSpinnerContainer___verticallyCentered,
      )}
    >
      <Spin size={size} spinning={spinning} {...rest}>
        {children}
      </Spin>
    </div>
  );
};
