import { EditOutlined } from "@ant-design/icons";
import { AppProps, Button, Col, Modal, Row } from "antd";
import { useState } from "react";
import {
  CreateTileItemModalProps,
  DeleteTileItemPopconfirmProps,
  EditTileItemModalProps,
} from "../AssuranceProfileWrapper";
import MoveRankButtons from "../MoveRankButtons/MoveRankButtons";
import {
  CertificationSelectionStatus,
  Tile,
  TileItem,
  TileItemId,
} from "../types/assuranceProfile";
import { statusIcon } from "../utils";
import TileItemContent from "./SeeMoreModal";
import styles from "./TileItems.module.scss";

interface TileItemsProps extends AppProps {
  tile: Tile;
  CreateModal?: React.FC<CreateTileItemModalProps>;
  EditModal?: React.FC<EditTileItemModalProps>;
  DeletePopConfirm?: React.FC<DeleteTileItemPopconfirmProps>;
  onMoveTileItem?: (tileItemId: TileItemId, direction: string) => void;
}

const TileItems = ({
  tile,
  CreateModal,
  EditModal,
  DeletePopConfirm,
  onMoveTileItem,
}: TileItemsProps): JSX.Element => {
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState<{
    [key: string]: boolean;
  }>({});
  const [currentEditingTileItem, setCurrentEditingTileItem] =
    useState<TileItem | null>(null);

  const [seeMoreTile, setSeeMoreTile] = useState<Tile>();

  const openEditModal = (tileItem: TileItem) => {
    setEditModalOpen((prevState) => ({ ...prevState, [tileItem.id]: true }));
    setCurrentEditingTileItem(tileItem);
  };

  const closeEditModal = () => {
    setEditModalOpen({});
    setCurrentEditingTileItem(null);
  };

  const tileItemsToShow = EditModal
    ? tile.relationships.tileItems
    : tile.relationships.tileItems.slice(0, 4);

  const titleSpan = EditModal ? 8 : 18;
  const actionButtonSpan = EditModal ? 8 : 0;
  const iconStatusSpan = 1; // Icon renders left side
  const textStatusSpan = 24 - titleSpan - actionButtonSpan; // Text renders right side
  const firstTileItem = tileItemsToShow[0];
  const lastTileItem = tileItemsToShow.slice(-1)[0];

  return (
    <>
      <div className={styles.TileItems_Container}>
        {tileItemsToShow.map((tileItem: TileItem) => (
          <Row className={styles.TileItem_Row} key={tileItem.id}>
            {tileItem.attributes.type === "status" && (
              <Col
                span={iconStatusSpan}
                className={styles.TileItem_StatusIcon}
                aria-label={`Status: ${tileItem.attributes.status}`}
              >
                {statusIcon(
                  tileItem.attributes.status as CertificationSelectionStatus,
                )}
              </Col>
            )}

            <Col span={titleSpan} className="TileText">
              {tileItem.attributes.title}
            </Col>

            {tileItem.attributes.type === "text" && (
              <Col
                span={textStatusSpan}
                className={styles.TileItem_StatusText}
                aria-label={`Status: ${tileItem.attributes.textValue}`}
              >
                {tileItem.attributes.textValue}
              </Col>
            )}

            <Col
              className={styles.TileItem_ActionButtons}
              span={actionButtonSpan}
            >
              {EditModal && (
                <>
                  <Button
                    className={styles.TileItem_EditButton}
                    onClick={() => openEditModal(tileItem)}
                    icon={<EditOutlined />}
                    type="text"
                  />
                  {editModalOpen[tileItem.id] &&
                    currentEditingTileItem === tileItem && (
                      <EditModal
                        open={true}
                        onCancel={closeEditModal}
                        onOk={closeEditModal}
                        tileItem={tileItem}
                      />
                    )}
                </>
              )}

              {DeletePopConfirm && (
                <DeletePopConfirm tileItemId={tileItem.id} />
              )}
              {EditModal && (
                <MoveRankButtons
                  onMoveUp={() => onMoveTileItem?.(tileItem.id, "up")}
                  onMoveDown={() => onMoveTileItem?.(tileItem.id, "down")}
                  disableUp={tileItem.id === firstTileItem?.id}
                  disableDown={tileItem.id === lastTileItem?.id}
                  vertical
                />
              )}
            </Col>
          </Row>
        ))}
        {tileItemsToShow.length > 0 && (
          <Button
            type="link"
            onClick={() => setSeeMoreTile(tile)}
            aria-label={`Open modal with more ${tile.attributes.header || ""} items`}
            className={styles.TileItem_SeeMoreButton}
          >
            See more ⟶
          </Button>
        )}
      </div>

      {CreateModal && (
        <Button
          className={styles.TileItem_CreateButton}
          onClick={() => setCreateModalOpen(true)}
        >
          Add tile item
        </Button>
      )}

      {CreateModal && (
        <CreateModal
          open={createModalOpen}
          onCancel={() => setCreateModalOpen(false)}
          onOk={() => setCreateModalOpen(false)}
          tileId={tile.id}
        />
      )}
      {seeMoreTile && (
        <Modal
          footer={null}
          open={!!seeMoreTile}
          onCancel={() => setSeeMoreTile(undefined)}
        >
          <TileItemContent tile={seeMoreTile} />
        </Modal>
      )}
    </>
  );
};

export default TileItems;
