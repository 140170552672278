import clsx from "clsx";
import React, { memo } from "react";
import {
  PiCompassLight,
  PiFileLight,
  PiToggleRightLight,
} from "react-icons/pi";
import { Link } from "react-router-dom";
import { ProfileVersion } from "../types/assuranceProfile";
import styles from "./NavBarV2.module.scss";

export type NavBarMenuItem = keyof typeof NavBarMenuItems;

export enum NavBarMenuItems {
  Overview = "Overview",
  Controls = "Controls",
  Documents = "Documents",
}

interface MenuItemProps {
  menuItem: NavBarMenuItem;
  isSelected: boolean;
  accentColor: string;
  variant: "desktop" | "mobile";
}

export const MENU_ITEMS_CONFIG = {
  [NavBarMenuItems.Overview]: {
    icon: PiCompassLight,
    path: "./",
    state: { scrollToOverview: true },
  },
  [NavBarMenuItems.Controls]: {
    icon: PiToggleRightLight,
    path: "./controls",
  },
  [NavBarMenuItems.Documents]: {
    icon: PiFileLight,
    path: "./documents",
  },
} as const;

export const filterMenuItems = (
  menuItems: NavBarMenuItem[],
  profileVersionRelationships: ProfileVersion["relationships"],
) => {
  return menuItems.filter((item) => {
    if (
      !profileVersionRelationships.tiles.length &&
      item === NavBarMenuItems.Controls
    )
      return false;
    if (
      !profileVersionRelationships.documents.length &&
      item === NavBarMenuItems.Documents
    )
      return false;
    return true;
  });
};

export const NavMenuItem = memo(
  ({ menuItem, isSelected, accentColor, variant }: MenuItemProps) => {
    const config = MENU_ITEMS_CONFIG[menuItem];
    const Icon = config.icon;
    const containerClass =
      variant === "desktop"
        ? styles.NavLinkContainer
        : styles.HamburgerNavLinkContainer;
    const iconClass =
      variant === "desktop" ? styles.NavLinkLogo : styles.HamburgerNavLinkLogo;

    return (
      <Link
        to={config.path}
        state={"state" in config ? config.state : undefined}
        aria-current={isSelected ? "page" : undefined}
        style={{
          textDecoration: "none",
          color: isSelected ? accentColor : "inherit",
        }}
        className={styles.NavMenuLink}
      >
        <div
          className={clsx(containerClass, {
            [styles.SelectedItem]: isSelected,
          })}
          style={{
            borderBottomColor: isSelected ? "transparent" : accentColor,
          }}
          role="menuitem"
          tabIndex={0}
        >
          <Icon
            className={iconClass}
            style={{
              color: isSelected ? accentColor : undefined,
            }}
          />
          <div
            style={{
              color: isSelected ? accentColor : undefined,
            }}
          >
            {menuItem}
          </div>
        </div>
      </Link>
    );
  },
);

NavMenuItem.displayName = "NavMenuItem";
