import { Tag, TagProps } from "antd";
import { HexColor } from "shared_frontend/src/types/colors";
import { getTextColorFromBackgroundColor } from "../../utils/colors/utils";
import styles from "./ColoredTag.module.scss";

interface ColoredTagProps extends TagProps {
  text: JSX.Element | string;
  backgroundColor?: HexColor | string;
}

const ColoredTag = ({
  backgroundColor,
  text,
  ...rest
}: ColoredTagProps): JSX.Element => {
  const textColor = backgroundColor
    ? getTextColorFromBackgroundColor(backgroundColor as HexColor)
    : undefined;
  const style = textColor ? { color: textColor } : undefined;
  return (
    <Tag
      className={styles.ColoredTag}
      style={style}
      color={backgroundColor}
      {...rest}
    >
      {text}
    </Tag>
  );
};

export default ColoredTag;
