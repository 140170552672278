import { UserAvatarProps } from ".";
import { User } from "../../features/API/types";

export const convertToInitial = (fullName: string): string => {
  return fullName
    .split(/\s/)
    .reduce((response, word) => (response += word.slice(0, 1)), "")
    .toLocaleUpperCase();
};

export const userToAvatarProps = (
  user: User | null,
  viewerIsExternal: boolean,
): UserAvatarProps => {
  let name: string;
  if (!user) {
    name = "Unknown";
  } else if (viewerIsExternal && user.attributes.isStaff) {
    name = "SecurityPal Concierge";
  } else {
    name = user.attributes.name;
  }
  return {
    name,
    email: user?.attributes.email ?? "unknown",
    company: user?.relationships?.company,
  };
};
