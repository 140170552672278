import {
  BaseQueryError,
  BaseQueryMeta,
} from "@reduxjs/toolkit/dist/query/baseQueryTypes";
import {
  QueryCacheLifecycleApi,
  ResultDescription,
} from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import { MutationDefinition, QueryDefinition } from "@reduxjs/toolkit/query";
import { captureException } from "@sentry/react";
import { Runtype, ValidationError } from "runtypes";
import { JSONAPI } from "../../utils/jsonAPI";
import { RtkQueryTag } from "../../utils/webSockets/generatedTypes";
import { SPBaseQueryFn, SPEndpointBuilder, SPQueryExtraOptions } from "./types";

interface QueryEndpointBuilderArgs<metaT, dataT, queryArgT> {
  builder: SPEndpointBuilder;
  metaRuntype?: Runtype<metaT>;
  dataRuntype?: Runtype<dataT>;
  url: (query: queryArgT) => string;
  onCacheEntryAdded?: (
    arg: queryArgT,
    api: QueryCacheLifecycleApi<
      queryArgT,
      SPBaseQueryFn,
      JSONAPI<metaT, dataT>,
      string
    >,
  ) => Promise<void> | void;
  providesTags?: ResultDescription<
    RtkQueryTag,
    JSONAPI<metaT, dataT>,
    queryArgT,
    BaseQueryError<SPBaseQueryFn>,
    BaseQueryMeta<SPBaseQueryFn>
  >;
  extraOptions?: SPQueryExtraOptions;
}

export const queryEndpointBuilder = <metaT, dataT, queryArgT>(
  args: QueryEndpointBuilderArgs<metaT, dataT, queryArgT>,
): QueryDefinition<
  queryArgT,
  SPBaseQueryFn,
  string,
  JSONAPI<metaT, dataT>,
  string
> => {
  type JSONResponse = JSONAPI<metaT, dataT>;
  return args.builder.query<JSONResponse, queryArgT>({
    query: (queryArg: queryArgT) => ({
      url: args.url(queryArg),
      method: "GET",
      responseHandler: (
        response: Response,
      ): Promise<JSONResponse | Record<string, unknown>> => {
        if (response.status >= 200 && response.status < 300) {
          if (response.status === 204) {
            return Promise.resolve({ meta: undefined, data: undefined });
          }
          return response.json().then((json) => checkRuntypes(json, args));
        }
        return response.json().then((json: Record<string, unknown>) => json);
      },
    }),
    onCacheEntryAdded: args.onCacheEntryAdded,
    providesTags: args.providesTags,
    extraOptions: args.extraOptions,
  });
};

interface MutationEndpointBuilderArgs<metaT, dataT, queryArgT> {
  builder: SPEndpointBuilder;
  metaRuntype: Runtype<metaT>;
  dataRuntype?: Runtype<dataT>;
  url: (query: queryArgT) => string;
  body: (query: queryArgT) => Record<string, unknown> | FormData | queryArgT;
  invalidatesTags?: ResultDescription<
    RtkQueryTag,
    JSONAPI<metaT, dataT>,
    queryArgT,
    BaseQueryError<SPBaseQueryFn>,
    BaseQueryMeta<SPBaseQueryFn>
  >;
  extraOptions?: SPQueryExtraOptions;
}

export const mutationEndpointBuilder = <metaT, dataT, queryArgT>(
  args: MutationEndpointBuilderArgs<metaT, dataT, queryArgT>,
): MutationDefinition<
  queryArgT,
  SPBaseQueryFn,
  string,
  JSONAPI<metaT, dataT>,
  string
> => {
  type JSONResponse = JSONAPI<metaT, dataT>;
  return args.builder.mutation<JSONResponse, queryArgT>({
    query: (queryArg: queryArgT) => ({
      url: args.url(queryArg),
      method: "POST",
      body: args.body(queryArg),
      responseHandler: (
        response: Response,
      ): Promise<JSONResponse | Record<string, unknown>> => {
        if (response.status >= 200 && response.status < 300) {
          if (response.status === 204) {
            return Promise.resolve({
              meta: undefined,
              data: undefined,
            });
          }
          return response.json().then((json) => checkRuntypes(json, args));
        }
        return response.json().then((json: Record<string, unknown>) => json);
      },
    }),
    invalidatesTags: args?.invalidatesTags,
    extraOptions: args?.extraOptions,
  });
};

const checkRuntypes = <metaT, dataT, queryArgT>(
  json: JSONAPI<metaT, dataT>,
  args:
    | MutationEndpointBuilderArgs<metaT, dataT, queryArgT>
    | QueryEndpointBuilderArgs<metaT, dataT, queryArgT>,
) => {
  if (
    args.extraOptions?.checkRunTypes !== undefined &&
    args.extraOptions.checkRunTypes === false
  ) {
    return json;
  }
  try {
    args.metaRuntype?.check(json.meta);
    args.dataRuntype?.check(json.data);
  } catch (err) {
    const castErr = err as ValidationError;
    if (window.ENV_VARIABLE_SENTRY_ENVIRONMENT === "dev") {
      console.error(castErr.details, castErr.code);
      // in dev, break hard if there is a type mismatch.
      throw castErr;
    } else {
      // in prod, try to continue, but still warn in sentry.
      captureException(castErr, {
        extra: { details: castErr.details, code: castErr.code },
      });
    }
  }
  return json;
};
