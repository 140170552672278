import { skipToken } from "@reduxjs/toolkit/query";
import { captureException } from "@sentry/react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import { useGetProductsQuery } from "../../features/API";
import { useGetMeQuery } from "../../features/API/auth";
import { Permission } from "../../features/API/permissions";
import {
  CompanyId,
  ProductId,
  SimpleUser,
  User,
  UserId,
  hasPermission,
} from "../../features/API/types";
import {
  useGetUsersByIdQuery,
  useGetUsersWithPermissionQuery,
} from "../../features/API/userManagement";
import {
  AnswerEntryFilterMethod,
  AnswerLibraryEntry,
  Document,
} from "../../features/KnowledgeLibrary/types";
import { MULTIPLE_LIBRARY_IDS_QUERY_KEY } from "../../features/KnowledgeLibrary/utils";
import { LAYOUT_KEY, setActiveProduct } from "../../features/Layout";
import { useActiveProduct, useAppSelector } from "../../utils/hooks";

export const useSetDefaultLibrary = (
  selectedCompanyId: CompanyId | undefined,
  setSelectedCompanyId: (companyId: CompanyId) => void,
) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { data: productsData } = useGetProductsQuery();
  const activeProduct = useActiveProduct();
  const location = useLocation();
  const dispatch = useDispatch();
  const isSettingsPage = location.pathname.endsWith("settings");

  const productIdsParam = searchParams.get(MULTIPLE_LIBRARY_IDS_QUERY_KEY);
  const productIds = productIdsParam
    ? productIdsParam.split(",").map((v) => parseInt(v))
    : null;

  useEffect(() => {
    // This effect helps to set the company and product selector to the initial state.

    if (!productsData || productsData.data.length === 0 || isSettingsPage) {
      return;
    }

    const setDefaultParams = (
      libraryIdsString: string,
      searchParams: URLSearchParams,
    ): URLSearchParams => {
      searchParams.set(MULTIPLE_LIBRARY_IDS_QUERY_KEY, libraryIdsString);
      searchParams.set("page", "1");
      searchParams.set("tagsOperator", AnswerEntryFilterMethod.and);
      return searchParams;
    };

    if (productIds) {
      //ensure company selector is set to same company
      const representativeProduct = productsData.data.find(
        (product) => product.id === productIds[0],
      );
      if (!representativeProduct) {
        return;
      }
      if (
        selectedCompanyId !==
        representativeProduct.relationships.company.data.id
      ) {
        setSelectedCompanyId(
          representativeProduct.relationships.company.data.id,
        );
      }
      // set an active product so if you navigate to copilot you will be on the same product
      setActiveProduct(representativeProduct);
    } else {
      if (activeProduct) {
        setSelectedCompanyId(activeProduct.relationships.company.data.id);
        setSearchParams((prev) =>
          setDefaultParams(activeProduct.id.toString(), prev),
        );
      } else {
        if (selectedCompanyId) {
          const defaultProduct = productsData.data.find(
            (product) =>
              product.relationships.company.data.id === selectedCompanyId,
          );
          if (defaultProduct) {
            dispatch(setActiveProduct(defaultProduct));
            setSearchParams((prev) =>
              setDefaultParams(defaultProduct.id.toString(), prev),
            );
          }
        } else {
          // If there is no default product, then set the first product as default
          const defaultProduct = productsData.data[0];
          if (defaultProduct) {
            dispatch(setActiveProduct(defaultProduct));
            setSearchParams((prev) =>
              setDefaultParams(defaultProduct.id.toString(), prev),
            );
          }
        }
      }
    }
  }, [
    productsData,
    productIds,
    selectedCompanyId,
    setSearchParams,
    setSelectedCompanyId,
    isSettingsPage,
    activeProduct,
    dispatch,
  ]);

  // page and tagsOperators are required so ensure they are set
  useEffect(() => {
    if (!searchParams.get("page")) {
      setSearchParams((prev) => {
        prev.set("page", "1");
        return prev;
      });
    }
    if (!searchParams.get("tagsOperator")) {
      setSearchParams((prev) => {
        prev.set("tagsOperator", AnswerEntryFilterMethod.and);
        return prev;
      });
    }
  }, [setSearchParams, searchParams]);
};

export const useHasPermissionOnAllEntries = (
  entries: AnswerLibraryEntry[],
  p: Permission,
) => {
  const { data: userData } = useGetMeQuery();
  const productPermissions = useAppSelector(
    (state) => state[LAYOUT_KEY].productPermissions,
  );
  if (!userData) return false;
  if (userData.user.attributes.isStaff) {
    return hasPermission(userData.user, p);
  }
  if (!productPermissions) return false;

  return checkAnswerLibraryEntryPermissions(productPermissions, entries, p);
};

// Checks if the user has permission on all the entries. If the entry belongs to multiple products,
// it will ensure the user has a permission on at least one of the products associated with the entry.
export const checkAnswerLibraryEntryPermissions = (
  productPermissions: Record<ProductId, Permission[]>,
  entries: AnswerLibraryEntry[],
  p: Permission,
) => {
  let canPerform = true;

  for (const entry of entries) {
    const productIds = entry.attributes.productIds;

    if (productIds.length > 0) {
      for (const productId of productIds) {
        if (!productPermissions[productId]?.includes(p)) {
          canPerform = false;
          break;
        }
      }
      if (!canPerform) break;
    } else {
      canPerform = false;
      captureException(new Error("Entry has no productIds"));
      break;
    }
  }

  return canPerform;
};

export const useHasPermissionOnAllDocuments = (
  documents: Document[],
  p: Permission,
) => {
  const { data: userData } = useGetMeQuery();
  const productPermissions = useAppSelector(
    (state) => state[LAYOUT_KEY].productPermissions,
  );
  if (!userData) return false;
  if (userData.user.attributes.isStaff) {
    return hasPermission(userData.user, p);
  }
  if (!productPermissions) return false;
  return checkDocumentPermissions(productPermissions, documents, p);
};

// Checks if the user has permission on all the documents. If the document belongs to multiple products,
// it will ensure the user has a permission on at least one of the products associated with the document.
export const checkDocumentPermissions = (
  productPermissions: Record<ProductId, Permission[]>,
  documents: Document[],
  p: Permission,
) => {
  let canPerform = true;
  for (const document of documents) {
    if (document.attributes.productIds.length > 0) {
      for (const productId of document.attributes.productIds) {
        if (!productPermissions[productId]?.includes(p)) {
          canPerform = false;
          break;
        }
      }
      if (!canPerform) break;
    } else {
      canPerform = false;
      captureException(new Error("Document has no productIds"));
      break;
    }
    if (!canPerform) {
      return false;
    }
  }

  return canPerform;
};

interface AppliedOwnersResults {
  fullyAppliedOwners: SimpleUser[];
  partiallyAppliedOwners: SimpleUser[];
  fetching: boolean;
}

export const useAppliedOwners = (
  entities: AnswerLibraryEntry[] | Document[],
): AppliedOwnersResults => {
  const ownerCountMap = entities.reduce(
    (acc, entity) => {
      entity.relationships.owners.forEach((user: User) => {
        acc[user.id] = acc[user.id] ? acc[user.id] + 1 : 1;
      });
      return acc;
    },
    {} as { [key: string]: number },
  );

  const partiallyAppliedOwnersIds = Object.entries(ownerCountMap)
    .filter(([__, count]) => {
      return count > 0 && count < entities.length;
    })
    .map(([ownerId]) => parseInt(ownerId) as UserId)
    .sort((a, b) => (a < b ? -1 : 1));

  const fullyAppliedOwnersIds = Object.entries(ownerCountMap)
    .filter((entity) => {
      const count = entity[1];
      return count === entities.length;
    })
    .map(([ownerId]) => parseInt(ownerId) as UserId)
    .sort((a, b) => (a < b ? -1 : 1));

  const { data: fullyAppliedOwners, isFetching: fullyAppliedOwnersFetching } =
    useGetUsersByIdQuery(fullyAppliedOwnersIds);
  const {
    data: partiallyAppliedOwners,
    isFetching: partiallyAppliedOwnersFetching,
  } = useGetUsersByIdQuery(partiallyAppliedOwnersIds);

  const fetching = fullyAppliedOwnersFetching || partiallyAppliedOwnersFetching;

  return {
    fullyAppliedOwners: fullyAppliedOwners ?? [],
    partiallyAppliedOwners: partiallyAppliedOwners ?? [],
    fetching,
  };
};

export const useGetUsersWithPermission = (
  permission: Permission,
  productIds: ProductId[],
  nameQuery?: string,
  limit?: number,
  offset?: number,
): { users: SimpleUser[]; isFetching: boolean } => {
  const { data, isFetching } = useGetUsersWithPermissionQuery(
    productIds.length
      ? {
          productIds,
          permission,
          nameQuery: nameQuery?.trim() || undefined,
          limit,
          offset,
        }
      : skipToken,
  );

  return { users: data?.data ?? [], isFetching };
};
