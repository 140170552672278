import { stemmer } from "stemmer";

export const sanitizeText = (text: string | undefined): string => {
  if (!text) {
    return "";
  }
  const whitespaceRe = new RegExp("\\s+", "g");
  return text.replaceAll(whitespaceRe, " ");
};

export const wordsMatchingTerms = (
  input: string,
  terms: string[],
): string[] => {
  const stemmedTerms = terms.map((term) => stemmer(term));

  // Used to match individual words (including apostrophes)
  const words = input.toLowerCase().match(/\b[\w']+\b/g);
  const wordsWithStems = words?.filter(
    (word) => stemmedTerms.indexOf(stemmer(word)) > -1,
  );
  return [...(wordsWithStems || []), ...terms];
};
