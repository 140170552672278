import { GetQuestionnairesParams } from "./dashboard";

export const constructQuestionnaireQueryParams = (
  params: GetQuestionnairesParams,
): string => {
  const { companyId, filters, limit, offset } = params;
  const searchParams = new URLSearchParams({
    companyId,
  });
  if (limit) {
    searchParams.set("limit", limit.toString());
  }
  if (offset) {
    searchParams.set("offset", offset.toString());
  }
  if (filters.acceptedBeforeDate) {
    searchParams.set(
      "acceptedBeforeDateInclusive",
      filters.acceptedBeforeDate.toISOString(),
    );
  }
  if (filters.acceptedAfterDate) {
    searchParams.set(
      "acceptedAfterDateInclusive",
      filters.acceptedAfterDate.toISOString(),
    );
  }
  if (filters.dueBeforeDate) {
    searchParams.set(
      "dueBeforeDateInclusive",
      filters.dueBeforeDate.toISOString(),
    );
  }
  if (filters.dueAfterDate) {
    searchParams.set(
      "dueAfterDateInclusive",
      filters.dueAfterDate.toISOString(),
    );
  }
  if (filters.returnedBeforeDate) {
    searchParams.set(
      //customer sees this as returned date but backend tracks this field from SP perspective as completed
      "completedBeforeDateInclusive",
      filters.returnedBeforeDate.toISOString(),
    );
  }
  if (filters.returnedAfterDate) {
    searchParams.set(
      //customer sees this as returned date but backend tracks this field from SP perspective as completed

      "completedAfterDateInclusive",
      filters.returnedAfterDate.toISOString(),
    );
  }
  if (filters.sortBy) {
    searchParams.set("sortBy", filters.sortBy);
  }
  if (filters.sortDirection) {
    searchParams.set("sortDirection", filters.sortDirection);
  }
  filters.statuses?.forEach((status) => {
    searchParams.append("statuses", status);
  });
  filters.productIds?.forEach((productId) => {
    searchParams.append("productIds", productId.toString());
  });
  if (filters.query) {
    searchParams.set("query", filters.query);
  }
  if (filters.minCompletionPercentage) {
    searchParams.set(
      "minCompletionPercentage",
      filters.minCompletionPercentage.toString(),
    );
  }
  if (filters.maxCompletionPercentage) {
    searchParams.set(
      "maxCompletionPercentage",
      filters.maxCompletionPercentage.toString(),
    );
  }
  if (filters.expedited) {
    searchParams.set("expedited", "True");
  }
  filters.tagIds?.forEach((tagId) => {
    searchParams.append("tagIds", tagId);
  });
  if (filters.senders) {
    filters.senders.forEach((sender) => {
      searchParams.append("senders", `${sender.email}|${sender.name}`);
    });
  }
  if (filters.formats) {
    filters.formats.forEach((format) => {
      searchParams.append("formats", format);
    });
  }
  if (filters.assigneesIds) {
    filters.assigneesIds.forEach((assigneeId) => {
      searchParams.append("assigneeIds", assigneeId.toString());
    });
  }
  if (params.includeAiCompletedQuestionnaires) {
    searchParams.set("includeAiCompletedQuestionnaires", "True");
  }
  return searchParams.toString();
};
